import React from "react";
import {ActionType, ch, PageRouteProvider, TextAlign} from "@reapptor-apps/reapptor-react-common";
import {
    BorderType, Button,
    ButtonType,
    CellModel,
    Checkbox, ColumnActionDefinition,
    ColumnDefinition, ColumnModel,
    Grid,
    GridHoveringType,
    GridModel, IconSize, Inline,
    InlineType,
    JustifyContent,
    PageContainer,
    PageHeader,
    PageRow,
    Tab,
    TabContainer,
    ToolbarButton,
    ToolbarContainer,
    ToolbarRow
} from "@reapptor-apps/reapptor-react-components";
import {FileModel, IPagedList, SortDirection} from "@reapptor-apps/reapptor-toolkit";
import AuthorizedPage from "@/models/base/AuthorizedPage";
import ListProductAuditRequest from "@/models/server/requests/ListProductAuditRequest";
import {ExportEncodingType, ProductAuditAction} from "@/models/Enums";
import Product from "@/models/server/Product";
import Customer from "@/models/server/Customer";
import GetProductRequest from "@/models/server/requests/GetProductRequest";
import GetProductResponse from "@/models/server/responses/GetProductResponse";
import CustomerGroup from "@/models/server/CustomerGroup";
import AuditToolbar from "@/pages/ProductAudit/ProductAuditToolbar/ProductAuditToolbar";
import ProductAuditToolbar, {ProductAuditToolbarModel} from "@/pages/ProductAudit/ProductAuditToolbar/ProductAuditToolbar";
import ProductAuditItem from "@/models/server/ProductAudit";
import ProductAssortmentConsumption from "@/models/server/ProductAssortmentConsumption";
import GetProductAssortmentConsumptionsRequest from "@/models/server/requests/GetProductAssortmentConsumptionsRequest";
import GetProductAssortmentConsumptionsResponse from "@/models/server/responses/GetProductAssortmentConsumptionsResponse";
import ExportProductAssortmentConsumptionsRequest from "@/models/server/requests/ExportProductAssortmentConsumptionsRequest";
import User from "@/models/server/User";
import Localizer from "@/localization/Localizer";

import styles from "./ProductAudit.module.scss";

export interface IProductAuditProps {
    productId?: string;
    customerGroupId?: string;
    customerId?: string;
}

interface IProductAuditState {
    toolbarModel: ProductAuditToolbarModel | null;
    product: Product | null;
    customerGroup: CustomerGroup | null;
    customer: Customer | null;
    initialized: boolean;
    consumptionAnnual: boolean;
    consumptionAittaOnly: boolean;
}

export default class ProductAudit extends AuthorizedPage<IProductAuditProps, IProductAuditState> {

    state: IProductAuditState = {
        toolbarModel: null,
        product: null,
        customerGroup: null,
        customer: null,
        initialized: false,
        consumptionAnnual: false,
        consumptionAittaOnly: false
    };

    private readonly _auditGridRef: React.RefObject<Grid<ProductAuditItem>> = React.createRef();
    private readonly _consumptionGridRef: React.RefObject<Grid<ProductAssortmentConsumption>> = React.createRef();
    private readonly _toolbarRef: React.RefObject<ProductAuditToolbar> = React.createRef();

    private readonly _columns: ColumnDefinition[] = [
        {
            header: Localizer.auditPageTimestamp,
            name: "timestamp",
            format: "dt",
            accessor: nameof<ProductAuditItem>(o => o.timestamp),
            isDefaultSorting: true,
            sorting: SortDirection.Desc,
            textAlign: TextAlign.Center,
            minWidth: "7rem",
        } as ColumnDefinition,
        {
            header: Localizer.auditPageModifiedBy,
            name: "user",
            sorting: true,
            accessor: (model) => (model.user != null) ? model.user : Localizer.auditPageSystem,
            minWidth: "10rem",
        } as ColumnDefinition,
        {
            header: Localizer.auditPageSourceLanguageItemName,
            accessor: (model: ProductAuditItem) => (!!model.filename)
                ? Localizer.auditPageSourceFile.format(model.filename)
                : (!!model.templateName)
                    ? Localizer.auditPageSourceTemplate.format(model.templateName)
                    : null,
            minWidth: "10rem",
            maxWidth: "20rem",
            wordBreak: false,
            noWrap: true,
        } as ColumnDefinition,
        {
            header: Localizer.auditPageEvent,
            name: "event",
            accessor: (model) => this.getAuditMessage(model),
            stretch: true,
            sorting: SortDirection.Desc,
            minWidth: "15rem",
        } as ColumnDefinition,
        {
            header: Localizer.productReplacementPageGridOwner,
            name: "owner",
            sorting: true,
            accessor: (model: ProductAuditItem) => (model.customerGroup != null) ? this.getCustomerGroupName(model) : this.getCustomerName(model),
            visible: () => (!this.singleUnitAudit),
            minWidth: "20rem",
            maxWidth: "20rem",
        } as ColumnDefinition,
        {
            header: Localizer.productPanelGridProductGroupLanguageItemName,
            sorting: true,
            isDefaultSorting: true,
            accessor: nameof.full<ProductAuditItem>(o => o.product!.mainGroup),
            visible: () => (!this.singleProductAudit),
            stretch: true,
            minWidth: "15rem",
            maxWidth: "15rem",
            settings: {
                infoAccessor: nameof.full<ProductAuditItem>(o => o.product!.subGroup),
            }
        } as ColumnDefinition,
        {
            name: nameof.full<ProductAuditItem>(o => o.product!.name),
            header: Localizer.productPanelGridNameLanguageItemName,
            accessor: (model: ProductAuditItem) => Product.getFullName(model.product!),
            visible: () => (!this.singleProductAudit),
            minWidth: "25rem",
            sorting: true,
            settings: {
                infoAccessor: nameof.full<ProductAuditItem>(model => model.product!.code),
            },
        }
    ];

    private readonly _consumptionColumns: ColumnDefinition[] = [
        {
            header: Localizer.genericDateLanguageItemName,
            format: "d",
            accessor: nameof<ProductAssortmentConsumption>(o => o.date),
            textAlign: TextAlign.Center,
            minWidth: "5rem",
        } as ColumnDefinition,
        {
            header: Localizer.auditPageConsumptionCustomerCodeLanguageItemName,
            accessor: (model: ProductAssortmentConsumption) => model.saleCustomerCode || model.customerCode,
            textAlign: TextAlign.Center,
            init: (cell: CellModel<ProductAssortmentConsumption>) => this.initCustomerCode(cell),
            minWidth: "6rem",
        } as ColumnDefinition,
        {
            header: Localizer.auditPageConsumptionProductCodeLanguageItemName,
            accessor: nameof.full<ProductAssortmentConsumption>(o => o.productAssortment!.product!.code),
            textAlign: TextAlign.Center,
            init: (cell: CellModel<ProductAssortmentConsumption>) => this.initProductCode(cell),
            minWidth: "6rem",
        } as ColumnDefinition,
        {
            header: Localizer.productPanelGridAittaLanguageItemName,
            minWidth: "5rem",
            textAlign: TextAlign.Center,
            init: (cell) => this.initSelectionColumn(cell),
            actions: [
                {
                    title: "AITTA",
                    type: ActionType.Light,
                } as ColumnActionDefinition
            ]
        } as ColumnDefinition,
        {
            header: Localizer.productPanelGridNameLanguageItemName,
            accessor: (model: ProductAssortmentConsumption) => Product.getFullName(model.productAssortment!.product!),
            minWidth: "25rem",
            stretch: true,
        } as ColumnDefinition,
        {
            header: Localizer.auditPageConsumptionPackageQuantityLanguageItemName,
            accessor: nameof.full<ProductAssortmentConsumption>(o => o.productAssortment!.product!.packageQuantity),
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: Localizer.auditPageConsumptionAnnualLanguageItemName,
            accessor: nameof.full<ProductAssortmentConsumption>(o => o.annual),
            transform: (cell: CellModel<ProductAssortmentConsumption>, value: boolean) => value ? "A" : "",
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: Localizer.auditPageConsumptionExternalLanguageItemName,
            accessor: nameof.full<ProductAssortmentConsumption>(o => o.external),
            transform: (cell: CellModel<ProductAssortmentConsumption>, value: boolean) => value ? "✓" : "",
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: Localizer.auditPageConsumptionInheritanceLanguageItemName,
            accessor: nameof.full<ProductAssortmentConsumption>(o => o.inheritance),
            transform: (cell: CellModel<ProductAssortmentConsumption>, value: boolean) => value ? "✓" : "",
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: Localizer.auditPageConsumptionApplicationLanguageItemName,
            accessor: nameof.full<ProductAssortmentConsumption>(o => o.application),
            textAlign: TextAlign.Center,
        } as ColumnDefinition,
        {
            header: Localizer.auditPageConsumptionOrderLanguageItemName,
            accessor: nameof.full<ProductAssortmentConsumption>(o => o.orderErpId),
            minWidth: "7rem",
            textAlign: TextAlign.Center,
            settings: {
                infoAccessor: (model: ProductAssortmentConsumption) => (model.orderNumber != null) ? "#{0}".format(model.orderNumber) : "-",
            },
        } as ColumnDefinition,
        {
            group: Localizer.auditPageConsumptionQuantityLanguageItemName,
            header: Localizer.auditPageConsumptionPackages,
            accessor: nameof.full<ProductAssortmentConsumption>(o => o.quantity),
            textAlign: TextAlign.Center,
            total: (column: ColumnModel<ProductAssortmentConsumption>) => this.getConsumptionTotalQuantity(column),
            minWidth: "6rem",
            settings: {
                infoAccessor: nameof.full<ProductAssortmentConsumption>(o => o.deliveredQuantity),
                infoHideEqual: true
            }
        } as ColumnDefinition,
        {
            group: Localizer.auditPageConsumptionQuantityLanguageItemName,
            header: Localizer.auditPageConsumptionPieces,
            accessor: (model: ProductAssortmentConsumption) => this.getPieces(model),
            textAlign: TextAlign.Center,
            total: "0",
            minWidth: "6rem",
            settings: {
                infoAccessor: (model: ProductAssortmentConsumption) => this.getDeliveredPieces(model),
                infoHideEqual: true
            }
        } as ColumnDefinition,
        {
            header: Localizer.auditPageConsumptionPriceLanguageItemName,
            accessor: nameof.full<ProductAssortmentConsumption>(o => o.price),
            transform: (cell: CellModel<any>, value: number | null) => (value != null) ? "{0:C} €".format(value) : "-",
            textAlign: TextAlign.Center,
            total: (column: ColumnModel<ProductAssortmentConsumption>) => this.getConsumptionTotalCost(column),
            minWidth: "6rem",
        } as ColumnDefinition,
    ];

    private async fetchAsync(pageNumber: number, pageSize: number, sortColumnName: string | null, sortDirection: SortDirection | null): Promise<IPagedList<ProductAuditItem>> {
        if (!this.state.initialized) {
            return [].toPagedList(1, pageSize);
        }

        const request = new ListProductAuditRequest();
        request.search = this.state.toolbarModel?.search ?? null;
        request.pageNumber = pageNumber;
        request.pageSize = pageSize;
        request.sortDirection = sortDirection;
        request.sortColumnName = sortColumnName;
        request.action = this.toolbarModel?.action ?? null;
        request.productId = this.product?.id ?? null;
        request.customerId = this.customer?.id ?? (this.toolbarModel?.customer?.id ?? null);
        request.customerGroupId = this.customerGroup?.id ?? (this.toolbarModel?.customerGroup?.id ?? null);

        return await this.postAsync("/api/audit/listProductAudit", request);
    }

    private async fetchConsumptionsAsync(): Promise<ProductAssortmentConsumption[]> {

        const productId: string | null = this.productId;
        const customerId: string | null = this.customerId;

        if ((!productId) || (!customerId)) {
            return [];
        }

        const request = new GetProductAssortmentConsumptionsRequest();
        request.productId = productId;
        request.customerId = customerId;
        request.annualOnly = this.state.consumptionAnnual;
        request.aittaOnly = this.state.consumptionAittaOnly;

        const response: GetProductAssortmentConsumptionsResponse = await this.postAsync("/api/audit/getProductAssortmentConsumptions", request);

        return response.consumptions ?? [];
    }

    private async setConsumptionAnnualAsync(value: boolean): Promise<void> {
        await this.setState({consumptionAnnual: value});

        this.consumptionGrid?.reloadAsync();
    }

    private async setConsumptionAittaOnlyAsync(value: boolean): Promise<void> {
        await this.setState({consumptionAittaOnly: value});

        this.consumptionGrid?.reloadAsync();
    }
    
    private async downloadCsvAsync(encodingType: ExportEncodingType = ExportEncodingType.Utf8): Promise<void> {

        const request = new ExportProductAssortmentConsumptionsRequest();
        request.productId = this.productId;
        request.customerId = this.customerId;
        request.annualOnly = this.state.consumptionAnnual;
        request.encodingType = encodingType;

        const file: FileModel = await this.postAsync("/api/audit/exportProductAssortmentConsumptions", request);

        ch.download(file);
    }

    private getPieces(model: ProductAssortmentConsumption): number {
        return model.quantity * (model.productAssortment?.product?.packageQuantity || 0);
    }

    private getDeliveredPieces(model: ProductAssortmentConsumption): number {
        return model.deliveredQuantity * (model.productAssortment?.product?.packageQuantity || 0);
    }
    
    private getConsumptionTotalQuantity(column: ColumnModel<ProductAssortmentConsumption>): number | null {
        return (this.state.product)
            ? column.cells.sum(item => item.model.quantity * (item.model.productAssortment?.product?.packageQuantity ?? 0)) / this.state.product.packageQuantity
            : null;
    }
    
    private getConsumptionTotalCost(column: ColumnModel<ProductAssortmentConsumption>): number | null {
        return column.cells.sum(item => item.model.quantity * (item.model.price ?? 0));
    }
    
    private getAuditMessage(model: ProductAuditItem): string {
        switch (model.action) {
            case ProductAuditAction.ProductCreated:
                return Localizer.enumProductAuditActionProductCreated;
            case ProductAuditAction.ProductDeleted:
                return Localizer.enumProductAuditActionProductDeleted;
            case ProductAuditAction.ProductUpdated:
                return Localizer.enumProductAuditActionProductUpdated;
            case ProductAuditAction.ProductRestored:
                return Localizer.enumProductAuditActionProductRestored;
            case ProductAuditAction.AssortmentRestored:
                return (model.customerGroup != null) ? Localizer.auditPageRestoreContractAssortment : Localizer.auditPageRestoreCustomerAssortment;
            case ProductAuditAction.AssortmentCreated:
                return (model.customerGroup != null) ? Localizer.auditPageCreatedContracAssortment : Localizer.auditPageCreatedCustomerAssortment;
            case ProductAuditAction.AssortmentDeleted:
                return (model.customerGroup != null) ? Localizer.auditPageRemovedContracAssortment : Localizer.auditPageRemovedCustomerAssortment;
            case ProductAuditAction.ReplacementCreated:
                return "Replaced with product <mark>\"{0} ({1})\"</mark>".format(model.replacementProduct?.name, model.replacementProduct?.code);
            case ProductAuditAction.ReplacementDeleted:
                return "Removed replacement with product <mark>\"{0} ({1})\"</mark>".format(model.replacementProduct?.name, model.replacementProduct?.code);
            case ProductAuditAction.TpTmValueChanged:
                const tpChangedMessage: string = (model.oldOrderThreshold != model.newOrderThreshold) ? "TP: \"{0:0.00} <mark> ⇨ </mark> {1:0.00}\"".format(model.oldOrderThreshold || 0, model.newOrderThreshold || 0) : "";
                const tmChangedMessage: string = (model.oldOrderQuantity != model.newOrderQuantity) ? "TM: \"{0} <mark> ⇨ </mark> {1}\"".format(model.oldOrderQuantity || 0, model.newOrderQuantity || 0) : "";
                return "{0} {1}".format(tpChangedMessage, tmChangedMessage);
            default:
                return "";
        }
    }

    private getCustomerName(model: ProductAuditItem): string {
        return (model.customer != null)
            ? Customer.getNameWithCode(model.customer)
            : (model.productAssortment?.customer != null)
                ? Customer.getNameWithCode(model.productAssortment.customer)
                : "-";
    }

    private getCustomerGroupName(model: ProductAuditItem): string {
        return (model.customerGroup != null)
            ? model.customerGroup.name
            : (model.productAssortment?.customerGroup != null)
                ? model.productAssortment.customerGroup.name
                : "-";
    }

    private initCustomerCode(cell: CellModel<ProductAssortmentConsumption>): void {
        const model: ProductAssortmentConsumption = cell.model;
        const noSale: boolean = (!model.saleCustomerCode);

        cell.className = this.cssIf(cell.className, noSale, styles.noSale);
    }

    private initProductCode(cell: CellModel<ProductAssortmentConsumption>): void {
        const model: ProductAssortmentConsumption = cell.model;
        const deleted: boolean = (model.productAssortment!.deleted) || (model.productAssortment!.product!.deleted);

        cell.className = this.cssIf(cell.className, deleted, styles.deleted);
    }

    private initSelectionColumn(cell: CellModel<ProductAssortmentConsumption>): void {
        const model: ProductAssortmentConsumption = cell.model;

        const selected: boolean = (model.aitta);

        cell.className = styles.aitta;
        cell.className = this.cssIf(cell.className, selected, styles.selected);
        cell.className = this.cssIf(cell.className, !selected, styles.unselected);
    }

    public get grid(): GridModel<ProductAuditItem> | null {
        return this._auditGridRef.current?.model || null;
    }

    public get consumptionGrid(): GridModel<ProductAssortmentConsumption> | null {
        return this._consumptionGridRef.current?.model || null;
    }

    public get user(): User {
        return ch.getUser();
    }

    public get isAdmin(): boolean {
        return this.user.isAdmin;
    }
    
    public get productId(): string | null {
        return this.product?.id ?? null;
    }
    
    public get customerId(): string | null {
        return this.customer?.id ?? (this.toolbarModel?.customer?.id ?? null);
    }

    public get toolbar(): AuditToolbar {
        return this._toolbarRef!.current!;
    }

    public get toolbarModel(): ProductAuditToolbarModel | null {
        return this.state.toolbarModel;
    }

    public get pageTitle(): string {
        return Localizer.auditPageTitle;
    }

    public get pageSubTitle(): string {
        const customer: string = (this.customerGroup)
            ? Localizer.auditPageCustomerGroup.format(this.customerGroup.name)
            : (this.customer)
                ? Localizer.auditPageCustomer.format(this.customer.name, this.customer.code)
                : "";

        return (this.product)
            ? Localizer.auditPageProduct.format(this.product.name, this.product.code, customer)
            : "";
    }

    public get product(): Product | null {
        return this.state.product;
    }

    public get customerGroup(): CustomerGroup | null {
        return this.state.customerGroup;
    }

    public get customer(): Customer | null {
        return this.state.customer;
    }

    public get singleProductAudit(): boolean {
        return (this.product != null);
    }

    public get singleUnitAudit(): boolean {
        return (this.customerGroup != null) || (this.customer != null);
    }

    public get initialized(): boolean {
        return (this.state.initialized);
    }

    public async onAuditModelChangeAsync(model: ProductAuditToolbarModel): Promise<void> {
        await this.setState({toolbarModel: model});

        await this.grid?.reloadAsync();
        
        await this.consumptionGrid?.reloadAsync();
    }

    public async initializeAsync(): Promise<void> {
        const params = this.parameters as IProductAuditProps as IProductAuditProps | null;

        const productId: string = params?.productId || "";

        if (productId) {
            const request = new GetProductRequest();
            request.productIdOrAssortmentIdOrOrderProductId = productId;

            const response: GetProductResponse = await this.postAsync("/api/productManagement/getProduct", request);

            this.state.product = response.product;
        }

        this.state.customerGroup = (params?.customerGroupId)
            ? await this.postAsync("/api/customerManagement/getCustomerGroup", params.customerGroupId)
            : null;

        this.state.customer = (params?.customerId)
            ? await this.postAsync("/api/customerManagement/getCustomer", params.customerId)
            : null;

        await this.setState({initialized: true});
    }

    public render(): React.ReactNode {
        return (
            <PageContainer id={this.id} fullHeight fullWidth scrollY className={styles.productAudit}>

                <PageHeader className={styles.header} title={this.pageTitle} subtitle={this.pageSubTitle}>

                    <ToolbarButton title={Localizer.genericBack}
                                   icon={{name: "fas arrow-alt-circle-left"}}
                                   type={ButtonType.Primary}
                                   onClick={async () => PageRouteProvider.back()}
                    />

                </PageHeader>

                <PageRow>

                    <div className="col">

                        <TabContainer>
    
                            <Tab id="Audit" title={Localizer.auditPageAuditTabLanguageItemName}>

                                <ProductAuditToolbar ref={this._toolbarRef}
                                                     showSearchFilter={!this.singleProductAudit}
                                                     showCustomersFilter={!this.singleUnitAudit}
                                                     onChange={(model) => this.onAuditModelChangeAsync(model)}
                                />
    
                                {
                                    (this.initialized) &&
                                    (
                                        <Grid autoToggle pagination optimization responsive
                                              id={"auditGrid"}
                                              minWidth="auto"
                                              ref={this._auditGridRef}
                                              hovering={GridHoveringType.Row}
                                              className={styles.auditGrid}
                                              noDataText={Localizer.genericNoData}
                                              headerMinHeight={80}
                                              borderType={BorderType.NoSeparators}
                                              columns={this._columns}
                                              fetchData={(_, pageNumber, pageSize, sortColumnName, sortDirection) => this.fetchAsync(pageNumber, pageSize, sortColumnName, sortDirection)}
                                        />
                                    )
                                }
    
                            </Tab>
    
                            <Tab id="Consumption" title={Localizer.auditPageConsumptionTabLanguageItemName}>

                                <ToolbarContainer className={styles.consumptionToolbar}>

                                    <ToolbarRow justify={JustifyContent.SpaceBetween}>

                                        <Inline>

                                            <Checkbox inline
                                                      inlineType={InlineType.Right}
                                                      label={Localizer.auditPageConsumptionToolboxAnnualLanguageItemName}
                                                      title={Localizer.auditPageConsumptionToolboxAnnualTitleLanguageItemName}
                                                      value={this.state.consumptionAnnual}
                                                      onChange={(_, value) => this.setConsumptionAnnualAsync(value)}
                                            />

                                            <Checkbox inline
                                                      inlineType={InlineType.Right}
                                                      label={Localizer.auditPageConsumptionToolboxAittaLanguageItemName}
                                                      title={Localizer.auditPageConsumptionToolboxAittaTitleLanguageItemName}
                                                      value={this.state.consumptionAittaOnly}
                                                      onChange={(_, value) => this.setConsumptionAittaOnlyAsync(value)}
                                            />

                                        </Inline>

                                        <Inline>
                                            
                                            <Button right
                                                    id={"exportToCsv"}
                                                    className={styles.button}
                                                    label={Localizer.labelsToolbarExcel}
                                                    icon={{name: "fa-file-download fas", size: IconSize.Large}}
                                                    type={ButtonType.Primary}
                                                    disabled={!this.initialized}
                                                    onClick={() => this.downloadCsvAsync()}
                                            >

                                                <Button.Action id={"utf8"}
                                                               title={"{0:ExportEncodingType}".format(ExportEncodingType.Utf8)}
                                                               onClick={() => this.downloadCsvAsync(ExportEncodingType.Utf8)}
                                                />

                                                <Button.Action id={"unicode"}
                                                               title={"{0:ExportEncodingType}".format(ExportEncodingType.Unicode)}
                                                               onClick={() => this.downloadCsvAsync(ExportEncodingType.Unicode)}
                                                />

                                                <Button.Action id={"latin1"}
                                                               title={"{0:ExportEncodingType}".format(ExportEncodingType.Latin1)}
                                                               onClick={() => this.downloadCsvAsync(ExportEncodingType.Latin1)}
                                                />

                                                <Button.Action id={"openXml"}
                                                               title={"{0:ExportEncodingType}".format(ExportEncodingType.OpenXml)}
                                                               onClick={() => this.downloadCsvAsync(ExportEncodingType.OpenXml)}
                                                />

                                            </Button>

                                        </Inline>

                                    </ToolbarRow>

                                </ToolbarContainer>

                                {
                                    (this.initialized) &&
                                    (
                                        <Grid optimization responsive
                                              id={"consumptionGrid"}
                                              ref={this._consumptionGridRef}
                                              minWidth="auto"
                                              hovering={GridHoveringType.Row}
                                              className={styles.consumptionGrid}
                                              noDataText={Localizer.genericNoData}
                                              headerMinHeight={80}
                                              borderType={BorderType.NoSeparators}
                                              columns={this._consumptionColumns}
                                              fetchData={() => this.fetchConsumptionsAsync()}
                                        />
                                    )
                                }
    
                            </Tab>
    
                        </TabContainer>

                    </div>
                        
                </PageRow>

            </PageContainer>
        )
    }
}