// autogenerated

export default class SetMediqDeviceRequest {

    public fcmToken: string | null = null;

    public isMediqDevice: boolean = false;

    public readonly isSetMediqDeviceRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof SetMediqDeviceRequest) || ((from as any).isSetMediqDeviceRequest === true));
    }

    public static as(from?: object | null): SetMediqDeviceRequest | null {
        return (SetMediqDeviceRequest.is(from)) ? from as SetMediqDeviceRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}