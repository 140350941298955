import React from "react";
import {BaseComponent, PageCacheProvider} from "@reapptor-apps/reapptor-react-common";
import {Button, ButtonType, Dropdown, DropdownAlign, DropdownOrderBy, IconSize, Inline, JustifyContent, SelectListItem, TextInput, ToolbarContainer, ToolbarRow} from "@reapptor-apps/reapptor-react-components";
import {ProductAuditAction} from "@/models/Enums";
import AittaConstants from "@/helpers/AittaConstants";
import Customer from "@/models/server/Customer";
import CustomerGroup from "@/models/server/CustomerGroup";
import TransformProvider from "@/providers/TransformProvider";
import AittaController from "@/pages/AittaController";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";

import styles from "./ProductAuditToolbar.module.scss";

export interface IProductAuditToolbarProps {
    showCustomersFilter?: boolean;
    showSearchFilter?: boolean;
    model?: ProductAuditToolbarModel;

    onChange?(model: ProductAuditToolbarModel): Promise<void>;
}

interface IProductAuditToolbarState {
    model: ProductAuditToolbarModel;
    customers: Customer[];
    isLoading: boolean;
}

export class ProductAuditToolbarModel {
    public action: ProductAuditAction | null = null;

    public customerGroup: CustomerGroup | null = null;

    public customer: Customer | null = null;

    public search: string | null = null;

    public dataInitialized: boolean = false;
}

export default class ProductAuditToolbar extends BaseComponent<IProductAuditToolbarProps, IProductAuditToolbarState> {

    state: IProductAuditToolbarState = {
        model: this.props.model ?? new ProductAuditToolbarModel(),
        customers: [],
        isLoading: false
    };

    private async onSearchChangeAsync(value: string) {
        this.state.model.search = value;

        await this.processAsync(true);
    }

    private async onSearchButtonClickAsync() {
        await this.processAsync(true);
    }

    private async selectActionFilterAsync(item: SelectListItem | null) {
        this.state.model.action = (item) ? parseInt(item.value) : null;
        await this.processAsync(true);
    }

    private async fetchCustomersAsync(): Promise<Customer[]> {
        return await PageCacheProvider.getAsync("listCustomers", () => this.postAsync("/api/productManagement/listCustomers"));
    }

    private get items(): (CustomerGroup | Customer)[] {
        return Customer.group(this.state.customers);
    }

    private async selectCustomerGroupOrCustomerAsync(customerGroupOrCustomer: CustomerGroup | Customer | null, userInteraction: boolean): Promise<void> {
        this.model.customerGroup = (customerGroupOrCustomer != null && CustomerGroup.is(customerGroupOrCustomer)) ? customerGroupOrCustomer as CustomerGroup : null;
        this.model.customer = (customerGroupOrCustomer != null && Customer.is(customerGroupOrCustomer)) ? customerGroupOrCustomer as Customer : null;

        if (userInteraction) {
            AittaController.setDefaultCustomerGroupOrCustomer(customerGroupOrCustomer);
        }

        await this.processAsync(true);
    }

    private async processAsync(invoke: boolean = false): Promise<void> {
        await this.setState({model: this.state.model});

        if ((invoke) && (this.props.onChange)) {
            await this.props.onChange(this.state.model);
        }
    }

    public get model(): ProductAuditToolbarModel {
        return this.state.model;
    }

    public get customerGroup(): CustomerGroup | null {
        return this.model.customerGroup;
    }

    public get customer(): Customer | null {
        return this.model.customer;
    }

    public get showSearchFilter(): boolean {
        return this.props.showSearchFilter ?? true;
    }

    public get showCustomersFilter(): boolean {
        return this.props.showCustomersFilter ?? true;
    }

    public get search(): string | null {
        return this.state.model.search || null;
    }
    
    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        const customers: Customer[] = await this.fetchCustomersAsync();

        await this.setState({isLoading: false, customers});

        this.model.dataInitialized = true;

        await this.processAsync(true);
    }

    public render(): React.ReactNode {
        return (
            <ToolbarContainer className={this.css(styles.productAuditToolbar)}>

                <ToolbarRow justify={JustifyContent.SpaceBetween}>

                    <Inline>

                        {
                            (this.showCustomersFilter) &&
                            (
                                <Dropdown id="customerGroupsOrCustomers" noWrap inline noGrouping clearButton
                                          className={styles.customerGroupsOrCustomers}
                                          align={DropdownAlign.Left}
                                          width={AittaConstants.customersDropdownMinWidth}
                                          items={this.items}
                                          selectedItem={this.customer || this.customerGroup}
                                          nothingSelectedText={Localizer.labelsToolbarAllCustomers}
                                          orderBy={DropdownOrderBy.None}
                                          transform={(item: Customer | CustomerGroup) => TransformProvider.toCustomerOrCustomerGroupSelectItem(item, styles.customerGroupItem, styles.customerItem)}
                                          onChange={(_, item: CustomerGroup | Customer | null, userInteraction: boolean) => this.selectCustomerGroupOrCustomerAsync(item, userInteraction)}
                                />
                            )
                        }

                        <Dropdown id="orderStatusFilter" noWrap inline clearButton
                                  align={DropdownAlign.Left}
                                  orderBy={DropdownOrderBy.None}
                                  width={"15rem"}
                                  nothingSelectedText={Localizer.auditToolbarAllEvents}
                                  items={EnumProvider.getProductAuditActionItems()}
                                  selectedItem={this.state.model?.action}
                                  onChange={(_, item: SelectListItem | null) => this.selectActionFilterAsync(item)}
                        />

                        {
                            (this.showSearchFilter) &&
                            (
                                <Inline>

                                    <TextInput id="productSearch" inline clearButton
                                               width={"25rem"}
                                               placeholder={Localizer.productsToolbarSearchPlaceholder}
                                               value={this.search}
                                               onChange={(_, value: string) => this.onSearchChangeAsync(value)}
                                    />

                                    <Button block
                                            id={"search"}
                                            label={Localizer.genericSearch}
                                            icon={{name: "far search", size: IconSize.Large}}
                                            type={ButtonType.Primary}
                                            onClick={() => this.onSearchButtonClickAsync()}
                                    />

                                </Inline>
                            )
                        }

                    </Inline>

                </ToolbarRow>

            </ToolbarContainer>
        )
    }
}