import {initializeApp} from "firebase/app";
import {getMessaging, getToken} from "firebase/messaging";
import {FirebaseApp, FirebaseOptions} from "@firebase/app";
import {GetTokenOptions, Messaging} from "@firebase/messaging";
import {ApiProvider, ch, DataStorageType, DialogResult, IMessageBoxButtons, PageCacheProvider, PageRoute, PageRouteProvider, UserInteractionDataStorage} from "@reapptor-apps/reapptor-react-common";
import {IconSize, IFooterLink, ILeftNavProps, IMenuItem, IUserProfile} from "@reapptor-apps/reapptor-react-components";
import UserContext from "../models/server/UserContext";
import User from "@/models/server/User";
import AittaConstants from "@/helpers/AittaConstants";
import PageDefinitions from "@/providers/PageDefinitions";
import OnlineData from "../../../Mediq.Aitta.WebUI.Server/Models/OnlineData";
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import {FcmNotification} from "@/models/server/FcmNotification";
import UserNotificationMessage from "@/models/server/UserNotificationMessage";
import FindMyPushNotificationRequest from "@/models/server/requests/FindMyPushNotificationRequest";
import SetFavoriteRequest from "@/models/server/requests/SetFavoriteRequest";
import Customer from "@/models/server/Customer";
import CustomerGroup from "@/models/server/CustomerGroup";
import {CustomerServiceType} from "@/models/Enums";
import Catalog from "@/components/Catalog/Catalog";
import IsProductPostDeliveryRequest from "@/models/server/requests/IsProductPostDeliveryRequest";
import IMessageBox from "@reapptor-apps/reapptor-react-common/src/models/IMessageBox";
import CatalogDataProvider from "@/providers/CatalogDataProvider";
import OrderProductMobileInfo from "@/models/server/OrderProductMobileInfo";
import BannerMessage from "@/models/server/BannerMessage";
import UserActivityHelper from "@/helpers/UserActivityHelper";
import CustomerMobileInfo from "@/models/server/CustomerMobileInfo";
import GoogleAnalyticsHelper from "@/helpers/GoogleAnalyticsHelper";
import SelectCurrentCustomerRequest from "@/models/server/requests/SelectCurrentCustomerRequest";
import ApplicationContext from "@/models/server/ApplicationContext";
import EnumProvider from "@/providers/EnumProvider";
import TransformProvider from "@/providers/TransformProvider";
import Localizer from "@/localization/Localizer";

import mobileAppStyles from "./Mobile/mobile.module.scss";

class AittaController {
    private _initialized: boolean = false;
    private _initializing: boolean = false;
    private _fcmToken: string | null = null;

    // noinspection JSIgnoredPromiseFromCall
    private async setFcmTokenAsync(fcmToken: string): Promise<void> {
        this._fcmToken = fcmToken;

        await ApiProvider.postAsync("/api/application/setFcmToken", fcmToken);

        const windowInstance = (window as any);
        windowInstance.setFcmToken = null;
    }

    private async openPushAppNotificationAsync(fcmNotification: FcmNotification | null): Promise<void> {
        if ((fcmNotification) && (fcmNotification.messageId)) {

            const request = new FindMyPushNotificationRequest();
            request.messageId = fcmNotification.messageId;
            request.markAsRead = true;

            const notification: UserNotificationMessage | null = await ApiProvider.postAsync("/api/account/findMyPushNotification", request);
            if (notification) {
                if (notification.pageRoute) {
                    await PageRouteProvider.redirectAsync(notification.pageRoute);
                } else {
                    await PageRouteProvider.redirectAsync(PageDefinitions.notificationsRoute);
                }
            }
        }
    }

    private async initializePushServiceAsync(): Promise<void> {
        const windowInstance = (window as any);

        if (windowInstance.setFcmToken == null) {
            windowInstance.setFcmToken = async (fcmToken: string): Promise<void> => await this.setFcmTokenAsync(fcmToken);
        }

        window.addEventListener("push-notification", async (event: Event) => {
            //alert("push-notification = " + (event as CustomEvent).detail);
            const notification: FcmNotification | null = FcmNotification.restore(event as CustomEvent);
            await this.openPushAppNotificationAsync(notification);
        });

        // Initialize Firebase for Web
        const context: ApplicationContext | null = ch.findContext();
        if ((context) && (context.settings.googleWebConfigJson)) {
            const firebaseConfig: FirebaseOptions = JSON.parse(context.settings.googleWebConfigJson);

            // Initialize Firebase
            const firebaseApp: FirebaseApp = initializeApp(firebaseConfig);

            // Initialize Firebase Cloud Messaging and get a reference to the service
            const messaging: Messaging = getMessaging(firebaseApp);

            // Fetch FCM token information
            let fcmToken: string = "";

            try {
                const options: GetTokenOptions = {
                    vapidKey: context.settings.googleWebVapidKey || ""
                };

                fcmToken = await getToken(messaging, options);
            } catch {
                // Notification is disabled
                // console.log("Firebase: Notification is disabled.");
            }

            if (fcmToken) {
                await this.setFcmTokenAsync(fcmToken);
            }
        }
    }

    // noinspection InfiniteRecursionJS
    private async pollOnlineDataAsync(): Promise<void> {
        try {
            if (ch.isAuthenticated) {
                const context = ch.getContext() as UserContext;

                if (context.supportsOnlineData) {

                    const id: string | null = context.onlineData?.id || null;

                    if (!ApiProvider.isLoading) {
                        const modified: boolean = await ApiProvider.postAsync("/api/application/checkOnlineStatus", {id});

                        if (modified) {
                            await ApiProvider.postAsync("/api/application/getOnlineData");

                            const onlineData: OnlineData | null = (ch.findContext() as UserContext)?.onlineData;

                            if (onlineData) {
                                try {
                                    await this.onOnlineDataAsync(onlineData);
                                } catch (e) {
                                }
                            }
                        }
                    }
                }
            }
        } catch (error) {
            await PageRouteProvider.exception(error as any);
        } finally {
            await Utility.wait(1000 * AittaConstants.pollTimeoutInSeconds);

            await this.pollOnlineDataAsync();
        }
    }

    private async onOnlineDataAsync(onlineData: OnlineData): Promise<void> {
        // TODO: Process online data
    }

    public async authorizeAsync(): Promise<void> {
        if (ch.isAuthenticated) {
            // Clear cache when login
            UserInteractionDataStorage.clear();
        }
    }

    public async initializeAsync(): Promise<void> {
        if ((!this._initialized) && (!this._initializing)) {
            this._initializing = true;
            try {
                // Init All Dependencies
                EnumProvider.initialize();
                TransformProvider.initialize();
                Localizer.initialize();
                PageDefinitions.initialize();

                UserActivityHelper.initialize();

                await this.initializePushServiceAsync();
                
                // parallel thread, do not await
                // noinspection ES6MissingAwait
                //this.pollOnlineDataAsync();

                this._initialized = true;
            } finally {
                this._initializing = false;
            }
        }
    }

    public async onScanQrCodeAsync(sender: Catalog, dataProvider: CatalogDataProvider | null, code: string, customerId: string): Promise<void> {
        if (code) {
            let productAlreadyInBasket: boolean = false;
            let productInPostDelivery: boolean = false;

            const orderInBasket: OrderProductMobileInfo | null = dataProvider?.orderProducts.find(item => (item.productAssortment?.product?.code == code) || (item.productAssortment?.product?.e6Code == code)) ?? null;

            if (orderInBasket && orderInBasket.quantity > 0) {
                productAlreadyInBasket = true;
            } else {
                productInPostDelivery = await this.isProductPostDelivery(sender, code, customerId)
            }

            if ((productAlreadyInBasket) || (productInPostDelivery)) {
                const buttons: IMessageBoxButtons = {
                    okButton: (productInPostDelivery) ? Localizer.mobileOrderConfirmationPostDeliveryOkay : Localizer.genericAccept,
                    noButton: Localizer.mobileOrderConfirmationPostDeliveryScanNext

                }

                const messageBox: IMessageBox = {
                    title: (productInPostDelivery) ? Localizer.mobileOrderConfirmationPostDeliveryMessage : Localizer.mobileOrderConfirmationInBasketAlready,
                    icon: "fas fa-exclamation-triangle"
                }

                const result: DialogResult = await ch.messageBoxAsync(messageBox, "", buttons);

                switch (result) {
                    case DialogResult.OK: {
                        break;
                    }
                    case DialogResult.No:
                        await sender.onScanQrClickAsync();
                }
            }
        }
    }

    private async isProductPostDelivery(sender: Catalog, code: string, customerId: string): Promise<boolean> {
        const request = new IsProductPostDeliveryRequest();
        request.productCode = code;
        request.customerId = customerId;

        return await sender.postAsync("/api/mobileApp/isProductPostDelivery", request);
    }

    public get currentCustomer(): Customer | null {
        if (ch.isAuthenticated) {
            const context = ch.getContext() as UserContext;
            return context.currentCustomer;
        }
        return null;
    }

    public currentCustomerIsSelected(): boolean {
        return (this.currentCustomer != null);
    }

    public get canDoInventory(): boolean {
        return (
            (this.currentCustomer != null) &&
            (this.currentCustomer.serviceType != CustomerServiceType.Shelving) &&
            (this.currentCustomer.serviceType != CustomerServiceType.Subscription)
        );
    }
    
    private backgroundSelectCurrentCustomer(customerId: string | null): void {
        const request = new SelectCurrentCustomerRequest();
        request.customerId = customerId;
        
        setTimeout(() => ApiProvider.postAsync("/api/application/selectCurrentCustomer", request), 0);
    }

    public setDefaultCustomerGroupOrCustomer(customerGroupOrCustomer: CustomerGroup | Customer | CustomerMobileInfo | null): void {
        if (customerGroupOrCustomer == null) {
            UserInteractionDataStorage.set(AittaConstants.customerGroupIdCacheKey, null, DataStorageType.Session);
            UserInteractionDataStorage.set(AittaConstants.customerIdCacheKey, null, DataStorageType.Session);

            this.backgroundSelectCurrentCustomer(null);

            GoogleAnalyticsHelper.customerSelected(null);

        } else if (CustomerGroup.is(customerGroupOrCustomer)) {
            const customerGroup = customerGroupOrCustomer as CustomerGroup;

            UserInteractionDataStorage.set(AittaConstants.customerGroupIdCacheKey, customerGroup.id, DataStorageType.Session);
            //UserInteractionDataStorage.set("customerId", null);

            this.backgroundSelectCurrentCustomer(null);

            GoogleAnalyticsHelper.customerSelected(null);

        } else {
            const customer = customerGroupOrCustomer as CustomerMobileInfo;

            UserInteractionDataStorage.set(AittaConstants.customerGroupIdCacheKey, customer.customerGroupId, DataStorageType.Session);
            UserInteractionDataStorage.set(AittaConstants.customerIdCacheKey, customer.id, DataStorageType.Session);

            this.backgroundSelectCurrentCustomer(customer.id);

            GoogleAnalyticsHelper.customerSelected(customer)

        }
    }

    public getDefaultCustomerId(): string | null {
        return UserInteractionDataStorage.get(AittaConstants.customerIdCacheKey, null, DataStorageType.Session);
    }

    public getDefaultCustomerGroupId(): string | null {
        return UserInteractionDataStorage.get(AittaConstants.customerGroupIdCacheKey, null, DataStorageType.Session);
    }

    public getDefaultCustomer(customers: Customer[]): Customer | null {
        const defaultCustomerId: string | null = this.getDefaultCustomerId();
        return (defaultCustomerId)
            ? customers.firstOrDefault(item => item.id == defaultCustomerId)
            : null;
    }

    public getDefaultCustomerGroup(customers: Customer[]): CustomerGroup | null {
        const defaultCustomerGroupId: string | null = this.getDefaultCustomerGroupId();
        return (defaultCustomerGroupId)
            ? customers.firstOrDefault(item => item.customerGroup?.id == defaultCustomerGroupId)?.customerGroup || null
            : null;
    }

    public async getHomePageAsync(): Promise<PageRoute> {
        return await ApiProvider.postAsync("/api/account/getHomePage");
    }

    public async onLogoClickAsync(): Promise<void> {
        const route: PageRoute = await this.getHomePageAsync();
        await PageRouteProvider.redirectAsync(route, false, true);
    }

    public async setFavoriteAsync(id: string, favorite: boolean): Promise<void> {
        const request = new SetFavoriteRequest(id, favorite);

        await ApiProvider.postAsync("/api/user/setFavorite", request);

        PageCacheProvider.clear("*customer*");
        PageCacheProvider.clear("*order*");
        PageCacheProvider.clear("*product*");
        PageCacheProvider.clear("*inventory*");
    }

    public setFavorite(id: string, favorite: boolean): void {
        setTimeout(() => this.setFavoriteAsync(id, favorite), 0);
    }

    public get isIosNative(): boolean {
        return (typeof (window as any).webkit?.messageHandlers?.postMessageListener?.postMessage === "function");
    }

    public get mobileAppContent(): boolean {
        return ch.findRouteName().startsWith("Mobile/") || (ch.mobileApp || ch.mobile);
    }

    public getUserProfile(): IUserProfile | null {
        const userContext: UserContext | null = this.userContext;

        return (userContext)
            ? {
                roleName: Localizer.get(`RoleName.${userContext.roleName}`),
                userFullName: "{0}".format(userContext.user)
            }
            : null;
    }

    public getLeftNav(): ILeftNavProps | null {
        if ((ch.isAuthenticated) && (this.mobileAppContent)) {
            const menuItems: IMenuItem[] | null = [
                {
                    icon: {name: "far fa-home", size: IconSize.Small},
                    label: Localizer.mobileHomePageNameLanguageItemName,
                    route: PageDefinitions.mobileDashboardRoute
                },
                {
                    icon: "far fa-cart-plus",
                    label: Localizer.mobileDashboardOrderLanguageItemName,
                    route: PageDefinitions.mobileRegularOrderRoute,
                    visible: () => this.currentCustomerIsSelected()
                },
                {
                    icon: "far fa-shipping-fast",
                    label: Localizer.mobileDashboardExpressOrderLanguageItemName,
                    route: PageDefinitions.mobileExpressOrderRoute,
                    visible: () => (this.currentCustomerIsSelected() && !this.user.isShelverPartner)
                },
                {
                    icon: "fal fa-truck-loading",
                    label: Localizer.mobilePostDeliveryPageTitleLanguageItemName,
                    route: PageDefinitions.mobilePostDeliveryRoute,
                    visible: () => this.currentCustomerIsSelected()
                },
                {
                    icon: "far fa-history",
                    label: Localizer.mobileOrderHistoryPageNameLanguageItemName,
                    route: PageDefinitions.mobileOrderHistoryRoute,
                    visible: () => this.currentCustomerIsSelected()
                },
                {
                    icon: "far fa-scanner",
                    label: Localizer.mobileOrderLabelsPageNameLanguageItemName,
                    route: PageDefinitions.mobileOrderLabelsRoute,
                    visible: () => this.currentCustomerIsSelected()
                },
                {
                    icon: "far fa-inventory",
                    label: Localizer.mobileDashboardInventoryLanguageItemName,
                    route: PageDefinitions.mobileInventoryRoute,
                    visible: () => this.canDoInventory
                },
                {
                    icon: "far fa-warehouse-alt",
                    label: Localizer.mobileDashboardInventoryManagement,
                    route: PageDefinitions.mobileInventoryManagementRoute,
                    visible: () => this.currentCustomerIsSelected()
                },
                {
                    icon: "far fa-user",
                    label: Localizer.topNavAccount, //Account
                    bottom: true,
                    route: PageDefinitions.accountRoute
                },
                {
                    icon: "far fa-question-circle",
                    label: Localizer.faqPageTitleLanguageItemName,
                    bottom: true,
                    route: PageDefinitions.faqRoute
                },
                {
                    icon: "fal fa-arrow-circle-left",
                    label: Localizer.topNavLogoutLanguageItemName, //Logout
                    bottom: true,
                    route: PageDefinitions.logoutRoute
                }
            ];

            return {
                userProfile: this.getUserProfile(),
                className: mobileAppStyles.leftNav,
                items: menuItems ?? undefined,
                autoCollapse: true
            };
        }

        return null;
    }

    public get userContext(): UserContext | null {
        return (ch.isAuthenticated)
            ? (ch.getContext() as UserContext)
            : null;
    }

    public get user(): User {
        return ch.getUser();
    }

    public get fcmToken(): string | null {
        return this._fcmToken ||= (ch.findContext()?.fcmToken || null);
    }

    public get isMediqDevice(): boolean {
        return ((ch.findContext() as any)?.isMediqDevice === true);
    }

    public get hasMasterAccess(): boolean {
        const userContext: UserContext | null = this.userContext;
        return ((userContext != null) && ((userContext.isMaster) || (userContext.isAdmin) || (userContext.isSiteAdmin)));
    }

    public get hasAdminAccess(): boolean {
        const userContext: UserContext | null = this.userContext;
        return ((userContext != null) && ((userContext.isAdmin) || (userContext.isSiteAdmin)));
    }

    public get hasMediqAccess(): boolean {
        const userContext: UserContext | null = this.userContext;
        return ((userContext != null) && (userContext.isMediq));
    }

    public get footerLinks(): IFooterLink[] {
        return [
            {href: "https://mediq.fi/", label: Localizer.componentFooterFrontpageLanguageItemName},
            {href: "https://mediq.fi/yhteystiedot", label: Localizer.componentFooterContactLanguageItemName},
        ];
    }

    public getBanners(): BannerMessage[] {
        const context: ApplicationContext | null = ch.findContext();
        if (context) {
            const banners = (context as any)["banners"];
            if (banners) {
                return banners as BannerMessage[];
            }
        }
        return [];
    }

    public findBannerMessage(): BannerMessage | null {
        const page: string = ch.findRouteName();

        if (page) {
            const banners: BannerMessage[] = this
                .getBanners()
                .where(banner => banner.pageNames.length == 0 || banner.pageNames.contains(page));

            banners.sortBy(banner => banner.activatedAt);

            return banners.firstOrDefault();
        }

        return null;
    }
}

//Singleton
export default new AittaController();