// autogenerated
import Localizer from "@/localization/Localizer";

export default class ProductAttribute {

    public key: string = "";

    public value: string | null = null;

    public readonly isProductAttribute: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof ProductAttribute) || ((from as any).isProductAttribute === true));
    }

    public static as(from?: object | null): ProductAttribute | null {
        return (ProductAttribute.is(from)) ? from as ProductAttribute : null;
    }

    // #region Placeholder

    public static getKey(attribute: ProductAttribute): string {
        const code: string = `ProductAttribute.Key.${attribute.key}`;
        return Localizer.contains(code)
            ? Localizer.get(code)
            : attribute.key;
    }

    public static getValue(attribute: ProductAttribute): string {
        let code: string;
        switch (attribute.key) {
            case "CountryOfOrigin":
                code = `Country.${attribute.value?.replace(" ", "")}`;
                break;
            case "Color":
                code = `Color.${attribute.value?.replace(" ", "")}`;
                break;
            case "UnitOfMeasure":
                code = `UnitOfMeasure.${attribute.value?.replace(" ", "")}`;
                break;
            case "SalesUnit":

                const value: string = attribute.value ?? "";

                const splitValue: string[] = value.split(" ");

                if (splitValue.length == 2) {
                    const translation: string = Localizer.get(`UnitOfMeasure.${splitValue[1]}`);

                    return `${splitValue[0]} ${translation}`;
                }

                code = attribute.value ?? "";
                break;
            default:
                code = `ProductAttribute.Value.${attribute.value}`;
                break;
        }

        return Localizer.contains(code)
            ? Localizer.get(code)
            : attribute.value ?? "";
    }

    // #endregion
}