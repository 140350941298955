// autogenerated
import {ExportEncodingType} from "@/models/Enums";

export default class ExportCustomersToCsvRequest {

    public encodingType: ExportEncodingType = ExportEncodingType.Utf8;

    public readonly isExportCustomersToCsvRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof ExportCustomersToCsvRequest) || ((from as any).isExportCustomersToCsvRequest === true));
    }

    public static as(from?: object | null): ExportCustomersToCsvRequest | null {
        return (ExportCustomersToCsvRequest.is(from)) ? from as ExportCustomersToCsvRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}