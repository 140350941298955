// autogenerated

export default class HasProductOrderOverlapRequest {

    public orderId: string = "";

    public readonly isHasProductOrderOverlapRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof HasProductOrderOverlapRequest) || ((from as any).isHasProductOrderOverlapRequest === true));
    }

    public static as(from?: object | null): HasProductOrderOverlapRequest | null {
        return (HasProductOrderOverlapRequest.is(from)) ? from as HasProductOrderOverlapRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}