// autogenerated
import Customer from "@/models/server/Customer";

export default class CustomerGroup {

    public id: string = "";

    public name: string = "";

    public favorite: boolean = false;

    public deleted: boolean = false;

    public customers: Customer[] | null = null;

    public readonly isCustomerGroup: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof CustomerGroup) || ((from as any).isCustomerGroup === true));
    }

    public static as(from?: object | null): CustomerGroup | null {
        return (CustomerGroup.is(from)) ? from as CustomerGroup : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}