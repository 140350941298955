// autogenerated

export default class ResetOrderRequest {

    public orderId: string = "";

    public readonly isResetOrderRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof ResetOrderRequest) || ((from as any).isResetOrderRequest === true));
    }

    public static as(from?: object | null): ResetOrderRequest | null {
        return (ResetOrderRequest.is(from)) ? from as ResetOrderRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}