// autogenerated

export default class SaveCustomerGroupRequest {

    public id: string | null = null;

    public name: string | null = null;

    public readonly isSaveCustomerGroupRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof SaveCustomerGroupRequest) || ((from as any).isSaveCustomerGroupRequest === true));
    }

    public static as(from?: object | null): SaveCustomerGroupRequest | null {
        return (SaveCustomerGroupRequest.is(from)) ? from as SaveCustomerGroupRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}