// autogenerated
import Customer from "@/models/server/Customer";
import User from "@/models/server/User";

export default class Inventory {

    public id: string = "";

    public date: Date = new Date();

    public customerId: string = "";

    public customer: Customer | null = null;

    public createdAt: Date = new Date();

    public createdById: string = "";

    public createdBy: User | null = null;

    public readonly isInventory: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof Inventory) || ((from as any).isInventory === true));
    }

    public static as(from?: object | null): Inventory | null {
        return (Inventory.is(from)) ? from as Inventory : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}