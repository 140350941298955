// autogenerated
import {ExportEncodingType} from "@/models/Enums";
import BaseProductAssortmentConsumptionsRequest from "./BaseProductAssortmentConsumptionsRequest";

export default class ExportProductAssortmentConsumptionsRequest extends BaseProductAssortmentConsumptionsRequest {

    public encodingType: ExportEncodingType = ExportEncodingType.Utf8;

    public readonly isExportProductAssortmentConsumptionsRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof ExportProductAssortmentConsumptionsRequest) || ((from as any).isExportProductAssortmentConsumptionsRequest === true));
    }

    public static as(from?: object | null): ExportProductAssortmentConsumptionsRequest | null {
        return (ExportProductAssortmentConsumptionsRequest.is(from)) ? from as ExportProductAssortmentConsumptionsRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}