// autogenerated
import {SortDirection} from "@reapptor-apps/reapptor-toolkit";
import {ProductLabelType, ProductLabelState, CustomerHandler} from "@/models/Enums";

export default class ListProductLabelsRequest {

    public customerGroupId: string | null = null;

    public customerId: string | null = null;

    public sortColumnName: string | null = null;

    public productLabelType: ProductLabelType | null = null;

    public productLabelState: ProductLabelState | null = null;

    public customerHandler: CustomerHandler | null = null;

    public showPrintedOnly: boolean = false;

    public printedDate: Date | null = null;

    public sortDirection: SortDirection | null = null;

    public search: string | null = null;

    public pageNumber: number = 0;

    public pageSize: number = 0;

    public readonly isListProductLabelsRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof ListProductLabelsRequest) || ((from as any).isListProductLabelsRequest === true));
    }

    public static as(from?: object | null): ListProductLabelsRequest | null {
        return (ListProductLabelsRequest.is(from)) ? from as ListProductLabelsRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}