import {PageRoute, BasePageDefinitions, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import {IEditCustomerProps} from "@/pages/EditCustomer/EditCustomer";
import {IProductDetailsProps} from "@/pages/Mobile/ProductDetails/ProductDetails";
import {IProductAuditProps} from "@/pages/ProductAudit/ProductAudit";

export default class PageDefinitions extends BasePageDefinitions {

    protected async require(pageContainer: string, pageName: string): Promise<any> {
        return await require(`../pages/${pageContainer}${pageName}/${pageName}`);
    }
    
    constructor() {
        super();
        PageRouteProvider.sendParametersOnRedirect = true;
    }

    public static readonly logoutRouteName: string = "Logout";

    public static readonly dummyRouteName: string = "Dummy";

    public static readonly dummyRoute: PageRoute = new PageRoute(PageDefinitions.dummyRouteName);

    public static readonly accountRouteName: string = "Account";

    public static readonly accountRoute: PageRoute = new PageRoute(PageDefinitions.accountRouteName);

    public static readonly loginRouteName: string = "Login";

    public static readonly loginRoute: PageRoute = new PageRoute(PageDefinitions.loginRouteName);

    public static readonly adminRouteName: string = "Admin";

    public static readonly adminRoute: PageRoute = new PageRoute(PageDefinitions.adminRouteName);

    public static readonly dashboardRouteName: string = "Dashboard";

    public static readonly dashboardRoute: PageRoute = new PageRoute(PageDefinitions.dashboardRouteName);

    public static readonly errorRouteName: string = "Error";

    public static readonly errorRoute: PageRoute = new PageRoute(PageDefinitions.errorRouteName);

    public static readonly offlineRouteName: string = "Offline";

    public static readonly offlineRoute: PageRoute = new PageRoute(PageDefinitions.offlineRouteName);

    public static readonly changePasswordRouteName: string = "ChangePassword";

    public static readonly changePasswordRoute: PageRoute = new PageRoute(PageDefinitions.changePasswordRouteName);

    public static readonly forgotPasswordRouteName: string = "ForgotPassword";
    
    public static readonly forgotPasswordRoute: PageRoute = new PageRoute(PageDefinitions.forgotPasswordRouteName);
    
    public static readonly resetPasswordRouteName: string = "ResetPassword";

    public static readonly resetPasswordRoute: PageRoute = new PageRoute(PageDefinitions.resetPasswordRouteName);

    public static readonly homeRouteName: string = "Home";

    public static readonly homeRoute: PageRoute = new PageRoute(PageDefinitions.homeRouteName);

    public static readonly userManagementRouteName: string = "UserManagement";

    public static readonly userManagementRoute: PageRoute = new PageRoute(PageDefinitions.userManagementRouteName);

    public static readonly productManagementRouteName: string = "ProductManagement";

    public static readonly productManagementRoute: PageRoute = new PageRoute(PageDefinitions.productManagementRouteName);

    public static readonly customerManagementRouteName: string = "CustomerManagement";

    public static readonly customerManagementRoute: PageRoute = new PageRoute(PageDefinitions.customerManagementRouteName);

    public static readonly labelsManagementRouteName: string = "LabelsManagement";

    public static readonly labelsManagementRoute: PageRoute = new PageRoute(PageDefinitions.labelsManagementRouteName);
    
    public static readonly reportsManagementRouteName: string = "ReportManagement";

    public static readonly reportsManagementRoute: PageRoute = new PageRoute(PageDefinitions.reportsManagementRouteName);

    public static readonly inventoryManagementRouteName: string = "InventoryManagement";

    public static readonly inventoryManagementRoute: PageRoute = new PageRoute(PageDefinitions.inventoryManagementRouteName);

    public static readonly editCustomerRouteName: string = "EditCustomer";

    public static readonly editCustomerRoute: PageRoute = new PageRoute(PageDefinitions.editCustomerRouteName);

    public static readonly editCustomer: (customerId?: string | null, customerGroupId?: string | null) => PageRoute = (customerId?: string | null, customerGroupId?: string | null) => new PageRoute(PageDefinitions.editCustomerRouteName, null, null, { customerId, customerGroupId } as IEditCustomerProps);
    
    public static readonly orderManagementRouteName: string = "OrderManagement";

    public static readonly orderManagementRoute: PageRoute = new PageRoute(PageDefinitions.orderManagementRouteName);
    
    public static readonly bannerManagementRouteName: string = "BannerManagement";

    public static readonly bannerManagementRoute: PageRoute = new PageRoute(PageDefinitions.bannerManagementRouteName);
    
    public static readonly applicationSettingsRouteName: string = "ApplicationSettings";

    public static readonly applicationSettingsRoute: PageRoute = new PageRoute(PageDefinitions.applicationSettingsRouteName);

    public static readonly adminConsoleRouteName: string = "AdminConsole";

    public static readonly adminConsoleRoute: PageRoute = new PageRoute(PageDefinitions.adminConsoleRouteName);

    public static readonly notificationsRouteName: string = "Notifications";

    public static readonly notificationsRoute: PageRoute = new PageRoute(PageDefinitions.notificationsRouteName);

    public static readonly myCalendarRouteName: string = "MyCalendar";

    public static readonly myCalendarRoute: PageRoute = new PageRoute(PageDefinitions.myCalendarRouteName);

    public static readonly faqRouteName: string = "Faq";

    public static readonly faqRoute: PageRoute = new PageRoute(PageDefinitions.faqRouteName);
    
    public static readonly FaqManagementRouteName: string = "FaqManagement";

    public static readonly FaqManagementRoute: PageRoute = new PageRoute(PageDefinitions.FaqManagementRouteName);
    
    public static readonly ProductAuditRouteName: string = "ProductAudit";

    public static readonly ProductAuditRoute: PageRoute = new PageRoute(PageDefinitions.ProductAuditRouteName);
    
    public static readonly IfsAuditRouteName: string = "IfsAudit";

    public static readonly IfsAuditRoute: PageRoute = new PageRoute(PageDefinitions.IfsAuditRouteName);

    public static readonly AuditRouteDetails: (productId?: string | null, customerGroupId?: string | null, customerId?: string | null) => PageRoute = (productId?: string | null, customerGroupId?: string | null, customerId?: string | null) => new PageRoute(PageDefinitions.ProductAuditRouteName, null, null, { productId, customerGroupId, customerId } as IProductAuditProps);
    
    // #region Mobile

    public static readonly mobileDashboardRouteName: string = "Mobile/Dashboard";

    public static readonly mobileDashboardRoute: PageRoute = new PageRoute(PageDefinitions.mobileDashboardRouteName);
    
    public static readonly mobileRegularOrderRouteName: string = "Mobile/RegularOrder";

    public static readonly mobileRegularOrderRoute: PageRoute = new PageRoute(PageDefinitions.mobileRegularOrderRouteName);
    
    public static readonly mobileExpressOrderRouteName: string = "Mobile/ExpressOrder";

    public static readonly mobileExpressOrderRoute: PageRoute = new PageRoute(PageDefinitions.mobileExpressOrderRouteName);
    
    public static readonly mobileOrderHistoryRouteName: string = "Mobile/OrderHistory";

    public static readonly mobileOrderHistoryRoute: PageRoute = new PageRoute(PageDefinitions.mobileOrderHistoryRouteName);
    
    public static readonly mobileOrderLabelsRouteName: string = "Mobile/OrderLabels";

    public static readonly mobileOrderLabelsRoute: PageRoute = new PageRoute(PageDefinitions.mobileOrderLabelsRouteName);
    
    public static readonly mobileInventoryRouteName: string = "Mobile/Inventory";

    public static readonly mobileInventoryRoute: PageRoute = new PageRoute(PageDefinitions.mobileInventoryRouteName);

    public static readonly mobileInventoryManagementRouteName: string = "Mobile/InventoryManagement";

    public static readonly mobileInventoryManagementRoute: PageRoute = new PageRoute(PageDefinitions.mobileInventoryManagementRouteName);

    public static readonly mobileInventoryDetailsRouteName: string = "Mobile/InventoryDetails";

    public static readonly mobileInventoryDetailsRoute: (inventoryId: string) => PageRoute = (inventoryId: string) => new PageRoute(PageDefinitions.mobileInventoryDetailsRouteName, null, inventoryId);

    public static readonly mobileProductDetailsRouteName: string = "Mobile/ProductDetails";
    
    public static readonly mobileProductDetails: (productIdOrAssortmentIdOrOrderProductId: string, replacementProductId: string | null) => PageRoute = (productIdOrAssortmentIdOrOrderProductId: string, replacementProductId: string | null) => new PageRoute(PageDefinitions.mobileProductDetailsRouteName, null, productIdOrAssortmentIdOrOrderProductId,  { productReplacementId: replacementProductId } as IProductDetailsProps);
    
    public static readonly mobileOrderDetailsRouteName: string = "Mobile/OrderHistory/OrderDetails";
    
    public static readonly mobileOrderDetails: (orderId: string) => PageRoute = (orderId: string) => new PageRoute(PageDefinitions.mobileOrderDetailsRouteName, null, orderId);

    public static readonly mobilePostDeliveryRouteName: string = "Mobile/PostDelivery";

    public static readonly mobilePostDeliveryRoute: PageRoute = new PageRoute(PageDefinitions.mobilePostDeliveryRouteName);
}

new PageDefinitions();