// autogenerated
import {ExportEncodingType} from "@/models/Enums";

export default class ExportOrderToCsvRequest {

    public orderId: string = "";

    public encodingType: ExportEncodingType = ExportEncodingType.Utf8;

    public readonly isExportOrderToCsvRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof ExportOrderToCsvRequest) || ((from as any).isExportOrderToCsvRequest === true));
    }

    public static as(from?: object | null): ExportOrderToCsvRequest | null {
        return (ExportOrderToCsvRequest.is(from)) ? from as ExportOrderToCsvRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}