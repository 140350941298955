import {BaseTransformProvider, GeoLocation, StringUtility, TFormat, Utility} from "@reapptor-apps/reapptor-toolkit";
import {ITitleModel, SelectListGroup, SelectListItem, StatusListItem} from "@reapptor-apps/reapptor-react-components";
import CustomerGroup from "../models/server/CustomerGroup";
import User from "../models/server/User";
import UserRole from "../models/server/UserRole";
import Customer from "../models/server/Customer";
import {IIconProps} from "@reapptor-apps/reapptor-react-components";
import Product from "@/models/server/Product";
import CustomerContact from "../models/server/CustomerContact";
import CustomerCode from "@/models/server/CustomerCode";
import ProductTemplate from "@/models/server/ProductTemplate";
import Inventory from "@/models/server/Inventory";
import Localizer from "../localization/Localizer";
import CustomerMobileInfo from "@/models/server/CustomerMobileInfo";
import {CustomerServiceType, ProductAssortmentFilter} from "@/models/Enums";
import EnumProvider from "@/providers/EnumProvider";

class TransformProvider extends BaseTransformProvider {

    protected createSelectListItem(value: string, text: string, subtext: string, groupName?: string | null, favorite?: boolean | null): SelectListItem {
        const listItem = new SelectListItem(value, text, subtext);
        listItem.favorite = (favorite == true);
        if (groupName) {
            listItem.group = SelectListGroup.create(groupName);
        }
        return listItem;
    }

    public constructor() {
        super();
    }

    public toTitle(item: any): ITitleModel {

        let label: string | null = null;
        let description: string | null = null;

        if (item != null) {
            label = Utility.findStringValueByAccessor(item, ["label", "name"]);
            description = Utility.findStringValueByAccessor(item, ["description", "text"]);
        }

        return {
            description: description || "",
            label: label || "",
            icon: null
        };
    }

    public userToString(item: User): string {
        const firstname: string = (item.firstname != null) ? item.firstname : "";
        const lastName: string = (item.lastName != null) ? item.lastName : "";

        if (firstname || lastName) {
            return `${firstname} ${lastName}`.trim();
        }
        return item.username;
    }

    public customerGroupToString(item: CustomerGroup): string {
        return item.name;
    }

    public customerToString(item: Customer): string {
        const code: string = item.codeInfo || "";
        const name: string = item.name || "";
        return (code)
            ? `${code.trim()} - ${name.trim()}`
            : name.trim();
    }

    public locationToString(location: GeoLocation | null) {
        return (location != null)
            ? [location.address, location.postalCode, location.city].filter(item => !!item).join(", ")
            : "";
    }

    public toString(item: any, format?: TFormat | null): string {

        if (item == null) {
            return "";
        }

        if ((item instanceof CustomerGroup) || item.isCustomerGroup) {
            return this.customerGroupToString(item as CustomerGroup);
        }

        if ((item instanceof User) || item.isUser) {
            return this.userToString(item as User);
        }

        if ((item instanceof Customer) || item.isCustomer) {
            return this.customerToString(item as Customer);
        }

        if ((item instanceof GeoLocation) || item.isGeoLocation) {
            return this.locationToString(item as GeoLocation);
        }

        return super.toString(item, format);
    }

    public toSelectListItem(item: any): SelectListItem {
        if ((item instanceof CustomerGroup) || (item.isCustomerGroup)) {
            return this.toCustomerGroupListItem(item as CustomerGroup);
        }

        if ((item instanceof Customer) || (item.isCustomer)) {
            return this.toCustomerListItem(item as Customer);
        }

        if ((item instanceof CustomerMobileInfo) || (item.isCustomerMobileInfo)) {
            return this.toCustomerMobileInfoListItem(item as CustomerMobileInfo);
        }

        if ((item instanceof User) || (item.isUser)) {
            return this.toUserListItem(item as User);
        }

        if ((item instanceof UserRole) || (item.isUserRole)) {
            return this.toUserRoleListItem(item as UserRole);
        }

        if ((item instanceof Product) || (item.isProduct)) {
            return this.toProductListItem(item as Product);
        }

        if ((item instanceof CustomerContact) || (item.isCustomerContact)) {
            return this.toCustomerContactListItem(item as CustomerContact);
        }

        if ((item instanceof CustomerCode) || (item.isCustomerCode)) {
            return this.toCustomerCodeListItem(item as CustomerCode);
        }

        if ((item instanceof ProductTemplate) || (item.isProductTemplate)) {
            return this.toProductTemplateListItem(item as ProductTemplate);
        }

        if ((item instanceof Inventory) || (item.isInventory)) {
            return this.toInventoryListItem(item as Inventory);
        }

        return super.toSelectListItem(item) as SelectListItem;
    }

    public toUserListItem(item: User): StatusListItem {
        const selectedItem = new StatusListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = this.toString(item);
        selectedItem.subtext = item.email || item.phone || "";
        selectedItem.lineThrough = item.isDeleted;
        selectedItem.completed = !item.isLocked;

        return selectedItem;
    }

    public toUserRoleListItem(item: UserRole): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.roleName;
        selectedItem.text = Localizer.get(`RoleName.${item.roleName}`);
        return selectedItem;
    }

    public toCustomerGroupListItem(item: CustomerGroup): StatusListItem {
        const selectedItem = new StatusListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = item.name;
        selectedItem.favorite = item.favorite;
        selectedItem.lineThrough = (item.deleted);
        selectedItem.completed = (!item.deleted);

        return selectedItem;
    }

    public toCustomerListItem(item: Customer): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = this.customerToString(item);
        selectedItem.subtext = Utility.formatValue(item.serviceType, nameof<CustomerServiceType>());
        selectedItem.favorite = item.favorite;
        selectedItem.group = (item.customerGroup != null)
            ? SelectListGroup.create(item.customerGroup.name)
            : null;
        return selectedItem;
    }

    public toCustomerMobileInfoListItem(item: CustomerMobileInfo): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = item.name || item.codeInfo || "";
        selectedItem.subtext = item.codeInfo || "";
        selectedItem.favorite = item.favorite;
        selectedItem.group = (item.customerGroupName != null)
            ? SelectListGroup.create(item.customerGroupName)
            : null;
        return selectedItem;
    }

    public toCustomerOrCustomerGroupSelectItem(item: CustomerGroup | Customer, customerGroupClassName?: string | null, customerClassName?: string | null): SelectListItem {
        const listItem = new StatusListItem();
        listItem.ref = item;
        listItem.value = item.id;
        listItem.text = item.name;
        listItem.favorite = item.favorite;

        if (CustomerGroup.is(item)) {
            listItem.completed = {name: "fal fa-object-group"} as IIconProps;
            listItem.className = customerGroupClassName || null;
        } else {
            const customer: Customer = item as Customer;
            listItem.completed = true;
            listItem.className = customerClassName || null;
            listItem.text = this.customerToString(customer);
            listItem.subtext = Utility.formatValue(customer.serviceType, nameof<CustomerServiceType>());
        }

        return listItem;
    }

    public toProductListItem(item: Product): SelectListItem {
        const selectedItem = new StatusListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = item.name;
        selectedItem.subtext = "{0} ({1})".format(item.code, item.manufactureCode);
        selectedItem.lineThrough = item.deleted;
        selectedItem.completed = true;

        return selectedItem;
    }

    public toCustomerContactListItem(item: CustomerContact): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = item.name || Localizer.editCustomerPageCustomerContactsPanelNewContact;

        selectedItem.subtext = (!item.info)
            ? "{0}".format(item.email)
            : "{0} ({1})".format(item.email, item.info);

        return selectedItem;
    }

    public toCustomerCodeListItem(item: CustomerCode): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = item.code || Localizer.editCustomerPageCustomerCodesPanelNewCode;
        selectedItem.subtext = (item.ifs || item.express)
            ? `${item.name} (${[item.ifs && Localizer.customerCodeIfs, item.express && Localizer.customerCodeExpress].where(item => !!item).join(", ")})`
            : item.name ?? "" ;

        return selectedItem;
    }

    public toProductTemplateListItem(item: ProductTemplate): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = item.name;

        selectedItem.subtext = (item.description && item.description.length > 30)
            ? "{0}...".format(item.description.substr(0, 30))
            : item.description ?? "";

        return selectedItem;
    }

    public toInventoryListItem(item: Inventory): SelectListItem {
        const selectedItem = new SelectListItem();
        selectedItem.ref = item;
        selectedItem.value = item.id;
        selectedItem.text = item.date.format("D");
        selectedItem.subtext = (item.createdBy != null)
            ? "{0}".format(item.createdBy)
            : "";
        return selectedItem;
    }

    public toProductAssortmentFilterItem(item: ProductAssortmentFilter, forCustomer: boolean): SelectListItem {
        const selectedItem: SelectListItem = EnumProvider.getProductAssortmentFilterItem(item);
        
        if (forCustomer) {
            switch (item) {
                case ProductAssortmentFilter.Selected:
                    selectedItem.text = Localizer.productsToolbarAssortmentFilterEnumSelectedForCustomerFilter;
                    break;
                case ProductAssortmentFilter.NotSelected:
                    selectedItem.text = Localizer.productsToolbarAssortmentFilterEnumNotSelectedForCustomerFilter;
                    break;
            }
            
        }
        
        return selectedItem;
    }
}

export default new TransformProvider();