// autogenerated

export default class SetFavoriteRequest {

    public id: string = "";

    public favorite: boolean = false;

    public readonly isSetFavoriteRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof SetFavoriteRequest) || ((from as any).isSetFavoriteRequest === true));
    }

    public static as(from?: object | null): SetFavoriteRequest | null {
        return (SetFavoriteRequest.is(from)) ? from as SetFavoriteRequest : null;
    }

    // #region Placeholder

    constructor(id: string, favorite: boolean) {
        this.id = id;
        this.favorite = favorite;
    }

    // #endregion
}