// autogenerated

export default class SelectCurrentCustomerRequest {

    public customerId: string | null = null;

    public readonly isSelectCurrentCustomerRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof SelectCurrentCustomerRequest) || ((from as any).isSelectCurrentCustomerRequest === true));
    }

    public static as(from?: object | null): SelectCurrentCustomerRequest | null {
        return (SelectCurrentCustomerRequest.is(from)) ? from as SelectCurrentCustomerRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}