// autogenerated

export default class DeliveryOrderRequest {

    public orderId: string = "";

    public comment: string | null = null;

    public readonly isDeliveryOrderRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof DeliveryOrderRequest) || ((from as any).isDeliveryOrderRequest === true));
    }

    public static as(from?: object | null): DeliveryOrderRequest | null {
        return (DeliveryOrderRequest.is(from)) ? from as DeliveryOrderRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}