// autogenerated

export default class ListInventoriesRequest {

    public customerId: string = "";

    public readonly isListInventoriesRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof ListInventoriesRequest) || ((from as any).isListInventoriesRequest === true));
    }

    public static as(from?: object | null): ListInventoriesRequest | null {
        return (ListInventoriesRequest.is(from)) ? from as ListInventoriesRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}