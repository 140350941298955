// autogenerated
import {LocalizationLanguage} from "@/models/Enums";

export default class Localization {

    public id: string = "";

    public finnish: string | null = null;

    public english: string | null = null;

    public norwegian: string | null = null;

    public swedish: string | null = null;

    public readonly isLocalization: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof Localization) || ((from as any).isLocalization === true));
    }

    public static as(from?: object | null): Localization | null {
        return (Localization.is(from)) ? from as Localization : null;
    }

    // #region Placeholder

    public static getLocalizedItem(model: Localization, locale: string | LocalizationLanguage): string {
        switch (locale) {
            case "fi":
            case LocalizationLanguage.Finnish:
                return model.finnish ?? "";
            case "en":
            case LocalizationLanguage.English:
                return model.english ?? "";
            case "nb":
            case LocalizationLanguage.Norwegian:
                return model.norwegian ?? "";
            case "sv":
            case LocalizationLanguage.Swedish:
                return model.swedish ?? "";
            default: return "";
        }
    }

    public static setLocalizedItem(model: Localization, language: LocalizationLanguage, value: string): void {
        switch (language) {
            case LocalizationLanguage.Finnish:
                model.finnish = value;
                break;
            case LocalizationLanguage.English:
                model.english = value;
                break;
            case LocalizationLanguage.Norwegian:
                model.norwegian = value;
                break;
            case LocalizationLanguage.Swedish:
                model.swedish = value;
                break;
        }
    }

    // #endregion
}