// autogenerated
import {CustomerHandler, CustomerLabelHandler, CustomerServiceType, DeliveryDayOfWeek} from "@/models/Enums";
import CustomerCode from "@/models/server/CustomerCode";
import CustomerContact from "@/models/server/CustomerContact";
import CustomerCalendarDate from "@/models/server/CustomerCalendarDate";

export default class SaveCustomerRequest {

    public id: string | null = null;

    public customerGroupId: string = "";

    public name: string | null = null;

    public code: string | null = null;

    public codes: CustomerCode[] | null = null;

    public handler: CustomerHandler = CustomerHandler.Customer;

    public labelHandler: CustomerLabelHandler = CustomerLabelHandler.Customer;

    public serviceType: CustomerServiceType = CustomerServiceType.Aitta;

    public spaces: string | null = null;

    public notes: string | null = null;

    public comments: string | null = null;

    public info: string | null = null;

    public deliveryStartDate: Date = new Date();

    public deliveryEndDate: Date | null = null;

    public deliveryInterval: number = 0;

    public closeInterval: number = 0;

    public deliveryDay: DeliveryDayOfWeek = DeliveryDayOfWeek.Monday;

    public contacts: CustomerContact[] | null = null;

    public virtualAddress: string | null = null;

    public formattedAddress: string | null = null;

    public externalAddress: string | null = null;

    public addressDetails: string | null = null;

    public ref: string | null = null;

    public refFirstName: string | null = null;

    public refLastName: string | null = null;

    public calendarDates: CustomerCalendarDate[] | null = null;

    public selectionMeeting: Date | null = null;

    public readonly isSaveCustomerRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof SaveCustomerRequest) || ((from as any).isSaveCustomerRequest === true));
    }

    public static as(from?: object | null): SaveCustomerRequest | null {
        return (SaveCustomerRequest.is(from)) ? from as SaveCustomerRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}