// autogenerated
import {ProductAssortmentType} from "@/models/Enums";

export default class SetProductSelectionRequest {

    public productId: string = "";

    public customerGroupId: string | null = null;

    public customerId: string | null = null;

    public productAssortmentType: ProductAssortmentType = ProductAssortmentType.Aitta;

    public templateId: string | null = null;

    public validate: boolean = false;

    public includeProductSelection: boolean = false;

    public orderThreshold: number | null = null;

    public orderQuantity: number | null = null;

    public selected: boolean = false;

    public bastard: boolean = false;

    public readonly isSetProductSelectionRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof SetProductSelectionRequest) || ((from as any).isSetProductSelectionRequest === true));
    }

    public static as(from?: object | null): SetProductSelectionRequest | null {
        return (SetProductSelectionRequest.is(from)) ? from as SetProductSelectionRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}