import React from "react";
import {BaseComponent} from "@reapptor-apps/reapptor-react-common";
import {ButtonType, DropdownOrderBy, EmailInput, Form, IconSize, Inline, List, OneColumn, Panel, TextInput, ToolbarButton, ToolbarContainer} from "@reapptor-apps/reapptor-react-components";
import Customer from "@/models/server/Customer";
import CustomerContact from "@/models/server/CustomerContact";
import AittaConstants from "@/helpers/AittaConstants";
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import Localizer from "@/localization/Localizer";

import styles from "./CustomerContactsPanel.module.scss";

interface ICustomerContactsPanelProps {
    customer: Customer;

    onChange(): Promise<void>
}

interface ICustomerContactsPanelState {
    selectedContact: CustomerContact | null;
}

export default class CustomerContactsPanel extends BaseComponent<ICustomerContactsPanelProps, ICustomerContactsPanelState> {

    state: ICustomerContactsPanelState = {
        selectedContact: this.contacts.firstOrDefault(),
    };

    private get customer(): Customer {
        return this.props.customer;
    }

    private readonly _contactsRef: React.RefObject<List<CustomerContact>> = React.createRef();
    private readonly _contactNameTextInputRef: React.RefObject<TextInput> = React.createRef();

    private get selectedContact(): CustomerContact | null {
        return this.state.selectedContact;
    }

    private get contacts(): CustomerContact[] {
        return this.customer.contacts ?? (this.customer.contacts = []);
    }

    private async onEmailChangeAsync(value: string): Promise<void> {
        if (this.selectedContact) {
            this.selectedContact.email = value;

            await this.props.onChange();

            await this.reRenderAsync();
        }
    }

    private async onInfoChangeAsync(value: string): Promise<void> {
        if (this.selectedContact) {
            this.selectedContact.info = value;

            await this.props.onChange();

            await this.reRenderAsync();
        }
    }

    private async onChangeContactAsync(selectedContact: CustomerContact): Promise<void> {
        await this.setState({selectedContact});
    }

    private async addContactAsync(): Promise<void> {
        const newContact = new CustomerContact();
        newContact.name = Localizer.editCustomerPageCustomerContactsPanelNewContact;
        newContact.id = Utility.newGuid();

        this.contacts.push(newContact);
        
        await this.setState({selectedContact: newContact});

        await this._contactNameTextInputRef.current!.showEditAsync(true);

        await this.props.onChange();
    }

    private async deleteContactAsync(): Promise<void> {

        let selectedContact: CustomerContact | null = this.selectedContact;

        if (selectedContact) {
            const index: number = this.contacts.findIndex(item => item.id == selectedContact!.id);
            
            this.contacts.removeAt(index);
            
            selectedContact = (this.contacts.length > 0)
                ? (index > 0)
                    ? this.contacts[index - 1]
                    : this.contacts[0]
                : null;
            
            await this.setState({selectedContact});

            await this.props.onChange();
        }
    }

    private async onContactNameChangeAsync(value: string): Promise<void> {
        if (this.selectedContact) {
            this.selectedContact.name = value;

            await this.props.onChange();

            await this.reRenderAsync();
        }
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();
    }

    public readonly _formRef: React.RefObject<Form> = React.createRef();

    public render(): React.ReactNode {
        return (
            <Panel className={styles.customerContactsPanel} title={Localizer.editCustomerPageCustomerContactsPanelTitle}>

                <div className="col d-flex flex-column">

                    <ToolbarContainer>

                        <Inline>

                            <ToolbarButton label={Localizer.genericAdd}
                                           icon={{name: "plus", size: IconSize.Large}}
                                           type={ButtonType.Primary}
                                           onClick={() => this.addContactAsync()}
                            />

                            <ToolbarButton label={Localizer.genericDelete}
                                           icon={{name: "trash", size: IconSize.Large}}
                                           type={ButtonType.Primary}
                                           onClick={() => this.deleteContactAsync()}
                            />

                        </Inline>

                    </ToolbarContainer>

                    <div className="row h-100">

                        <div className="col-md-6 pb-6">

                            <List required noGrouping absoluteListItems trackChanges
                                  id="contacts"
                                  ref={this._contactsRef}
                                  maxHeight={"auto"}
                                  orderBy={DropdownOrderBy.None}
                                  filterMinLength={10}
                                  noDataText={Localizer.editCustomerPageCustomerContactsPanelNoContacts}
                                  selectedItem={this.selectedContact}
                                  items={this.contacts}
                                  onChange={(_, item) => this.onChangeContactAsync(item!)}
                            />

                        </div>

                        <div className="col-md-6">

                            <Form ref={this._formRef}>

                                <OneColumn>

                                    <TextInput noAutoComplete required
                                               ref={this._contactNameTextInputRef}
                                               id="name"
                                               label={Localizer.genericName}
                                               readonly={!this.selectedContact}
                                               value={this.selectedContact?.name}
                                               onChange={(_, value) => this.onContactNameChangeAsync(value)}
                                    />

                                    <EmailInput noAutoComplete
                                                id="email"
                                                label={Localizer.genericEmail}
                                                readonly={!this.selectedContact}
                                                value={this.selectedContact?.email}
                                                onChange={(_, value) => this.onEmailChangeAsync(value)}
                                    />

                                    <TextInput noAutoComplete
                                               id="info"
                                               label={Localizer.editCustomerPageCustomerContactsPanelInformation}
                                               maxLength={AittaConstants.hashLength}
                                               readonly={!this.selectedContact}
                                               value={this.selectedContact?.info}
                                               onChange={(_, value) => this.onInfoChangeAsync(value)}

                                    />

                                </OneColumn>

                            </Form>

                        </div>

                    </div>

                </div>

            </Panel>
        );
    }
};