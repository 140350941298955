// autogenerated
import {SortDirection} from "@reapptor-apps/reapptor-toolkit";
import {ProductAuditAction} from "@/models/Enums";

export default class ListProductAuditRequest {

    public productId: string | null = null;

    public customerGroupId: string | null = null;

    public customerId: string | null = null;

    public action: ProductAuditAction | null = null;

    public sortColumnName: string | null = null;

    public sortDirection: SortDirection | null = null;

    public search: string | null = null;

    public pageNumber: number = 0;

    public pageSize: number = 0;

    public readonly isListProductAuditRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof ListProductAuditRequest) || ((from as any).isListProductAuditRequest === true));
    }

    public static as(from?: object | null): ListProductAuditRequest | null {
        return (ListProductAuditRequest.is(from)) ? from as ListProductAuditRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}