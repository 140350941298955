// autogenerated
import {SortDirection} from "@reapptor-apps/reapptor-toolkit";

export default class ListProductAssortmentsRequest {

    public templateId: string | null = null;

    public customerGroupId: string | null = null;

    public customerId: string | null = null;

    public sortColumnName: string | null = null;

    public sortDirection: SortDirection | null = null;

    public search: string | null = null;

    public pageNumber: number = 0;

    public pageSize: number = 0;

    public readonly isListProductAssortmentsRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof ListProductAssortmentsRequest) || ((from as any).isListProductAssortmentsRequest === true));
    }

    public static as(from?: object | null): ListProductAssortmentsRequest | null {
        return (ListProductAssortmentsRequest.is(from)) ? from as ListProductAssortmentsRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}