// autogenerated

export default class SaveProductAssortmentNoteRequest {

    public productAssortmentId: string = "";

    public note: string | null = null;

    public readonly isSaveProductAssortmentNoteRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof SaveProductAssortmentNoteRequest) || ((from as any).isSaveProductAssortmentNoteRequest === true));
    }

    public static as(from?: object | null): SaveProductAssortmentNoteRequest | null {
        return (SaveProductAssortmentNoteRequest.is(from)) ? from as SaveProductAssortmentNoteRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}