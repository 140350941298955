// autogenerated

export default class CustomerContact {

    public id: string = "";

    public name: string | null = null;

    public email: string | null = null;

    public info: string | null = null;

    public readonly isCustomerContact: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof CustomerContact) || ((from as any).isCustomerContact === true));
    }

    public static as(from?: object | null): CustomerContact | null {
        return (CustomerContact.is(from)) ? from as CustomerContact : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}