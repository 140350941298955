// autogenerated
import {LocalizationLanguage} from "@/models/Enums";
import Localization from "@/models/server/Localization";

export default class FaqItem {

    public id: string = "";

    public key: string | null = null;

    public title: Localization | null = null;

    public description: Localization | null = null;

    public isFaq: boolean = false;

    public readonly isFaqItem: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof FaqItem) || ((from as any).isFaqItem === true));
    }

    public static as(from?: object | null): FaqItem | null {
        return (FaqItem.is(from)) ? from as FaqItem : null;
    }

    // #region Placeholder

    constructor() {
        this.title = new Localization();
        this.description = new Localization();
    }

    public static getLocalizedTitle(model: FaqItem, locale: string | LocalizationLanguage): string {
        return Localization.getLocalizedItem(model.title!, locale) || "";
    }

    public static setTitle(model: FaqItem, locale: LocalizationLanguage, value: string): void {
        Localization.setLocalizedItem(model.title!, locale, value);
    }

    public static setDescription(model: FaqItem, locale: LocalizationLanguage, value: string): void {
        Localization.setLocalizedItem(model.description!, locale, value);
    }

    public static getLocalizedDescription(model: FaqItem, locale: string | LocalizationLanguage): string {
        return Localization.getLocalizedItem(model.description!, locale) || "";
    }

    // #endregion
}