// autogenerated

export default class OrderProductSelection {

    public productAssortmentId: string = "";

    public replacementProductAssortmentId: string | null = null;

    public quantity: number = 0;

    public readonly isOrderProductSelection: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof OrderProductSelection) || ((from as any).isOrderProductSelection === true));
    }

    public static as(from?: object | null): OrderProductSelection | null {
        return (OrderProductSelection.is(from)) ? from as OrderProductSelection : null;
    }

    // #region Placeholder

    public static create(productAssortmentId: string, quantity: number): OrderProductSelection {
        const selection = new OrderProductSelection();
        selection.productAssortmentId = productAssortmentId;
        selection.quantity = quantity;
        return selection;
    }

    // #endregion
}