// autogenerated
import BaseConcurrencyRequest from "./BaseConcurrencyRequest";
import OrderProductSelection from "@/models/server/OrderProductSelection";

export default class SaveOrderProductRequest extends BaseConcurrencyRequest {

    public customerId: string = "";

    public expressOrder: boolean = false;

    public product: OrderProductSelection | null = null;

    public readonly isSaveOrderProductRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof SaveOrderProductRequest) || ((from as any).isSaveOrderProductRequest === true));
    }

    public static as(from?: object | null): SaveOrderProductRequest | null {
        return (SaveOrderProductRequest.is(from)) ? from as SaveOrderProductRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}