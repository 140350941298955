// autogenerated
import {GeoLocation} from "@reapptor-apps/reapptor-toolkit";
import {IUser} from "@reapptor-apps/reapptor-react-common";
import {AuthType} from "@/models/Enums";
import UserInvitation from "@/models/server/UserInvitation";
import UserRole from "@/models/server/UserRole";
import AittaConstants from "@/helpers/AittaConstants";

export default class User implements IUser {

    public id: string = "";

    public username: string = "";

    public email: string = "";

    public agreementAccepted: boolean = false;

    public registrationAccepted: boolean = false;

    public isLocked: boolean = false;

    public isDeleted: boolean = false;

    public hasPassword: boolean = false;

    public invitations: UserInvitation[] | null = null;

    public authType: AuthType = AuthType.Email;

    public firstname: string | null = null;

    public lastName: string | null = null;

    public middleName: string | null = null;

    public language: string | null = null;

    public phone: string | null = null;

    public modifiedAt: Date = new Date();

    public modifiedByUserName: string | null = null;

    public role: UserRole | null = null;

    public roles: UserRole[] = [];

    public homeLocation: GeoLocation | null = null;

    public address: string | null = null;

    public city: string | null = null;

    public postalCode: string | null = null;

    public externalId: string | null = null;

    public external: boolean = false;

    public fullName: string | null = null;

    public isSiteAdmin: boolean = false;

    public isAdmin: boolean = false;

    public isMaster: boolean = false;

    public isManager: boolean = false;

    public isShelver: boolean = false;

    public isShelverPartner: boolean = false;

    public isMediq: boolean = false;

    public isCustomer: boolean = false;

    public supportsOrdering: boolean = false;

    public supportsProductSelection: boolean = false;

    public supportsCalendar: boolean = false;

    public supportsMobileApp: boolean = false;

    public supportsReporting: boolean = false;

    public supportsInventory: boolean = false;

    public hasNoCustomers: boolean = false;

    public hasDailyNotifications: boolean = false;

    public hasGroupLevelReportsAccess: boolean = false;

    public readonly isUser: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof User) || ((from as any).isUser === true));
    }

    public static as(from?: object | null): User | null {
        return (User.is(from)) ? from as User : null;
    }

    // #region Placeholder

    public static getPrimaryRole(user: User): UserRole {
        if (user.roles.length == 0) {
            User.setPrimaryRole(user, AittaConstants.masterRole);
        }

        return user.roles[0];
    }

    public static setPrimaryRole(user: User, roleName: string, customerGroupId?: string | null): void {
        const mediqRoles = AittaConstants.mediqRoles.includes(roleName);

        if (mediqRoles) {
            customerGroupId = null;
        }

        const role = new UserRole(roleName, null, customerGroupId);

        user.roles = [role];
    }

    public static isValidAccount(user: User): boolean {
        return (
            (!!user.firstname) && (!!user.lastName) && (!!user.language) &&
            (
                ((user.authType == AuthType.Email) && (!!user.email)) || ((user.authType == AuthType.Phone) && (!!user.phone))
            )
        )
    }

    public static isValidRole(user: User): boolean {
        return (
            (user.roles.length > 0) &&
            (user.roles.every(role => !!role.customerGroupId || AittaConstants.mediqRoles.includes(role.roleName)))
        )
    }

    public static isValid(user: User): boolean {
        return (this.isValidAccount(user) && this.isValidRole(user));
    }

    // #endregion
}