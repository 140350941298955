// autogenerated
import {SortDirection} from "@reapptor-apps/reapptor-toolkit";

export default class ListFaqRequest {

    public sortColumnName: string | null = null;

    public sortDirection: SortDirection | null = null;

    public search: string | null = null;

    public readonly isListFaqRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof ListFaqRequest) || ((from as any).isListFaqRequest === true));
    }

    public static as(from?: object | null): ListFaqRequest | null {
        return (ListFaqRequest.is(from)) ? from as ListFaqRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}