// autogenerated
import BaseConcurrencyRequest from "./BaseConcurrencyRequest";

export default class SendOrderRequest extends BaseConcurrencyRequest {

    public orderId: string = "";

    public close: boolean = false;

    public readonly isSendOrderRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof SendOrderRequest) || ((from as any).isSendOrderRequest === true));
    }

    public static as(from?: object | null): SendOrderRequest | null {
        return (SendOrderRequest.is(from)) ? from as SendOrderRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}