export enum SyncActionType {
    GetOrderAgentTask,

    GetSalesReportAgentTask
}

export enum AittaReportFilter {
    /**
     * Order lines are created through any application
     */
    All,

    /**
     * Order lines are created using the customer level (Aitta) selection only
     */
    AittaOnly,

    /**
     * Order lines are created using the contract level selections only
     */
    NotAittaOnly,

    /**
     * Order lines are created using the customer-level (Aitta) selections which are active now.
     */
    ActiveAittaOnly,

    /**
     * Order lines are created using the contract level selections, which are not active
     */
    NotAittaAndNotActive
}

export enum ErpType {
    E6,

    Ifs,
}

export enum ProductAuditAction {
    ProductCreated,

    ProductUpdated,

    ProductDeleted,

    ProductRestored,

    AssortmentCreated,

    AssortmentRestored,

    AssortmentDeleted,

    ReplacementCreated,

    ReplacementDeleted,

    TpTmValueChanged
}

export enum InventoryStateFilter {
    Completed,

    NotCompleted,

    All
}

export enum ReportType {
    Annual,

    Quarterly,

    Monthly
}

export enum ExportEncodingType {
    Utf8,

    Unicode,

    Latin1,

    OpenXml,
}

export enum ProductLabelType {
    Manual,

    ProductChanged,

    ProductAdded,

    ProductReplaced,

    ProductRemoved
}

export enum ProductLabelState {
    Open,

    Printed
}

export enum DeliveryDayOfWeek {
    Monday,

    Tuesday,

    Wednesday,

    Thursday,

    Friday,
}

export enum ProductAccessoryType {
    RelatedProduct,

    CorrespondingProduct
}

export enum ProductReplacementLevel {
    Global,

    CustomerGroup,

    Customer
}

export enum ProductReplacementType {
    Temporary,

    Permanent
}

export enum OrderStatus {
    Open,

    Closed,

    Submitted,

    PartiallyDelivered,

    Delivered
}

export enum OrderProductStatus {
    Open,

    Closed,

    Submitted,

    PartiallyDelivered,

    Delivered,

    Ignored
}

export enum OrderProductErpAction {
    Modified,

    Replaced,

    Added,

    Deleted
}

export enum CustomerLabelHandler {
    Customer,

    Mediq,

    Shelver
}

export enum CustomerHandler {
    Customer,

    Mediq,

    /**
     * Länsiö
     */
    Lansio,

    PostNord,

    HubLogistics
}

export enum CustomerServiceType {
    /**
     * Aitta (MobileApp, Calendar)
     */
    Aitta,

    /**
     * MiniAitta (MobileApp)
     */
    MiniAitta,

    /**
     * Mobiiliaitta (MobileApp, Calendar)
     */
    MobileAitta,

    /**
     * Hyllytyspalvelu (MobileApp, Calendar)
     */
    Shelving,

    /**
     * Tilauspalvelu
     */
    Subscription
}

export enum CalendarDateMark {
    None,

    PublicHoliday,

    CustomerAway,

    CloseDate,

    DeliveryDate
}

export enum ProductType {
    Unknown,

    Factory,

    Stock
}

export enum ProductAssortmentType {
    Aitta,

    CustomerGroup,

    Customer,

    Template
}

export enum ProductAssortmentFilter {
    /**
     * All Mediq products
     */
    All,

    /**
     * Contract based products
     */
    Contract,

    /**
     * Aitta productsAitta
     */
    Selected,

    /**
     * Not Aitta products
     */
    NotSelected,

    /**
     * Customer assortment product without contract
     */
    NonContract,

    /// <summary>
    /// Customer based products
    /// </summary>
    Customer
}

export enum ProductTemplateFilter {
    All,

    Selected,

    NotSelected
}

export enum ProductOrderFilter {
    CustomerSelection,

    CustomerGroupSelection,

    Assumed,

    Order,

    Top30,

    Favorites
}

export enum LoginResultStatus {
    Success,

    NotFound,

    Deleted,

    Locked,

    /**
     * Token security stamp does not correspond to user security stamp
     */
    TokenInvalidSecurityStamp,

    /**
     * Token already used
     */
    TokenUsed,

    /**
     * Token invalid or expired
     */
    TokenInvalidOrExpired
}

export enum InvitationType {
    Invitation,

    ResetPassword,

    ForgotPassword
}

export enum NotificationType {
    Sms,

    Application,

    Push,

    Email
}

export enum AuthType {
    Email,

    Phone
}

export enum AuditTimestamp {
    CreatedAt,

    ModifiedAt
}

export enum SavePasswordResultStatus {
    Success,

    WeakPassword,

    WrongCurrent
}

export enum DayOfWeek {
    Sunday,

    Monday,

    Tuesday,

    Wednesday,

    Thursday,

    Friday,

    Saturday
}

export enum ScheduleAction {
    Undefined
}

export enum LocalizationLanguage {
    Finnish,

    English,

    Norwegian,

    Swedish
}

export enum TraceLevel
{
    Off = 0,

    Error = 1,

    Warning = 2,

    Info = 3,

    Verbose = 4,
}