import React from "react";
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import {ch, PageRoute, PageRouteProvider} from "@reapptor-apps/reapptor-react-common";
import {Button, ButtonContainer, ButtonType, IconSize, IIconProps, PageContainer, PageHeader, PageRow, Tab, TabContainer, TabModel, ToolbarButton} from "@reapptor-apps/reapptor-react-components";
import {CalendarDateMark} from "@/models/Enums";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import Customer from "@/models/server/Customer";
import CustomerGroup from "@/models/server/CustomerGroup";
import SaveCustomerRequest from "@/models/server/requests/SaveCustomerRequest";
import OrderCalendarPanel from "@/pages/EditCustomer/OrderCalendarPanel/OrderCalendarPanel";
import CustomerContractPanel from "@/pages/EditCustomer/CustomerContractPanel/CustomerContractPanel";
import PageDefinitions from "@/providers/PageDefinitions";
import CustomerCalendarDate from "@/models/server/CustomerCalendarDate";
import GenerateCustomerCalendarRequest from "@/models/server/requests/GenerateCustomerCalendarRequest";
import GenerateCustomerCalendarResponse from "@/models/server/responses/GenerateCustomerCalendarResponse";
import SaveCustomerResponse from "@/models/server/responses/SaveCustomerResponse";
import CustomerGeneralInformationPanel from "@/pages/EditCustomer/CustomerGeneralInformationPanel/CustomerGeneralInformationPanel";
import CustomerNotesPanel from "@/pages/EditCustomer/CustomerNotesPanel/CustomerNotesPanel";
import CustomerContactsPanel from "@/pages/EditCustomer/CustomerContactsPanel/CustomerContactsPanel";
import CustomerCodesPanel from "@/pages/EditCustomer/CustomerCodesPanel/CustomerCodesPanel";
import CustomerMeetingsPanel from "@/pages/EditCustomer/CustomerMeetingsPanel/CustomerMeetingsPanel";
import GoogleAnalyticsHelper from "@/helpers/GoogleAnalyticsHelper";
import AittaController from "@/pages/AittaController";
import Localizer from "@/localization/Localizer";

import styles from "./EditCustomer.module.scss"

export interface IEditCustomerProps {
    customerId?: string | null;
    customerGroupId?: string | null;
}

interface IEditCustomerState {
    customerGroups: CustomerGroup[];
    customer: Customer | null;
    isModified: boolean;
    selectedTabIndex: number;
    originalSupportsCalendar: boolean;
}

export default class EditCustomer extends AuthorizedPage<IEditCustomerProps, IEditCustomerState> {

    state: IEditCustomerState = {
        customerGroups: [],
        customer: null,
        isModified: false,
        selectedTabIndex: 0,
        originalSupportsCalendar: false
    };

    private readonly _tabContainerRef: React.RefObject<TabContainer> = React.createRef();
    private readonly _customerGeneralInformationPanelRef: React.RefObject<CustomerGeneralInformationPanel> = React.createRef();
    private readonly _customerNotesPanelRef: React.RefObject<CustomerNotesPanel> = React.createRef();
    private readonly _customerContractPanelRef: React.RefObject<CustomerContractPanel> = React.createRef();
    private readonly _customerContactsPanelRef: React.RefObject<CustomerContactsPanel> = React.createRef();
    private readonly _customerCodesPanelRef: React.RefObject<CustomerCodesPanel> = React.createRef();
    private readonly _orderCalendarPanelRef: React.RefObject<OrderCalendarPanel> = React.createRef();
    private _originalCustomer: Customer | null = null;

    private async fetchCustomerAsync(customerId: string): Promise<Customer> {
        return await this.postAsync("/api/customerManagement/getCustomer", customerId);
    }

    private async generateCustomerCalendarAsync(customer: Customer, calendarDates: CustomerCalendarDate[] | null = null): Promise<CustomerCalendarDate[]> {
        const request = new GenerateCustomerCalendarRequest();
        request.customerId = customer.id;
        request.deliveryStartDate = customer.deliveryStartDate;
        request.deliveryEndDate = customer.deliveryEndDate;
        request.deliveryInterval = customer.deliveryInterval;
        request.deliveryDay = customer.deliveryDay;
        request.closeInterval = customer.closeInterval;
        request.customerAwayDays = CustomerCalendarDate.getCustomerAwayDays(calendarDates);
        request.openOrderCloseDate = CustomerCalendarDate.getOpenOrderDay(calendarDates, CalendarDateMark.CloseDate);
        request.openOrderDeliveryDate = CustomerCalendarDate.getOpenOrderDay(calendarDates, CalendarDateMark.DeliveryDate);

        const response: GenerateCustomerCalendarResponse = await this.postAsync("/api/customerManagement/generateCustomerCalendar", request);
        
        return response.calendarDates ?? [];
    }

    private get customerGroups(): CustomerGroup[] {
        return this.state.customerGroups;
    }

    private get customer(): Customer | null {
        return this.state.customer;
    }

    private get isNew(): boolean {
        return (this.customer != null) && (!this.customer.id);
    }

    private get isModified(): boolean {
        return ((this.state.isModified) || (this.isNew));
    }

    private get userHasFullWriteAccess(): boolean {
        return (AittaController.user.isAdmin) || (AittaController.user.isSiteAdmin);
    }
    
    private get supportsCalendar(): boolean {
        return (this.customer != null) && (Customer.supportsCalendar(this.customer));
    }

    private async setIsModifiedAsync(isModified = true, customerCodeIsModified = false, calendarDeliverySettingsModified = false): Promise<void> {
        const newModified: boolean = (isModified !== this.state.isModified);
        const newSupportsCustomerCalendar: boolean = (this.supportsCalendar !== this.state.originalSupportsCalendar);

        if ((newSupportsCustomerCalendar) || (newModified) || (customerCodeIsModified)) {
            await this.setState({isModified, originalSupportsCalendar: this.supportsCalendar});
        }
    }

    private async cancelModifyingAsync(): Promise<void> {
        Utility.copyTo(this._originalCustomer, this.customer);
        Utility.restoreDate(this.customer);

        await this.setIsModifiedAsync(false);
    }

    private get accountWarningIcon(): IIconProps | undefined {
        const visible: boolean = (this.customer != null) && (!Customer.isValidCustomer(this.customer));
        return visible ? {name: "far fa-exclamation-triangle", className: styles.warningIcon} : undefined;
    }

    private async onChangeTabAsync(tab: TabModel): Promise<void> {
        await this.setState({selectedTabIndex: tab.index});
    }

    private get calendarDeliverySettingsModified(): boolean {
        return this._orderCalendarPanelRef.current?.getSettingsModified() ?? false;
    }
    
    public getTitle(): string {
        return Localizer.editCustomerPageTitle;
    }

    public getSubtitle(): string {
        return (this.customer)
            ? (this.customer.name)
                ? (this.customer.name.includes(this.customer.code))
                    ? this.customer.name
                    : "<mark>{0}</mark> {1}".format(this.customer.code, this.customer.name)
                : Localizer.editCustomerPageSubTitle
            : Localizer.genericLoading;
    }

    public async saveCustomerAsync(): Promise<void> {
        if (this.calendarDeliverySettingsModified) {
            await this.alertWarningAsync(Localizer.editCustomerPageNeedApplyCalendar, true, true)
            return;
        }
        
        let customer: Customer = this.customer!;
        const isNew: boolean = this.isNew;

        if (!Customer.isValidCustomer(customer)) {
            await this.reRenderAsync();

            if (this._customerGeneralInformationPanelRef.current?._formRef.current) {
                await this._customerGeneralInformationPanelRef.current._formRef.current.validateAsync();
            }

            if (this._customerContractPanelRef.current?._formRef.current) {
                await this._customerContractPanelRef.current._formRef.current.validateAsync();
            }

            return;
        }

        const request = new SaveCustomerRequest();
        request.id = customer.id;
        request.customerGroupId = customer.customerGroupId;
        request.name = customer.name;
        request.code = customer.code;
        request.handler = customer.handler;
        request.labelHandler = customer.labelHandler;
        request.serviceType = customer.serviceType;
        request.spaces = customer.spaces;
        request.notes = customer.notes;
        request.comments = customer.comments;
        request.info = customer.info;
        request.deliveryStartDate = customer.deliveryStartDate;
        request.deliveryEndDate = customer.deliveryEndDate;
        request.deliveryInterval = customer.deliveryInterval;
        request.closeInterval = customer.closeInterval;
        request.deliveryDay = customer.deliveryDay;
        request.formattedAddress = customer.location?.formattedAddress || null;
        request.virtualAddress = customer.virtualAddress;
        request.externalAddress = customer.externalAddress;
        request.addressDetails = customer.addressDetails;
        request.ref = customer.ref;
        request.refFirstName = customer.refFirstName;
        request.refLastName = customer.refLastName;
        request.contacts = customer.contacts ?? [];
        request.codes = customer.codes ?? [];
        request.calendarDates = customer.calendarDates;
        request.selectionMeeting = customer.selectionMeeting;

        const response: SaveCustomerResponse = await this.postAsync("/api/customerManagement/saveCustomer", request);

        if (response.customer == null) {

            const message: string = (response.duplicatedName)
                ? Localizer.editCustomerPageErrorDuplicatedName.format(customer.name)
                : (response.duplicatedCode)
                    ? Localizer.editCustomerPageErrorDuplicatedCode.format(customer.code)
                    : Localizer.editCustomerPageErrorUnknownReasons

            await this.alertErrorAsync(message, true);

            return;
        }

        customer = response.customer;

        await this.setState({customer, isModified: false});

        if (isNew) {
            AittaController.setDefaultCustomerGroupOrCustomer(customer);

            const route: PageRoute = PageDefinitions.editCustomer(customer.id);

            PageRouteProvider.push(route, null, true)
        }

        const message: string = (isNew)
            ? Localizer.editCustomerPageNewCustomerCreated.format(customer.name)
            : Localizer.editCustomerPageCustomerUpdated.format(customer.name);

        if (isNew) {
            GoogleAnalyticsHelper.customerCreated(customer);
        }
        
        await ch.flyoutMessageAsync(message);
    }

    public async initializeAsync(): Promise<void> {
        const params = this.parameters as IEditCustomerProps as IEditCustomerProps | null;

        const customerId: string = params?.customerId || "";
        const customerGroupId: string = params?.customerGroupId || "";

        await super.initializeAsync();

        const isNew: boolean = (!customerId);

        const customerGroups: CustomerGroup[] = (this.userHasFullWriteAccess)
            ? await this.postAsync("/api/customerManagement/listCustomerGroups")
            : [];

        let customer: Customer;

        if (isNew) {
            customer = Customer.create();

            if (customerGroupId) {
                customer.customerGroupId = customerGroupId;
            }

            customer.calendarDates = await this.generateCustomerCalendarAsync(customer);

        } else {
            customer = await this.fetchCustomerAsync(customerId);

            AittaController.setDefaultCustomerGroupOrCustomer(customer);

            this._originalCustomer = Utility.clone(customer);
        }

        const supportsCalendar: boolean = Customer.supportsCalendar(customer);

        await this.setState({customer, customerGroups, originalSupportsCalendar: supportsCalendar});

        if (isNew) {
            await this._tabContainerRef.current?.model.activateTabAsync(0);
        }
    }

    public async beforeRedirectAsync(nextRoute: PageRoute, innerRedirect: boolean): Promise<boolean> {

        if (this.isModified) {
            const canLeaveWithoutSaved: boolean = await this.confirmAsync(Localizer.editCustomerPageUnsavedChanges)

            if (!canLeaveWithoutSaved) {
                return false;
            }
        }

        return super.beforeRedirectAsync(nextRoute, innerRedirect);
    }
    
    public render(): React.ReactNode {
       
        if (!this.isAuthenticated) {
            return <React.Fragment/>;
        }
        
        return (
            <PageContainer fullWidth fullHeight className={styles.editCustomer}>

                <PageHeader withTabs
                            title={this.getTitle()}
                            subtitle={this.getSubtitle()}
                >

                    <ToolbarButton title={Localizer.genericBack}
                                   icon={{name: "fas arrow-alt-circle-left"}}
                                   type={ButtonType.Primary}
                                   route={PageDefinitions.customerManagementRoute}
                    />

                </PageHeader>

                <PageRow>

                    <div className="col">

                        {
                            (this.customer) &&
                            (
                                <React.Fragment>

                                    <TabContainer ref={this._tabContainerRef}
                                                  id="customerManagementTabs"
                                                  key={`customerManagementTabs_${this.supportsCalendar}`}
                                                  onSelect={(tab) => this.onChangeTabAsync(tab)}>

                                        {
                                            (this.userHasFullWriteAccess) &&
                                            (
                                                <Tab id="customerGeneralInfo" title={Localizer.editCustomerPageGeneralInfoTabTitle} icon={this.accountWarningIcon}>

                                                    <div className={"w-fit-content"}>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="d-flex flex-nowrap w-100 no-first-last-margin">

                                                                    <CustomerGeneralInformationPanel ref={this._customerGeneralInformationPanelRef}
                                                                                                     customer={this.customer}
                                                                                                     customerGroups={this.customerGroups}
                                                                                                     onChange={(sender, codeModified: boolean) => this.setIsModifiedAsync(true, codeModified)}
                                                                    />

                                                                    <CustomerContractPanel ref={this._customerContractPanelRef}
                                                                                           customer={this.customer}
                                                                                           onChange={() => this.setIsModifiedAsync()}
                                                                    />

                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>

                                                </Tab>
                                            )
                                        }

                                        <Tab id="customerAdditionalInfo" title={Localizer.editCustomerPageAdditionalInfoTabTitle}>

                                            <div className={"w-fit-content"}>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="d-flex flex-nowrap w-100 no-first-last-margin">

                                                            <CustomerContactsPanel ref={this._customerContactsPanelRef}
                                                                                   customer={this.customer}
                                                                                   onChange={() => this.setIsModifiedAsync()}
                                                            />

                                                            <CustomerCodesPanel ref={this._customerCodesPanelRef}
                                                                                customer={this.customer}
                                                                                onChange={() => this.setIsModifiedAsync()}
                                                            />

                                                        </div>

                                                    </div>

                                                    <div className="col-md-12">
                                                        
                                                        <div className="d-flex flex-nowrap w-100 no-first-last-margin">

                                                            <CustomerMeetingsPanel customer={this.customer}
                                                                                   onChange={() => this.setIsModifiedAsync()}
                                                            />

                                                        </div>

                                                    </div>
                                                    
                                                    <div className="col-md-12">
                                                        
                                                        <div className="d-flex flex-nowrap w-100 no-first-last-margin">

                                                            <CustomerNotesPanel ref={this._customerNotesPanelRef}
                                                                                customer={this.customer}
                                                                                onChange={() => this.setIsModifiedAsync()}
                                                            />

                                                        </div>

                                                    </div>

                                                </div>

                                            </div>

                                        </Tab>

                                        {
                                            (this.supportsCalendar) && (this.userHasFullWriteAccess) &&
                                            (
                                                <Tab id="orderCalendar" title={Localizer.editCustomerPageCalendarTabTitle}>

                                                    <OrderCalendarPanel ref={this._orderCalendarPanelRef}
                                                                        customer={this.customer}
                                                                        generateCustomerCalendar={(sender, customer, calendarDates) => this.generateCustomerCalendarAsync(customer, calendarDates)}
                                                                        onChange={(calendarDeliverySettingsModified: boolean) => this.setIsModifiedAsync(true, false, calendarDeliverySettingsModified)}
                                                    />

                                                </Tab>
                                            )
                                        }

                                    </TabContainer>

                                    <ButtonContainer className={styles.buttons}>

                                        {
                                            ((!this.isNew) && (this.isModified)) &&
                                            (
                                                <Button id={"cancelModify"}
                                                        minWidth={90}
                                                        label={Localizer.genericCancel}
                                                        type={ButtonType.Primary}
                                                        icon={{name: "far ban", size: IconSize.Large}}
                                                        confirm={Localizer.adminConfirmationButtonRollback}
                                                        onClick={() => this.cancelModifyingAsync()}
                                                />
                                            )
                                        }

                                        <Button id={"saveCustomer"}
                                                minWidth={90}
                                                disabled={!this.isModified}
                                                label={Localizer.genericSave}
                                                type={ButtonType.Orange}
                                                icon={{name: "far save", size: IconSize.Large}}
                                                onClick={() => this.saveCustomerAsync()}
                                        />

                                    </ButtonContainer>

                                </React.Fragment>
                            )
                        }

                    </div>

                </PageRow>

            </PageContainer>
        );
    }
}