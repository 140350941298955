// autogenerated
import {SortDirection} from "@reapptor-apps/reapptor-toolkit";
import {ETagRequest} from "@reapptor-apps/reapptor-react-common";

export default class ListInventoryProductsRequest extends ETagRequest {

    public inventoryId: string = "";

    public search: string | null = null;

    public inventoried: boolean | null = null;

    public sortColumnName: string | null = null;

    public sortDirection: SortDirection | null = null;

    public includeProductGroups: boolean = false;

    public readonly isListInventoryProductsRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof ListInventoryProductsRequest) || ((from as any).isListInventoryProductsRequest === true));
    }

    public static as(from?: object | null): ListInventoryProductsRequest | null {
        return (ListInventoryProductsRequest.is(from)) ? from as ListInventoryProductsRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}