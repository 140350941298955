// autogenerated

export default class OrderingRule {

    public productAssortmentId: string = "";

    public newOrderThreshold: number | null = null;

    public newOrderQuantity: number | null = null;

    public readonly isOrderingRule: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof OrderingRule) || ((from as any).isOrderingRule === true));
    }

    public static as(from?: object | null): OrderingRule | null {
        return (OrderingRule.is(from)) ? from as OrderingRule : null;
    }

    // #region Placeholder

    constructor(productAssortmentId: string, newOrderThreshold: number | null, newOrderQuantity: number | null) {
        this.productAssortmentId = productAssortmentId;
        this.newOrderThreshold = newOrderThreshold;
        this.newOrderQuantity = newOrderQuantity;
    }

    // #endregion
}