// autogenerated
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import RevenueChartDataItem from "@/models/server/RevenueChartDataItem";

export default class BaseReport {

    public yearMinus0: number = 0;

    public yearMinus1: number = 0;

    public yearMinus2: number = 0;

    public yearMinus3: number = 0;

    public quarterMinus0: number = 0;

    public quarterMinus1: number = 0;

    public quarterMinus2: number = 0;

    public quarterMinus3: number = 0;

    public monthMinus0: number = 0;

    public monthMinus1: number = 0;

    public monthMinus2: number = 0;

    public monthMinus3: number = 0;

    public monthMinus4: number = 0;

    public monthMinus5: number = 0;

    public monthMinus6: number = 0;

    public monthMinus7: number = 0;

    public monthMinus8: number = 0;

    public monthMinus9: number = 0;

    public monthMinus10: number = 0;

    public monthMinus11: number = 0;

    public intervals: number[] | null = null;

    public readonly isBaseReport: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof BaseReport) || ((from as any).isBaseReport === true));
    }

    public static as(from?: object | null): BaseReport | null {
        return (BaseReport.is(from)) ? from as BaseReport : null;
    }

    // #region Placeholder

    private static getQuarter(value: Date): number {
        return Math.trunc(value.getMonth() / 3) + 1;
    }

    private static getQuarterName(minus: number): string {
        const date: Date = Utility.today().addMonths(3 * minus);
        const quarter: number = this.getQuarter(date);

        return "Q{0}\n<small>{1}</small>".format(quarter, date.getFullYear());
        // return (false)
        //     ? "Q{0}({1})".format(quarter, date.getFullYear())
        //     : "Q{0}\n<small>{1}</small>".format(quarter, date.getFullYear());
    }

    private static getMonthName(minus: number): string {
        const date: Date = Utility.today().addMonths(minus);
        return "{0:MMM}\n<small>{1}</small>".format(date, date.getFullYear());
        // return (revenue)
        //     ? "{0:MMM}({1})".format(date, date.getFullYear())
        //     : "{0:MMM}\n<small>{1}</small>".format(date, date.getFullYear());
    }

    private static getYearName(minus: number): string {
        const date: Date = Utility.today().addYears(minus);
        return "{0}".format(date.getFullYear());
    }

    public static toRevenueReportChartDataItem(getName: () => string, value: number): RevenueChartDataItem {
        const name: string = getName()
            .replace("<small>", "")
            .replace("</small>", "");

        return {
            name: name,
            revenue: value
        };
    }

    public static getYearMinus0Name(): string {
        return BaseReport.getYearName(0);
    }

    public static getYearMinus1Name(): string {
        return BaseReport.getYearName(-1);
    }

    public static getYearMinus2Name(): string {
        return BaseReport.getYearName(-2);
    }

    public static getYearMinus3Name(): string {
        return BaseReport.getYearName(-3);
    }

    public static getQuarterMinus0Name(): string {
        return BaseReport.getQuarterName(0);
    }

    public static getQuarterMinus1Name(): string {
        return BaseReport.getQuarterName(-1);
    }

    public static getQuarterMinus2Name(): string {
        return BaseReport.getQuarterName(-2);
    }

    public static getQuarterMinus3Name(): string {
        return BaseReport.getQuarterName(-3);
    }

    public static getMonthMinus0Name(): string {
        return BaseReport.getMonthName(0);
    }

    public static getMonthMinus1Name(): string {
        return BaseReport.getMonthName(-1);
    }

    public static getMonthMinus2Name(): string {
        return BaseReport.getMonthName(-2);
    }

    public static getMonthMinus3Name(): string {
        return BaseReport.getMonthName(-3);
    }

    public static getMonthMinus4Name(): string {
        return BaseReport.getMonthName(-4);
    }

    public static getMonthMinus5Name(): string {
        return BaseReport.getMonthName(-5);
    }

    public static getMonthMinus6Name(): string {
        return BaseReport.getMonthName(-6);
    }

    public static getMonthMinus7Name(): string {
        return BaseReport.getMonthName(-7);
    }

    public static getMonthMinus8Name(): string {
        return BaseReport.getMonthName(-8);
    }

    public static getMonthMinus9Name(): string {
        return BaseReport.getMonthName(-9);
    }

    public static getMonthMinus10Name(): string {
        return BaseReport.getMonthName(-10);
    }

    public static getMonthMinus11Name(): string {
        return BaseReport.getMonthName(-11);
    }

    // #endregion
}