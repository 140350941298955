// autogenerated
import OrderProductSelection from "@/models/server/OrderProductSelection";

export default class AddOrderProductsRequest {

    public customerId: string = "";

    public expressOrder: boolean = false;

    public products: OrderProductSelection[] | null = null;

    public readonly isAddOrderProductsRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof AddOrderProductsRequest) || ((from as any).isAddOrderProductsRequest === true));
    }

    public static as(from?: object | null): AddOrderProductsRequest | null {
        return (AddOrderProductsRequest.is(from)) ? from as AddOrderProductsRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}