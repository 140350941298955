// autogenerated

export default class ListProductLabelOrdersRequest {

    public customerId: string = "";

    public search: string | null = null;

    public onlyNotOrdered: boolean | null = null;

    public readonly isListProductLabelOrdersRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof ListProductLabelOrdersRequest) || ((from as any).isListProductLabelOrdersRequest === true));
    }

    public static as(from?: object | null): ListProductLabelOrdersRequest | null {
        return (ListProductLabelOrdersRequest.is(from)) ? from as ListProductLabelOrdersRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}