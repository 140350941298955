// autogenerated

export default class GetProductLabelsTotalCountRequest {

    public printed: boolean = false;

    public readonly isGetProductLabelsTotalCountRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof GetProductLabelsTotalCountRequest) || ((from as any).isGetProductLabelsTotalCountRequest === true));
    }

    public static as(from?: object | null): GetProductLabelsTotalCountRequest | null {
        return (GetProductLabelsTotalCountRequest.is(from)) ? from as GetProductLabelsTotalCountRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}