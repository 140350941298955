// autogenerated
import Localizer from "@/localization/Localizer";

export default class ProductGroupMobileInfo {

    public id: string = "";

    public parentId: string | null = null;

    public parent: ProductGroupMobileInfo | null = null;

    public name: string = "";

    public imageId: string | null = null;

    public readonly isProductGroupMobileInfo: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof ProductGroupMobileInfo) || ((from as any).isProductGroupMobileInfo === true));
    }

    public static as(from?: object | null): ProductGroupMobileInfo | null {
        return (ProductGroupMobileInfo.is(from)) ? from as ProductGroupMobileInfo : null;
    }

    // #region Placeholder

    public icon: string = "";

    constructor(id: string = "", name: string = "", icon: string = "", parent: ProductGroupMobileInfo | null = null) {
        this.id = id;
        this.name = name;
        this.icon = icon;
        this.parent = parent;
    }

    public static isFavorite(productGroup: ProductGroupMobileInfo): boolean {
        return (productGroup.id === "favorite");
    }

    /**
     * Return all parent {@link ProductGroup}s of a {@link ProductGroup}, along with the group itself.
     */
    public static toBreadCrumbs(group: ProductGroupMobileInfo): ProductGroupMobileInfo[] {
        return (group.parent)
            ? [...ProductGroupMobileInfo.toBreadCrumbs(group.parent), group]
            : [group];
    }

    public static isAssumed(productGroup: ProductGroupMobileInfo): boolean {
        return (productGroup.id === "assumed");
    }

    public static isTop30(productGroup: ProductGroupMobileInfo): boolean {
        return (productGroup.id === "top30");
    }

    public static createFavorite(): ProductGroupMobileInfo {
        return new ProductGroupMobileInfo("favorite", Localizer.mobileCatalogProviderFavorite, "far star");
    }

    public static createAssumed(): ProductGroupMobileInfo {
        return new ProductGroupMobileInfo("assumed", Localizer.mobileCatalogProviderAssumed, "far repeat");
    }

    public static createTop30(): ProductGroupMobileInfo {
        return new ProductGroupMobileInfo("top30", Localizer.mobileCatalogProviderTop30, "far fa-trophy-alt");
    }

    // #endregion
}