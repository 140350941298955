import React from "react";
import {WebApplicationType, PageRoute, PageRouteProvider, LdData} from "@reapptor-apps/reapptor-react-common";
import AnonymousPage from "../../models/base/AnonymousPage";
import PageDefinitions from "../../providers/PageDefinitions";
import {IconStyle, PageContainer, WidgetContainer, RouteWidget, LinkWidget, LdDataHeader} from "@reapptor-apps/reapptor-react-components";
import BannerMessageAlert from "@/components/BannerMessageAlert/BannerMessageAlert";
import ApplicationContext from "@/models/server/ApplicationContext";
import {PwaHelper} from "@reapptor-apps/reapptor-toolkit";
import AittaController from "@/pages/AittaController";
import Localizer from "../../localization/Localizer";

import styles from "./Home.module.scss";

interface IHomeProps {
    name: string | null;
}

interface IHomeState {
    applicationLdData: LdData | null;
    pwaInstall: boolean;
}

export default class Home extends AnonymousPage<IHomeProps, IHomeState> {

    state: IHomeState = {
        applicationLdData: null,
        pwaInstall: false,
    };

    private get pwaInstall(): boolean {
        return this.state.pwaInstall;
    }

    private async onPwaHelperInitializedAsync(): Promise<void> {
        const context: ApplicationContext = this.getContext();
        const applicationType: WebApplicationType = context.applicationType;
        const pwaInstall: boolean = (PwaHelper.canBeInstalled) && ((applicationType == WebApplicationType.MobileBrowser) || ((context.isDevelopment) && (applicationType == WebApplicationType.DesktopBrowser)));
        await this.setState({pwaInstall});
    }

    private async installPwaAsync(): Promise<void> {
        await PwaHelper.installAsync();
        await this.reRenderAsync();
    }

    public async initializeAsync(): Promise<void> {
        if (this.isAuthorized) {
            const route: PageRoute = await AittaController.getHomePageAsync();

            await PageRouteProvider.redirectAsync(route, true, true);
            
            return;
        }

        await super.initializeAsync();

        await PwaHelper.subscribe(() => this.onPwaHelperInitializedAsync());

        const applicationLdData: LdData = await this.postAsync("/api/application/getApplicationLdData");

        await this.setState({applicationLdData});
    }

    public getTitle(): string {
        return Localizer.topNavFrontpage;
    }
    
    public render(): React.ReactNode {
        return (
            <PageContainer transparent className={styles.home} alertClassName={styles.alert}>
                
                <WidgetContainer>

                    <LdDataHeader data={this.state.applicationLdData}
                                  canonical={"/"}
                    />

                    <BannerMessageAlert />

                    {
                        (this.pwaInstall)
                            ? <RouteWidget icon={{name: "fas tablet-alt"}} label={Localizer.widgetPwaInstall} description={Localizer.widgetPwaDescription} onClick={() => this.installPwaAsync()} />
                            : <LinkWidget icon={{ name: "android", style: IconStyle.Brands }} url="https://mediq.fi/" label={Localizer.widgetMediqSuomiLinkLabel} description={Localizer.widgetMediqSuomiLinkDescription} />
                    }

                    <RouteWidget icon={{ name: "sign-in" }} route={PageDefinitions.loginRoute} label={Localizer.topNavLogin} description={Localizer.widgetLoginDescription} />
                    
                </WidgetContainer>

            </PageContainer>
        );
    }
}