// autogenerated
import {SortDirection} from "@reapptor-apps/reapptor-toolkit";
import {ProductOrderFilter} from "@/models/Enums";

export default class ListOrderProductsRequest {

    public orderId: string | null = null;

    public productOrderFilter: ProductOrderFilter = ProductOrderFilter.CustomerSelection;

    public customerGroupId: string = "";

    public customerId: string = "";

    public expressOrder: boolean | null = null;

    public sortColumnName: string | null = null;

    public sortDirection: SortDirection | null = null;

    public search: string | null = null;

    public readonly isListOrderProductsRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof ListOrderProductsRequest) || ((from as any).isListOrderProductsRequest === true));
    }

    public static as(from?: object | null): ListOrderProductsRequest | null {
        return (ListOrderProductsRequest.is(from)) ? from as ListOrderProductsRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}