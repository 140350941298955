// autogenerated
import {ReportType, AittaReportFilter} from "@/models/Enums";

export default class GetRevenueReportRequest {

    public customerGroupId: string | null = null;

    public customerId: string | null = null;

    public aittaAppOnly: boolean = false;

    public reportType: ReportType = ReportType.Annual;

    public aittaReportFilter: AittaReportFilter = AittaReportFilter.All;

    public readonly isGetRevenueReportRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof GetRevenueReportRequest) || ((from as any).isGetRevenueReportRequest === true));
    }

    public static as(from?: object | null): GetRevenueReportRequest | null {
        return (GetRevenueReportRequest.is(from)) ? from as GetRevenueReportRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}