// autogenerated
import {SortDirection} from "@reapptor-apps/reapptor-toolkit";
import {OrderStatus} from "@/models/Enums";

export default class ListOrdersRequest {

    public customerGroupId: string | null = null;

    public customerId: string | null = null;

    public statuses: OrderStatus[] | null = null;

    public withReturns: boolean = false;

    public express: boolean | null = null;

    public sortColumnName: string | null = null;

    public pageNumber: number = 0;

    public pageSize: number = 0;

    public sortDirection: SortDirection | null = null;

    public search: string | null = null;

    public readonly isListOrdersRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof ListOrdersRequest) || ((from as any).isListOrdersRequest === true));
    }

    public static as(from?: object | null): ListOrdersRequest | null {
        return (ListOrdersRequest.is(from)) ? from as ListOrdersRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}