import React from "react";
import Customer from "@/models/server/Customer";
import {BaseComponent, ch} from "@reapptor-apps/reapptor-react-common";
import {
    Button,
    ButtonContainer,
    ButtonType,
    DateInput,
    Dropdown,
    DropdownAlign,
    DropdownOrderBy,
    Form,
    Icon,
    IconSize,
    NumberInput,
    ThreeColumns,
    TwoColumns
} from "@reapptor-apps/reapptor-react-components";
import CustomerCalendarMark from "@/models/server/CustomerCalendarMark";
import {Utility} from "@reapptor-apps/reapptor-toolkit";
import Dictionary from "typescript-collections/dist/lib/Dictionary";
import CustomerCalendarDate from "@/models/server/CustomerCalendarDate";
import {CalendarDateMark, DeliveryDayOfWeek} from "@/models/Enums";
import Comparator from "@/helpers/Comparator";
import User from "@/models/server/User";
import EnumProvider from "@/providers/EnumProvider";
import Localizer from "@/localization/Localizer";

import styles from "./OrderCalendarPanel.module.scss";

interface IOrderCalendarPanelProps {
    customer: Customer;
    readonly?: boolean;

    onChange?(calendarDeliverySettingsModified: boolean): Promise<void>;

    generateCustomerCalendar?(sender: OrderCalendarPanel, customer: Customer, calendarDates?: CustomerCalendarDate[] | null): Promise<CustomerCalendarDate[]>;
}

interface IOrderCalendarPanelState {
    month: Date;
    selectedDay: Date;
    initialCloseInterval: number;
    initialDeliveryInterval: number;
    initialDeliveryDay: number;
    initialDeliveryStartDate: Date;
    initialDeliveryEndDate: Date | null;
}

export default class OrderCalendarPanel extends BaseComponent<IOrderCalendarPanelProps, IOrderCalendarPanelState> {

    state: IOrderCalendarPanelState = {
        month: this.getTodayMonth(),
        selectedDay: Utility.today(),
        initialCloseInterval: this.customer.closeInterval,
        initialDeliveryInterval: this.customer.deliveryInterval,
        initialDeliveryDay: this.customer.deliveryDay,
        initialDeliveryStartDate: this.customer.deliveryStartDate,
        initialDeliveryEndDate: this.customer.deliveryEndDate,
    };

    private readonly _formRef: React.RefObject<Form> = React.createRef();
    private readonly _calendarRef: React.RefObject<DateInput> = React.createRef();

    private getTodayMonth(): Date {
        const date: Date = Utility.today();
        date.setDate(1);
        return date.date();
    }

    private getFirstDate(): Date {
        const minDate: Date = this.calendarDates.firstOrDefault()?.date ?? this.customer?.deliveryStartDate ?? this.customer?.createdAt ?? Utility.today();
        return minDate.addMonths(-1);
    }

    private getLastDate(): Date {
        return Utility.today().addMonths(18);
    }

    private get canPrevMonth(): boolean {
        return (this.customer != null) && (Utility.diff(this.selectedMonth, this.getFirstDate()).days > 0);
    }

    private get canNextMonth(): boolean {
        return (this.customer != null) && (Utility.diff(this.getLastDate(), this.selectedMonth).days > 0);
    }

    private get canPrevDay(): boolean {
        return (this.customer != null) && (Utility.diff(this.selectedDay, this.getFirstDate()).days > 0);
    }

    private get canNextDay(): boolean {
        return (this.customer != null) && (Utility.diff(this.getLastDate(), this.selectedDay).days > 0);
    }

    private async cancelModifyingAsync(): Promise<void> {
        this.customer.closeInterval = this.state.initialCloseInterval;
        this.customer.deliveryInterval = this.state.initialDeliveryInterval;
        this.customer.deliveryDay = this.state.initialDeliveryDay;
        this.customer.deliveryStartDate = this.state.initialDeliveryStartDate;
        this.customer.deliveryEndDate = this.state.initialDeliveryEndDate;

        await this.onChangeAsync();
        
        await this.reRenderAsync();
    }

    private async applyModifyingAsync(): Promise<void> {
        this.state.initialCloseInterval = this.customer.closeInterval;
        this.state.initialDeliveryInterval = this.customer.deliveryInterval;
        this.state.initialDeliveryDay = this.customer.deliveryDay;
        this.state.initialDeliveryStartDate = this.customer.deliveryStartDate;
        this.state.initialDeliveryEndDate = this.customer.deliveryEndDate;

        if (this.props.generateCustomerCalendar) {
            this.customer.calendarDates = await this.props.generateCustomerCalendar(this, this.customer, this.calendarDates);
        }

        await this.reRenderAsync();

        await this.onChangeAsync();
    }

    private async prevMonthAsync(): Promise<void> {
        const month: Date = this.selectedMonth.addMonths(-1);
        const selectedDay: Date = this.selectedDay.addMonths(-1);

        await this.setState({month, selectedDay});
    }

    private async nextMonthAsync(): Promise<void> {
        const month: Date = this.selectedMonth.addMonths(1);
        const selectedDay: Date = this.selectedDay.addMonths(1);

        await this.setState({month, selectedDay});
    }

    private async prevDayAsync(): Promise<void> {
        const selectedDay: Date = this.selectedDay.addDays(-1);

        let month: Date = new Date(selectedDay);
        month.setDate(1);
        month = month.date();

        await this.setState({selectedDay, month});
    }

    private async nextDayAsync(): Promise<void> {
        const selectedDay: Date = this.selectedDay.addDays(1);

        let month: Date = new Date(selectedDay);
        month.setDate(1);
        month = month.date();

        await this.setState({selectedDay, month});
    }

    private async selectDayAsync(day: Date): Promise<void> {
        await this.setState({selectedDay: day});
    }

    private async setCustomerAwayAsync(calendarDate: CustomerCalendarDate, customerAwayMark: CustomerCalendarMark): Promise<void> {
        const customerAway: boolean = calendarDate.items.includes(customerAwayMark);

        if (customerAway) {
            calendarDate.items.remove(customerAwayMark);

            if (calendarDate.items.length == 0) {
                this.calendarDates.remove(calendarDate);
            }
        } else {
            calendarDate.items.push(customerAwayMark);
        }

        await this.reRenderAsync();

        await this.onChangeAsync();
    }

    private getMarkClassName(mark: CalendarDateMark): string {
        switch (mark) {
            case CalendarDateMark.CloseDate:
                return styles.closeDate;
            case CalendarDateMark.DeliveryDate:
                return styles.deliveryDate;
            case CalendarDateMark.PublicHoliday:
                return styles.publicHoliday;
            case CalendarDateMark.CustomerAway:
                return styles.customerAway;
            default:
                return "";
        }
    }

    private getDatesClassNames(): Dictionary<Date, string[]> {
        const datesClassNames = new Dictionary<Date, string[]>();

        let date: Date = this.selectedMonth.date().addDays(-6);
        const monthCalendarDates: CustomerCalendarDate[] = this.getMonthCalendarDates();

        for (let i: number = 0; i < 44; i++) {

            const calendarDate: CustomerCalendarDate = monthCalendarDates.firstOrDefault(item => CustomerCalendarDate.dateEquals(item.date.date(), date)) ?? CustomerCalendarDate.create(date);

            const classNames: string[] = [];

            const inPast: boolean = date.inPast(true);
            const isWeekend: boolean = CustomerCalendarDate.isWeekend(calendarDate);
            const publicHoliday: boolean = CustomerCalendarDate.hasMark(calendarDate, CalendarDateMark.PublicHoliday);
            const customerAway: boolean = CustomerCalendarDate.hasMark(calendarDate, CalendarDateMark.CustomerAway);
            const deliveryDate: boolean = CustomerCalendarDate.hasMark(calendarDate, CalendarDateMark.DeliveryDate);
            const closeDate: boolean = CustomerCalendarDate.hasMark(calendarDate, CalendarDateMark.CloseDate);
            const warning: boolean = CustomerCalendarDate.hasWarning(calendarDate);
            const openOrder: boolean = CustomerCalendarDate.openOrder(calendarDate, false);
            const express: boolean = CustomerCalendarDate.hasExpressOrder(calendarDate);
            
            if (inPast) {
                classNames.push(styles.inPast);
            }

            if (isWeekend) {
                classNames.push(styles.weekend);
            }
            
            if (express) {
                classNames.push(styles.express);
            }

            if (openOrder) {
                classNames.push(styles.openOrder);
            }
            
            if (warning) {
                classNames.push(styles.warning);
            }

            if (deliveryDate) {
                classNames.push(this.getMarkClassName(CalendarDateMark.DeliveryDate));
            } else if (closeDate) {
                classNames.push(this.getMarkClassName(CalendarDateMark.CloseDate));
            } else if (publicHoliday) {
                classNames.push(this.getMarkClassName(CalendarDateMark.PublicHoliday));
            } else if (customerAway) {
                classNames.push(this.getMarkClassName(CalendarDateMark.CustomerAway));
            }

            if (classNames.length > 0) {
                datesClassNames.setValue(date, classNames);
            }

            date = date.addDays(1);
        }

        return datesClassNames;
    }

    private assignDatesClassNames(): void {
        if (this._calendarRef.current) {

            const datesClassNames: Dictionary<Date, string[]> = this.getDatesClassNames();

            const node = this.JQuery("#" + this._calendarRef.current.id);
            const children = node.find(".react-datepicker__week div.react-datepicker__day");

            const dates: JQuery[] = [];
            for (let i: number = 0; i < children.length; i++) {
                dates.push(this.JQuery(children[i]));
            }

            const firstIndex: number = dates.findIndex(date => !date.hasClass("react-datepicker__day--outside-month"));

            let date: Date = this.selectedMonth;
            if (firstIndex > 0) {
                date = date.addDays(-firstIndex);
            }

            for (let i: number = 0; i < dates.length; i++) {
                const dateNode: JQuery = this.JQuery(children[i]);
                const dateClassNames: string[] = datesClassNames.getValue(date) || [];

                // remove obsolete classes
                const classNamesToRemove: string[] = [styles.inPast, styles.weekend, styles.publicHoliday, styles.customerAway, styles.closeDate, styles.deliveryDate, styles.openOrder, styles.warning];
                classNamesToRemove.remove(dateClassNames);

                for (let j: number = 0; j < classNamesToRemove.length; j++) {
                    const classNameToRemove: string = classNamesToRemove[j];
                    if (dateNode.hasClass(classNameToRemove)) {
                        dateNode.removeClass(classNameToRemove);
                    }
                }

                // add new class
                for (let j: number = 0; j < dateClassNames.length; j++) {
                    const dateClassName: string = dateClassNames[j];
                    if (!dateNode.hasClass(dateClassName)) {
                        dateNode.addClass(dateClassName);
                    }
                }

                date = date.addDays(1);
            }
        }
    }

    private get readonlyDates(): Date[] {
        const dates: Date[] = [];
        const firstDate: Date = this.selectedMonth;
        let date: Date = firstDate.addDays(-6);
        for (let i: number = 0; i < 44; i++) {
            const isReadonly: boolean = (date.getMonth() != firstDate.getMonth());
            if (isReadonly) {
                dates.push(date);
            }
            date = date.addDays(1);
        }
        return dates;
    }

    private get readonly(): boolean {
        return (this.props.readonly == true);
    }

    private get customer(): Customer {
        return this.props.customer;
    }

    private get user(): User {
        return ch.getUser();
    }

    private get isMediqUser(): boolean {
        return this.user.isMediq;
    }

    private get calendarDates(): CustomerCalendarDate[] {
        return this.customer?.calendarDates ?? [];
    }

    private getMonthCalendarDates(): CustomerCalendarDate[] {
        const year: number = this.selectedMonth.getFullYear();
        const month: number = this.selectedMonth.getMonth();
        return this.calendarDates.where(item => (item.date.getFullYear() == year) && (item.date.getMonth() == month));
    }

    private get selectedMonth(): Date {
        return this.state.month;
    }

    private get selectedDay(): Date {
        return this.state.selectedDay;
    }

    private getNeedsToGenerate(): boolean {
        return (
            (this.getSettingsModified()) ||
            (!this.calendarDates.some(item => (!item.date.inPast(true)) && ((CustomerCalendarDate.hasMark(item, CalendarDateMark.DeliveryDate) || CustomerCalendarDate.hasMark(item, CalendarDateMark.CloseDate)))))
        );
    }

    private getSelectedCalendarDay(): CustomerCalendarDate {
        const days: CustomerCalendarDate[] = this.getMonthCalendarDates();
        const selectedDay: Date = this.selectedDay;
        let selectedCalendarDay: CustomerCalendarDate | null = days.firstOrDefault(item => CustomerCalendarDate.dateEquals(item.date.date(), selectedDay)) || null;
        if (selectedCalendarDay == null) {
            selectedCalendarDay = CustomerCalendarDate.create(selectedDay);
            this.calendarDates.push(selectedCalendarDay);
        }
        return selectedCalendarDay;
    }

    private async onChangeAsync(): Promise<void> {
        if (this.props.onChange) {
            const calendarDeliverySettingsModified: boolean = this.getSettingsModified();
            
            await this.props.onChange(calendarDeliverySettingsModified);
        }

        await this._formRef.current?.validateAsync();
    }

    private async setDeliveryStartDateAsync(value: Date): Promise<void> {
        this.customer.deliveryStartDate = value;

        await this.reRenderAsync();

        await this.onChangeAsync();
    }

    private async setDeliveryEndDateAsync(value: Date | null): Promise<void> {
        this.customer.deliveryEndDate = value;

        await this.reRenderAsync();

        await this.onChangeAsync();
    }

    private async setDeliveryIntervalAsync(value: number): Promise<void> {
        this.customer.deliveryInterval = value;

        await this.reRenderAsync();

        await this.onChangeAsync();
    }

    private async setCloseIntervalAsync(value: number): Promise<void> {
        this.customer.closeInterval = value;

        await this.reRenderAsync();

        await this.onChangeAsync();
    }

    private async setDeliveryDayAsync(value: DeliveryDayOfWeek, userInteraction: boolean): Promise<void> {
        this.customer.deliveryDay = value;

        await this.reRenderAsync();

        if (userInteraction) {
            await this.onChangeAsync();
        }
    }

    private async moveCalendarDateAsync(calendarDate: CustomerCalendarDate, days: number, mark: CalendarDateMark): Promise<void> {
        const markItem: CustomerCalendarMark | null = calendarDate.items.firstOrDefault(item => item.mark == mark) || null;

        if (markItem != null) {

            const newDate: Date = calendarDate.date.addDays(days).date();
            let newCalendarDate: CustomerCalendarDate | null = this.calendarDates.firstOrDefault(item => CustomerCalendarDate.dateEquals(item.date, newDate));

            if (newCalendarDate == null) {
                newCalendarDate = CustomerCalendarDate.create(newDate);
                this.calendarDates.push(newCalendarDate);
            }

            calendarDate.items.remove(markItem);
            newCalendarDate.items.push(markItem);

            await this.onChangeAsync();

            if (days > 0) {
                await this.nextDayAsync();
            } else {
                await this.prevDayAsync();
            }
        }
    }

    private async deleteDeliveryDateAsync(calendarDate: CustomerCalendarDate, calendarMark: CustomerCalendarMark): Promise<void> {
        let prevDate: Date = calendarDate.date.addDays(-1);
        let prevCalendarDate: CustomerCalendarDate | null = null;
        let prevCalendarMark: CustomerCalendarMark | null = null;

        while (!prevDate.inPast(true)) {
            prevCalendarDate = this.calendarDates.firstOrDefault(item => CustomerCalendarDate.dateEquals(item.date, prevDate));
            if (prevCalendarDate != null) {
                prevCalendarMark = prevCalendarDate.items.firstOrDefault(item => (item.mark == CalendarDateMark.CloseDate) && (item.orderNumber == null));
                if (prevCalendarMark != null) {
                    break;
                }
            }
            prevDate = prevDate.addDays(-1);
        }

        calendarDate.items.remove(calendarMark);
        if (calendarDate.items.length == 0) {
            this.calendarDates.remove(calendarDate);
        }

        if ((prevCalendarDate) && (prevCalendarMark)) {
            prevCalendarDate.items.remove(prevCalendarMark);
            if (prevCalendarDate.items.length == 0) {
                this.calendarDates.remove(prevCalendarDate);
            }
        }

        await this.onChangeAsync();

        await this.reRenderAsync();
    }

    private async addDeliveryDateAsync(calendarDate: CustomerCalendarDate, calendarMark: CustomerCalendarMark): Promise<void> {

        const prevDate: Date = calendarDate.date.addDays(-1).date();

        let prevCalendarDate: CustomerCalendarDate | null = this.calendarDates.firstOrDefault(item => CustomerCalendarDate.dateEquals(item.date, prevDate));
        if (prevCalendarDate == null) {
            prevCalendarDate = CustomerCalendarDate.create(prevDate);
            this.calendarDates.push(prevCalendarDate);
        }

        const prevCalendarMark: CustomerCalendarMark = CustomerCalendarMark.create(CalendarDateMark.CloseDate);

        prevCalendarDate.items.push(prevCalendarMark);
        calendarDate.items.push(calendarMark);

        await this.onChangeAsync();

        await this.reRenderAsync();
    }

    private renderMarks(calendarDay: CustomerCalendarDate): React.ReactNode {
        const marks: CalendarDateMark[] = [
            CalendarDateMark.PublicHoliday, CalendarDateMark.CustomerAway,
            CalendarDateMark.CloseDate, CalendarDateMark.DeliveryDate,
        ];

        const readonly: boolean = this.readonly;
        const isWeekend: boolean = CustomerCalendarDate.isWeekend(calendarDay);
        const inPast: boolean = CustomerCalendarDate.inPast(calendarDay);
        const isDayPublicHoliday: boolean = CustomerCalendarDate.hasMark(calendarDay, CalendarDateMark.PublicHoliday);
        const warning: boolean = CustomerCalendarDate.hasWarning(calendarDay);

        return (
            <div className={styles.marksContainer}>
                {
                    marks.map((mark, index) => {
                            let markDays: CustomerCalendarMark[] = calendarDay.items.where(item => item.mark == mark);

                            const isDeliveryDate: boolean = (mark == CalendarDateMark.DeliveryDate);
                            const isCloseDate: boolean = (mark == CalendarDateMark.CloseDate);
                            const isPublicHoliday: boolean = (mark == CalendarDateMark.PublicHoliday);
                            const isCustomerAway: boolean = (mark == CalendarDateMark.CustomerAway);
                            const selected: boolean = (markDays.length > 0) || ((isPublicHoliday) && (isWeekend));
                            const className: any = selected && this.getMarkClassName(mark);

                            if (markDays.length == 0) {
                                markDays = [CustomerCalendarMark.create(mark)];
                            }

                            return (
                                <React.Fragment key={index}>
                                    {
                                        markDays.map((calendarMark, index) => {

                                                const hasOrder: boolean = (calendarMark.orderNumber != null);
                                                const editable: boolean = (!readonly) && ((isCustomerAway) && (!inPast) && (!isWeekend) && (!isDayPublicHoliday));
                                                const moveable: boolean = (!readonly) && (selected) && (!inPast) && ((isCloseDate) || (isDeliveryDate));
                                                const deleteable: boolean = (!readonly) && (moveable) && (isDeliveryDate);
                                                const addable: boolean = (!readonly) && (!inPast) && (isDeliveryDate) && (!selected);
                                                const canDelete: boolean = (!readonly) && (deleteable) && (!hasOrder);
                                                const canAdd: boolean = (!readonly) && (addable) && (!hasOrder) && (CustomerCalendarDate.canAddDeliveryDate(calendarDay, this.calendarDates));
                                                
                                                const title: string = (isPublicHoliday)
                                                    ? Localizer.editCustomerPageOrderCalendarPanelMarkHoliday
                                                    : mark.format(nameof(CalendarDateMark));

                                                const subtitle: string = (isPublicHoliday)
                                                    ? (calendarMark.nativeName)
                                                        ? calendarMark.nativeName
                                                        : (isWeekend)
                                                            ? Localizer.editCustomerPageOrderCalendarPanelMarkWeekend
                                                            : Localizer.editCustomerPageOrderCalendarPanelMarkWorkingDay
                                                    : (hasOrder)
                                                        ? (calendarMark.expressOrder)
                                                            ? Localizer.editCustomerPageOrderCalendarPanelMarkExpressOrder.format(calendarMark.orderNumber)
                                                            : Localizer.editCustomerPageOrderCalendarPanelMarkOrder.format(calendarMark.orderNumber)
                                                        : (isCustomerAway)
                                                            ? Localizer.editCustomerPageOrderCalendarPanelMarkCustomerAway
                                                            : (isCloseDate)
                                                                ? Localizer.editCustomerPageOrderCalendarPanelMarkPlannedCloseDay
                                                                : (isDeliveryDate)
                                                                    ? Localizer.editCustomerPageOrderCalendarPanelMarkPlannedDeliveryDay
                                                                    : "";

                                                const warningStyle = ((warning) && (selected) && ((isCloseDate) || (isDeliveryDate))) && styles.warning;

                                                return (
                                                    <div key={index}
                                                         className={this.css(styles.mark, selected && styles.selected, !editable && styles.readonly, warningStyle, className)}
                                                         onClick={() => editable && this.setCustomerAwayAsync(calendarDay, calendarMark)}
                                                    >

                                                        <div>
                                                            <span>{selected ? "✓" : ""}</span>
                                                        </div>

                                                        {
                                                            (moveable) &&
                                                            (
                                                                <Icon name={"fal fa-arrow-circle-left"}
                                                                      tooltip={Localizer.editCustomerPageOrderCalendarPanelMarkMovePrev}
                                                                      disabled={!CustomerCalendarDate.canPrevDate(calendarDay, this.calendarDates)}
                                                                      onClick={() => this.moveCalendarDateAsync(calendarDay, -1, mark)}
                                                                />
                                                            )
                                                        }

                                                        <div>
                                                            <span>{title}</span>
                                                            <span className={styles.subtitle}>{subtitle}</span>
                                                        </div>

                                                        {
                                                            (moveable) &&
                                                            (
                                                                <Icon name={"fal fa-arrow-circle-right"}
                                                                      tooltip={Localizer.editCustomerPageOrderCalendarPanelMarkMoveNext}
                                                                      disabled={!CustomerCalendarDate.canNextDate(calendarDay, this.calendarDates)}
                                                                      onClick={() => this.moveCalendarDateAsync(calendarDay, +1, mark)}
                                                                />
                                                            )
                                                        }

                                                        {
                                                            (deleteable) &&
                                                            (
                                                                <Icon name={"fal fa-ban"}
                                                                      className={styles.delete}
                                                                      tooltip={Localizer.editCustomerPageOrderCalendarPanelMarkDelete}
                                                                      confirm={Localizer.editCustomerPageOrderCalendarPanelMarkDeleteConfirm}
                                                                      disabled={!canDelete}
                                                                      onClick={() => this.deleteDeliveryDateAsync(calendarDay, calendarMark)}
                                                                />
                                                            )
                                                        }

                                                        {
                                                            (addable) &&
                                                            (
                                                                <Icon name={"fal fa-plus-circle"}
                                                                      className={styles.delete}
                                                                      tooltip={Localizer.editCustomerPageOrderCalendarPanelMarkAddDeliveryDay}
                                                                      confirm={Localizer.editCustomerPageOrderCalendarPanelMarkAddDeliveryDayConfirm}
                                                                      disabled={!canAdd}
                                                                      onClick={() => this.addDeliveryDateAsync(calendarDay, calendarMark)}
                                                                />
                                                            )
                                                        }

                                                    </div>
                                                )
                                            }
                                        )
                                    }
                                </React.Fragment>
                            )
                        }
                    )
                }
            </div>
        );
    }

    public getSettingsModified(): boolean {
        return (
            (this.state.initialDeliveryDay != this.customer.deliveryDay) ||
            (this.state.initialDeliveryInterval != this.customer.deliveryInterval) ||
            (this.state.initialCloseInterval != this.customer.closeInterval) ||
            (!Comparator.isEqual(this.state.initialDeliveryStartDate, this.customer.deliveryStartDate)) ||
            (!Comparator.isEqual(this.state.initialDeliveryEndDate, this.customer.deliveryEndDate))
        );
    }
    
    public renderCalendarPanel(): React.ReactNode {
        return (
            <div className={styles.calendarPanel}>

                <div className={styles.calendarContainer}>

                    <div className={styles.monthSelector}>

                        <Icon name={"fal fa-arrow-circle-left"}
                              disabled={!this.canPrevMonth}
                              onClick={() => this.prevMonthAsync()}
                        />

                        <span>{"{0:MMMM} {1}".format(this.selectedMonth, this.selectedMonth.getFullYear())}</span>

                        <Icon name={"fal fa-arrow-circle-right"}
                              disabled={!this.canNextMonth}
                              onClick={() => this.nextMonthAsync()}
                        />

                    </div>

                    <div className={styles.legend}>
                        <span
                            className={styles.closeDate}>{Localizer.editCustomerPageOrderCalendarPanelClosingDay}</span>
                        <span
                            className={styles.deliveryDate}>{Localizer.editCustomerPageOrderCalendarPanelDeliveryDay}</span>
                        <span
                            className={styles.publicHoliday}>{Localizer.editCustomerPageOrderCalendarPanelHoliday}</span>
                    </div>

                    <DateInput expanded
                               id={"myCalendar"}
                               ref={this._calendarRef}
                               className={styles.calendar}
                               value={this.selectedDay}
                               excludeDates={this.readonlyDates}
                               onItemClick={(sender, date: Date) => this.selectDayAsync(date)}
                    />

                </div>

            </div>
        );
    }

    public renderSettingsPanel(): React.ReactNode {
        const settingsModified: boolean = this.getSettingsModified();
        const needsToGenerate: boolean = this.getNeedsToGenerate();

        return (
            <div className={styles.settingsPanel}>

                <div className={styles.settingsHeader}>
                    <span>{Localizer.editCustomerPageOrderCalendarPanelSettingsDeliverySettings}</span>
                </div>

                <Form ref={this._formRef} className={styles.settingsForm}>

                    <TwoColumns>

                        <DateInput required showYearDropdown showMonthDropdown
                                   id={"deliveryContractStartDate"}
                                   className={styles.calendar}
                                   label={Localizer.editCustomerPageCustomerContractsPanelContractStartDate}
                                   readonly={this.readonly}
                                   minDate={Utility.today()}
                                   value={this.customer.deliveryStartDate}
                                   onChange={(date: Date) => this.setDeliveryStartDateAsync(date)}
                        />

                        {
                            (this.isMediqUser) &&
                            (
                                <DateInput showYearDropdown showMonthDropdown clearButton
                                           id={"deliveryContractEndDate"}
                                           className={styles.calendar}
                                           label={Localizer.editCustomerPageCustomerContractsPanelContractEndDate}
                                           readonly={this.readonly}
                                           minDate={Utility.today()}
                                           value={this.customer.deliveryEndDate}
                                           onChange={(date: Date | null) => this.setDeliveryEndDateAsync(date)}
                                />
                            )
                        }   

                    </TwoColumns>

                    <TwoColumns>

                        <NumberInput id="deliveryInterval" required hideZero
                                     label={Localizer.editCustomerPageCustomerContractsPanelWeeklyInterval}
                                     readonly={this.readonly}
                                     hideArrows={this.readonly}
                                     step={1}
                                     min={1}
                                     max={4}
                                     value={this.customer.deliveryInterval}
                                     onChange={(sender, value) => this.setDeliveryIntervalAsync(value)}
                        />

                        <NumberInput id="closeInterval" required hideZero
                                     label={Localizer.editCustomerPageOrderCalendarPanelSettingsCloseDays}
                                     readonly={this.readonly}
                                     hideArrows={this.readonly}
                                     step={1}
                                     min={1}
                                     max={5}
                                     value={this.customer.closeInterval}
                                     onChange={(sender, value) => this.setCloseIntervalAsync(value)}
                        />

                    </TwoColumns>

                    <TwoColumns>

                        <Dropdown id="dayOfWeek" required
                                  label={Localizer.editCustomerPageCustomerContractsPanelDeliveryDayOfWeek}
                                  align={DropdownAlign.Left}
                                  orderBy={DropdownOrderBy.None}
                                  disabled={this.readonly}
                                  items={EnumProvider.getDeliveryDayOfWeekItems()}
                                  selectedItem={this.customer.deliveryDay}
                                  onChange={(sender, item, userInteraction: boolean) => this.setDeliveryDayAsync(parseInt(item!.value), userInteraction)}
                        />

                    </TwoColumns>

                    {
                        ((!this.readonly) && (settingsModified || needsToGenerate)) &&
                        (
                            <ButtonContainer className={styles.buttons}>

                                {
                                    (settingsModified) &&
                                    (
                                        <Button id={"cancel"}
                                                minWidth={90}
                                                label={Localizer.genericCancel}
                                                type={ButtonType.Primary}
                                                icon={{name: "far ban", size: IconSize.Large}}
                                                confirm={Localizer.editCustomerPageOrderCalendarPanelSettingsCancelConfirm}
                                                onClick={() => this.cancelModifyingAsync()}
                                        />
                                    )
                                }

                                {
                                    (needsToGenerate) &&
                                    (
                                        <Button id={"applyCalendarSettings"}
                                                minWidth={90}
                                                label={Localizer.editCustomerPageOrderCalendarPanelSettingsApply}
                                                type={ButtonType.Orange}
                                                icon={{name: "far fa-play", size: IconSize.Large}}
                                                confirm={Localizer.editCustomerPageOrderCalendarPanelSettingsApplyConfirm}
                                                onClick={() => this.applyModifyingAsync()}
                                        />
                                    )
                                }

                            </ButtonContainer>
                        )
                    }

                </Form>

            </div>
        );
    }

    public renderSelectedDayPanel(): React.ReactNode {
        return (
            <div className={styles.selectedDayPanel}>

                <div className={styles.daySelector}>

                    <Icon name={"fal fa-arrow-circle-left"}
                          disabled={!this.canPrevDay}
                          onClick={() => this.prevDayAsync()}
                    />

                    <div className={this.css(styles.selectedDayHeader)}>
                        <span>{"{0:D}".format(this.selectedDay)}</span>
                        <small>{"{0:dddd}".format(this.selectedDay)}</small>
                    </div>

                    <Icon name={"fal fa-arrow-circle-right"}
                          disabled={!this.canNextDay}
                          onClick={() => this.nextDayAsync()}
                    />

                </div>

                {this.renderMarks(this.getSelectedCalendarDay())}

            </div>
        );
    }

    public render(): React.ReactNode {

        setTimeout(() => this.assignDatesClassNames(), 0);

        return (
            <div className={styles.orderCalendarPanel}>

                {
                    (this.customer) &&
                    (
                        <ThreeColumns size={"xl"}>

                            {this.renderSettingsPanel()}

                            {this.renderCalendarPanel()}

                            {this.renderSelectedDayPanel()}

                        </ThreeColumns>
                    )
                }

            </div>
        );
    }
};