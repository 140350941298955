// autogenerated
import {SortDirection} from "@reapptor-apps/reapptor-toolkit";
import {ProductAssortmentFilter, ProductTemplateFilter} from "@/models/Enums";

export default class ListProductSelectionsRequest {

    public assortmentFilter: ProductAssortmentFilter = ProductAssortmentFilter.All;

    public templateFilter: ProductTemplateFilter = ProductTemplateFilter.All;

    public templateId: string | null = null;

    public customerGroupId: string | null = null;

    public customerId: string | null = null;

    public sortColumnName: string | null = null;

    public sortDirection: SortDirection | null = null;

    public search: string | null = null;

    public pageNumber: number = 0;

    public pageSize: number = 0;

    public readonly isListProductSelectionsRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof ListProductSelectionsRequest) || ((from as any).isListProductSelectionsRequest === true));
    }

    public static as(from?: object | null): ListProductSelectionsRequest | null {
        return (ListProductSelectionsRequest.is(from)) ? from as ListProductSelectionsRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}