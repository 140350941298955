import React from "react";
import {ch} from "@reapptor-apps/reapptor-react-common";
import {
    Button,
    ButtonType,
    EmailInput,
    FileInput, IconSize,
    Inline,
    OneColumn,
    PageContainer,
    PageHeader,
    PageRow,
    PhoneInput,
    Tab,
    TabContainer,
    TextAreaInput,
    ToolbarButton,
    TwoColumns
} from "@reapptor-apps/reapptor-react-components";
import AuthorizedPage from "../../models/base/AuthorizedPage";
import TestPdfResponse from "@/models/server/responses/TestPdfResponse";
import TestSmsResponse from "@/models/server/responses/TestSmsResponse";
import TestEmailResponse from "@/models/server/responses/TestEmailResponse";
import PageDefinitions from "@/providers/PageDefinitions";
import AgentState from "@/models/server/AgentState";
import {FileModel, Utility} from "@reapptor-apps/reapptor-toolkit";
import TestPushNotificationResponse from "@/models/server/responses/TestPushNotificationResponse";
import SystemInfo from "@/models/server/SystemInfo";
import Localizer from "../../localization/Localizer";

import styles from "./AdminConsole.module.scss"
import TestPimResponse from "@/models/server/responses/TestPimResponse";
import TestIfsConnectorResponse from "@/models/server/responses/TestIfsConnectorResponse";

interface IAdminConsoleState {
    phone: string;
    email: string;
    agentState: AgentState | null;
    agentUpdateAt: Date | null;
    systemInfo: SystemInfo | null;
    invoking: boolean;
}

export default class AdminConsole extends AuthorizedPage<{}, IAdminConsoleState> {

    state: IAdminConsoleState = {
        phone: this.getUser().phone ?? "",
        email: this.getUser().email,
        agentState: null,
        agentUpdateAt: null,
        systemInfo: null,
        invoking: false,
    };

    private readonly _fileInputRef: React.RefObject<FileInput> = React.createRef();

    private async openFileInputOpenAsync(): Promise<void> {
        if (this._fileInputRef.current) {
            await this._fileInputRef.current.openAsync();
        }
    }

    private async onUploadSelectionsAsync(file: FileModel): Promise<void> {
        await this.postAsync("/api/adminConsole/syncAdeonaDeletedProductsImagesFromFile", file);

        await this.alertMessageAsync(Localizer.adminConsolePageFileUploaded, true);
    }

    private async updateOnlineStateAsync(reload: boolean = false): Promise<void> {
        if (ch.getPage() == this) {
            if (!this.state.invoking) {
                const agentState: AgentState = await this.postAsync("/api/adminConsole/getAgentState");

                await this.setState({agentState, agentUpdateAt: new Date()});
            }

            if (reload) {
                setTimeout(() => this.updateOnlineStateAsync(true), 5000);
            }
        }
    }

    private async updateSystemInfoAsync(): Promise<void> {
        const systemInfo: SystemInfo = await this.postAsync("/api/adminConsole/getSystemInfo");

        await this.setState({systemInfo});
    }

    private async updateTop100RequestsAsync(): Promise<void> {
        let top100RequestsJson: string = await this.postAsync("/api/adminConsole/getTop100Requests");

        top100RequestsJson = JSON.stringify(JSON.parse(top100RequestsJson), null, 4);

        const clipboard: Clipboard = navigator.clipboard;

        if (document.hasFocus()) {
            await clipboard.writeText(top100RequestsJson);

            await ch.flyoutMessageAsync("Statistics copied to clipboard!");
        }
    }

    private async refreshAsync(): Promise<void> {
        if (ch.getPage() == this) {
            await this.reRenderAsync();

            setTimeout(() => this.refreshAsync(), 1000);
        }
    }

    private async setPhoneAsync(phone: string): Promise<void> {
        await this.setState({phone});
    }

    private async setEmailAsync(email: string): Promise<void> {
        await this.setState({email});
    }

    private async clearRedisCacheAsync(): Promise<void> {
        await this.postAsync("/api/adminConsole/clearRedisCache");
    }

    private async clearRedisSessionAsync(): Promise<void> {
        await this.postAsync("/api/adminConsole/clearRedisSession");
        ch.refresh();
    }

    private async clearSyncHashAsync(): Promise<void> {
        await this.postAsync("/api/adminConsole/clearSyncHash");
    }

    private async throwBackendExceptionAsync(): Promise<void> {
        await this.postAsync("/api/adminConsole/throwBackendException");
    }

    private async testPdfAsync(): Promise<void> {

        if (!this.state.invoking) {
            this.state.invoking = true;

            try {
                const response: TestPdfResponse = await this.postAsync("/api/adminConsole/testPdf");

                if (response.success) {

                    await this.alertMessageAsync(Localizer.adminConsolePageTestPdfSuccess.format(response.version), true);

                    if (response.pdf) {
                        ch.download(response.pdf);
                    }

                } else {

                    await this.alertErrorAsync(Localizer.adminConsolePageTestPdfFailed.format(response.version, response.error));

                }
            } finally {
                this.state.invoking = false;
            }
        }
    }

    private async generateProductAssortmentsStatisticsAsync(): Promise<void> {
        await this.postAsync("/api/adminConsole/generateProductAssortmentsStatistics");
    }

    private async syncAdeonaProductsAsync(): Promise<void> {
        await this.postAsync("/api/adminConsole/syncAdeonaProducts");
    }

    private async testSmsAsync(): Promise<void> {
        const response: TestSmsResponse = await this.postAsync("/api/adminConsole/testSms", this.state.phone);

        if (response.success) {
            await this.alertMessageAsync(Localizer.adminConsolePageTestSmsSuccess, true);
        } else {
            await this.alertErrorAsync(Localizer.adminConsolePageTestSmsFailed.format(response.error));
        }
    }

    private async testPushNotificationAsync(): Promise<void> {
        const response: TestPushNotificationResponse = await this.postAsync("/api/adminConsole/testPushNotification");

        if (response.success) {

            await this.alertMessageAsync(Localizer.adminConsolePageTestPushNotificationSent, true);

        } else {

            await this.alertErrorAsync(Localizer.adminConsolePageTestPushNotificationFailed.format(response.error));

        }
    }

    private async testEmailAsync(): Promise<void> {
        const response: TestEmailResponse = await this.postAsync("/api/adminConsole/testEmail", this.state.email);

        if (response.success) {

            await this.alertMessageAsync(Localizer.adminConsolePageTestEmailSuccess, true);

        } else {

            await this.alertErrorAsync(Localizer.adminConsolePageTestEmailFailed.format(response.error));

        }
    }

    private async testPimConnectionAsync(): Promise<void> {
        const response: TestPimResponse = await this.postAsync("/api/adminConsole/testPimConnection");

        if (response.success) {
            await this.alertMessageAsync(Localizer.ifsMigrationConsolePagePimTestSuccess, true);
        } else {
            await this.alertErrorAsync(Localizer.ifsMigrationConsolePagePimTestFailed.format(response.error));
        }
    }

    private async testIfsConnectorAsync(): Promise<void> {
        const response: TestIfsConnectorResponse = await this.postAsync("/api/adminConsole/testIfsConnector");

        if (response.success) {

            await this.alertMessageAsync(Localizer.ifsMigrationConsolePageTestIfsConnectorSuccess, true);

        } else {

            await this.alertErrorAsync(Localizer.ifsMigrationConsolePageTestIfsConnectorFailed.format(response.error));

        }
    }

    private async getSyncActionsJsonAsync(): Promise<void> {
        const file: FileModel = await this.postAsync("/api/adminConsole/getSyncActionsJson");

        ch.download(file);
    }

    private get agentUpdateAt(): string {
        return (this.state.agentUpdateAt != null)
            ? "{0:0} sec(s) ago".format(Utility.diff(new Date(), this.state.agentUpdateAt).totalSeconds)
            : "N/A";
    }

    private get agentPingAt(): string {
        const pingAt: Date | null = this.state.agentState?.pingAt || null;
        if (pingAt == null) {
            return "N/A";
        }
        const seconds: number = Utility.diff(new Date(), pingAt).totalSeconds;
        return (seconds <= 90)
            ? "{0:0} sec(s) ago".format(seconds)
            : pingAt.format("G");
    }

    private get agentLogs(): string {
        return ((this.state.agentState) && (this.state.agentState.messages))
            ? "...\n\n" + [...this.state.agentState.messages].reverse().join("\n\n")
            : "...";
    }

    public async initializeAsync(): Promise<void> {
        await super.initializeAsync();

        await this.updateSystemInfoAsync();

        await this.updateOnlineStateAsync(true);

        await this.refreshAsync();
    }

    private renderStateItem(title: string, value: boolean): React.ReactNode {
        return (
            <p>
                <span>{title}:</span>
                <span className={this.css(value && styles.active)}>{value ? "✓" : "-"}</span>
            </p>
        );
    }

    public render(): React.ReactNode {
        const systemInfo: SystemInfo | null = this.state.systemInfo;

        return (
            <PageContainer className={styles.adminConsole}>

                <PageHeader title={Localizer.topNavAdminManagement} subtitle={this.getContext().username}>

                    <ToolbarButton title={Localizer.genericBack}
                                   icon={{name: "fas arrow-alt-circle-left"}}
                                   type={ButtonType.Primary}
                                   route={PageDefinitions.adminRoute}
                    />

                </PageHeader>

                <PageRow>
                    <div className="col">

                        <TabContainer>

                            <Tab id={"console"} title={"Console"}>

                                <TwoColumns>

                                    <OneColumn>

                                        <Inline>

                                            <Button id={"clearRedisCache"}
                                                    icon={{name: "fad eraser"}}
                                                    type={ButtonType.Primary}
                                                    minWidth={"14rem"}
                                                    label={Localizer.adminConsolePageClearRedisCache}
                                                    confirm={Localizer.adminConsolePageClearRedisCacheConfirm}
                                                    onClick={() => this.clearRedisCacheAsync()}
                                            />

                                            <Button id={"clearRedisSession"}
                                                    icon={{name: "fad skull-crossbones"}}
                                                    type={ButtonType.Primary}
                                                    minWidth={"14rem"}
                                                    label={Localizer.adminConsolePageDropUserSessions}
                                                    confirm={Localizer.adminConsolePageDropUserSessionsConfirm}
                                                    onClick={() => this.clearRedisSessionAsync()}
                                            />

                                        </Inline>

                                        <Inline>

                                            <Button id={"testPdf"}
                                                    icon="file-pdf"
                                                    type={ButtonType.Primary}
                                                    minWidth={"14rem"}
                                                    label={Localizer.adminConsolePageTestPdf}
                                                    onClick={() => this.testPdfAsync()}
                                            />

                                            <Button id={"testNotification"}
                                                    icon="bell"
                                                    type={ButtonType.Primary}
                                                    minWidth={"14rem"}
                                                    label={Localizer.adminConsolePageTestPushNotification}
                                                    onClick={() => this.testPushNotificationAsync()}
                                            />

                                        </Inline>

                                        <Inline>

                                            <Button id={"throwException"}
                                                    icon={{name: "fad bomb"}}
                                                    type={ButtonType.Primary}
                                                    minWidth={"14rem"}
                                                    label={"Throw BE Exception"}
                                                    confirm={"Are you sure you want to throw BE exception?"}
                                                    onClick={() => this.throwBackendExceptionAsync()}
                                            />

                                        </Inline>

                                        <Inline>

                                            <Button id={"generateStatistics"}
                                                    icon={{name: "fa-chart-line far"}}
                                                    confirm={"The operation is very heaviest and shouldn't be run manually!\nAre you sure you want to continue?"}
                                                    type={ButtonType.Primary}
                                                    minWidth={"14rem"}
                                                    label={"Generate statistics"}
                                                    onClick={() => this.generateProductAssortmentsStatisticsAsync()}
                                            />

                                        </Inline>
                                        
                                        <Inline>

                                            <Button id={"clearSyncHashes"}
                                                    icon={{name: "fad skull-crossbones"}}
                                                    type={ButtonType.Primary}
                                                    minWidth={"14rem"}
                                                    label={"Clear sync hashes"}
                                                    confirm={"The operation is very heaviest and shouldn't be run manually!\nAre you sure you want to continue?"}
                                                    onClick={() => this.clearSyncHashAsync()}
                                            />

                                            <Button id={"syncAdeonaProducts"}
                                                    icon={{name: "far fa-sync-alt"}}
                                                    confirm={"The operation is very heaviest and shouldn't be run manually!\nAre you sure you want to continue?"}
                                                    type={ButtonType.Primary}
                                                    minWidth={"14rem"}
                                                    label={"Sync Adeona products"}
                                                    onClick={() => this.syncAdeonaProductsAsync()}
                                            />

                                        </Inline>

                                        <TwoColumns>

                                            <OneColumn>

                                                <FileInput inline hidden
                                                           ref={this._fileInputRef}
                                                           fileTypes={[".xlsx", ".csv"]}
                                                           onChange={(_, file: FileModel) => this.onUploadSelectionsAsync(file)}
                                                />

                                                <Button id={"uploadDeletedProductImages"}
                                                        block
                                                        className={styles.upload}
                                                        label={Localizer.adminConsolePageUploadDeletedProductsImages}
                                                        icon={{name: "fas fa-file-upload", size: IconSize.Large}}
                                                        type={ButtonType.Primary}
                                                        onClick={() => this.openFileInputOpenAsync()}
                                                />

                                            </OneColumn>

                                        </TwoColumns>

                                        <Inline>

                                            <PhoneInput id="test_sms"
                                                        width={"14rem"}
                                                        value={this.state.phone}
                                                        onChange={async (sender, value) => this.setPhoneAsync(value)}
                                            />

                                            <Button id={"testSms"}
                                                    icon={{name: "phone"}}
                                                    type={ButtonType.Primary}
                                                    minWidth={"14rem"}
                                                    label={Localizer.adminConsolePageTestSms}
                                                    disabled={!this.state.phone}
                                                    onClick={() => this.testSmsAsync()}
                                            />

                                        </Inline>

                                        <Inline>

                                            <EmailInput id="test_email"
                                                        width={"14rem"}
                                                        value={this.state.email}
                                                        onChange={async (sender, value) => this.setEmailAsync(value)}
                                            />

                                            <Button id={"testEmail"}
                                                    icon={{name: "envelope-open"}}
                                                    type={ButtonType.Primary}
                                                    minWidth={"14rem"}
                                                    label={Localizer.adminConsolePageTestEmail}
                                                    disabled={!this.state.email}
                                                    onClick={() => this.testEmailAsync()}
                                            />

                                        </Inline>

                                        <Inline>

                                            <Button id={"getSyncActions"}
                                                    icon={{name: "envelope-open"}}
                                                    type={ButtonType.Primary}
                                                    minWidth={"14rem"}
                                                    label={"Get sync actions"}
                                                    onClick={() => this.getSyncActionsJsonAsync()}
                                            />

                                        </Inline>

                                        <Inline>

                                            <OneColumn>

                                                <Inline>

                                                    <Button id={"testPim"}
                                                            icon={{name: "far fa-router"}}
                                                            type={ButtonType.Primary}
                                                            minWidth={"14rem"}
                                                            label={Localizer.ifsMigrationConsolePageTestPim}
                                                            onClick={() => this.testPimConnectionAsync()}
                                                    />

                                                </Inline>

                                            </OneColumn>

                                            <OneColumn>

                                                <Inline>

                                                    <Button id={"testIfs"}
                                                            icon={{name: "far fa-router"}}
                                                            type={ButtonType.Primary}
                                                            minWidth={"14rem"}
                                                            label={Localizer.ifsMigrationConsolePageTestIfsConnector}
                                                            onClick={() => this.testIfsConnectorAsync()}
                                                    />

                                                </Inline>

                                            </OneColumn>

                                        </Inline>

                                        <Inline>

                                            <Button icon={"fal fa-database"}
                                                    label={"TOP 100 Requests"}
                                                    type={ButtonType.Primary}
                                                    minWidth={"14rem"}
                                                    onClick={() => this.updateTop100RequestsAsync()}
                                            />

                                        </Inline>
                                        
                                    </OneColumn>

                                    <OneColumn className={styles.info}>

                                        <p><span>Version:</span><span>{ch.version}</span></p>
                                        {this.renderStateItem("Development VS", ch.isDevelopmentVS)}
                                        {this.renderStateItem("Development", ch.isDevelopment)}
                                        {this.renderStateItem("Production", (!ch.isDevelopmentVS && !ch.isDevelopment!))}
                                        {this.renderStateItem("Online", ch.online)}

                                        {
                                            (systemInfo) &&
                                            (
                                                <React.Fragment>
                                                    {this.renderStateItem("Debug", systemInfo.isDebug)}
                                                    {this.renderStateItem("CL Debug", systemInfo.isComponentLibraryDebug)}
                                                    {this.renderStateItem("UI Debug", ch.debug)}
                                                    {this.renderStateItem("Logger Debug", systemInfo.isLoggerDebug)}
                                                    {this.renderStateItem("Optimized", systemInfo.isOptimized)}
                                                    <p>
                                                        <span>Cores per server:</span><span>{systemInfo.coresPerServer}</span>
                                                    </p>
                                                    <p>
                                                        <span>Session timeot, min:</span><span>{systemInfo.sessionTimeoutMinutes}</span>
                                                    </p>
                                                </React.Fragment>
                                            )
                                        }

                                    </OneColumn>

                                </TwoColumns>

                            </Tab>

                            <Tab id={"agent"} title={"Agent"}>

                                <Inline>
                                    <Button id={"updateOnlineState"}
                                            label={"Update"}
                                            type={ButtonType.Blue}
                                            onClick={() => this.updateOnlineStateAsync()}
                                    />
                                </Inline>

                                <hr/>

                                <p>
                                    <span>Status at: </span>
                                    <span><b>{this.agentUpdateAt}</b></span>
                                </p>

                                <p>
                                    <span>Ping at: </span>
                                    <span><b>{this.agentPingAt}</b></span>
                                </p>

                                <TextAreaInput readonly
                                               rows={40}
                                               value={this.agentLogs}
                                />

                            </Tab>

                        </TabContainer>

                    </div>
                </PageRow>
            </PageContainer>
        );
    }
}