import {AthenaeumConstants} from "@reapptor-apps/reapptor-toolkit";
import {CustomerServiceType} from "@/models/Enums";

export default class AittaConstants extends AthenaeumConstants {
    
    public static readonly companyName: string = "Mediq";

    public static readonly applicationName: string = "Aitta";

    public static readonly pollTimeoutInSeconds: number = 15;

    public static readonly pollUserActivityInSeconds: number = 60;

    public static readonly siteAdminRole: string = "SiteAdmin";

    public static readonly adminRole: string = "Admin";

    public static readonly masterRole: string = "Master";

    public static readonly managerRole: string = "Manager";

    public static readonly shelverRole: string = "Shelver";

    public static readonly shelverPartnerRole: string = "ShelverPartner";

    public static readonly adminRoles: readonly string[] = [AittaConstants.adminRole, AittaConstants.masterRole];

    public static readonly mediqRoles: readonly string[] = [AittaConstants.siteAdminRole, AittaConstants.adminRole, AittaConstants.masterRole, AittaConstants.shelverRole];
    
    public static readonly customerRoles: readonly string[] = [AittaConstants.managerRole, AittaConstants.shelverPartnerRole];
    
    public static readonly calendarServiceTypes: readonly CustomerServiceType[] =
        [
            CustomerServiceType.Aitta,
            CustomerServiceType.MobileAitta,
            CustomerServiceType.Shelving
        ];
    
    public static readonly keyLength: number = 255;
    
    public static readonly descriptionLength: number = 1024;
    
    public static readonly hashLength: number = 32;

    /**
     * Every 2nd week
     */
    public static readonly initialDeliveryInterval: number = 2;

    /**
     * 2 working days
     */
    public static readonly initialCloseInterval: number = 2;
    
    /**
    * 9999
    */
    public static readonly maxOrderProductQuantity: number = 9999;
    
    /**
     * 35 chars
     */
    public static readonly maxProductNameLength: number = 35;

    /**
     * 25 chars
     */
    public static readonly maxProductSizeLength: number = 35;

    /**
     * "20rem"
     */
    public static readonly customersDropdownMinWidth: string = "25rem";
    
    /**
     * Global message modal user interaction storage constants
     */
    public static readonly needToShowGlobalMessage: string = "NeedToShowGlobalMessage";

    /**
     * "GlobalMessageHashCode"
     */
    public static readonly globalMessageHashCode: string = "GlobalMessageHashCode";
    
    /**
     * "defaultCustomerId"
     */
    public static readonly customerIdCacheKey: string = "defaultCustomerId";
    
    /**
     * "defaultCustomerGroupId"
     */
    public static readonly customerGroupIdCacheKey: string = "defaultCustomerGroupId";

    /**
     * "-1"
     */
    public static readonly orderThresholdNoValue: number = -1;

    /// <summary>
    /// "1 000"
    /// </summary>
    public static readonly minimumSymbolsToDisplayCustomersInDropDown: number = 3;
}