// autogenerated
import {ExportEncodingType} from "@/models/Enums";

export default class ExportLabelsToCsvRequest {

    public labelIds: string[] | null = null;

    public encodingType: ExportEncodingType = ExportEncodingType.Utf8;

    public readonly isExportLabelsToCsvRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof ExportLabelsToCsvRequest) || ((from as any).isExportLabelsToCsvRequest === true));
    }

    public static as(from?: object | null): ExportLabelsToCsvRequest | null {
        return (ExportLabelsToCsvRequest.is(from)) ? from as ExportLabelsToCsvRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}