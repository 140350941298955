// autogenerated

export default class DeleteProductTemplateRequest {

    public id: string = "";

    public readonly isDeleteProductTemplateRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof DeleteProductTemplateRequest) || ((from as any).isDeleteProductTemplateRequest === true));
    }

    public static as(from?: object | null): DeleteProductTemplateRequest | null {
        return (DeleteProductTemplateRequest.is(from)) ? from as DeleteProductTemplateRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}