import React from "react";
import {Accordion, Button, ButtonType, Carousel, CarouselNavigation, CarouselPagination, OneColumn, PageContainer, Spinner} from "@reapptor-apps/reapptor-react-components";
import Product from "@/models/server/Product";
import {BasePageParameters, SwipeDirection} from "@reapptor-apps/reapptor-react-common";
import ProductAccessory from "@/models/server/ProductAccessory";
import ProductAttribute from "@/models/server/ProductAttribute";
import {ProductAccessoryType} from "@/models/Enums";
import ImageProvider from "@/providers/ImageProvider";
import GetProductRequest from "@/models/server/requests/GetProductRequest";
import MobileAuthorizedPage from "@/models/base/MobileAuthorizedPage";
import GetProductResponse from "@/models/server/responses/GetProductResponse";
import ProductReplacement from "@/models/server/ProductReplacement";
import OrderProductDelivery from "@/models/server/OrderProductDelivery";
import AittaController from "@/pages/AittaController";
import Localizer from "@/localization/Localizer";

import styles from "./ProductDetails.module.scss";

import noImage from "@/components/Images/NoImage.png";

export interface IProductDetailsProps extends BasePageParameters {
    productReplacementId: string | null;
}

interface IProductDetailsState {
    product: Product | null;
    productReplacement: ProductReplacement | null;
    replacedProducts: ProductReplacement[] | [];
    deliveries: OrderProductDelivery[];
    loading: boolean;
    descriptionExpanded: boolean;
}

export default class ProductDetails extends MobileAuthorizedPage<IProductDetailsProps, IProductDetailsState> {

    state: IProductDetailsState = {
        product: null,
        productReplacement: null,
        replacedProducts: [],
        deliveries: [],
        loading: false,
        descriptionExpanded: false,
    };

    private get relatedProducts(): ProductAccessory[] {
        return this.product?.accessories?.where(item => item.type == ProductAccessoryType.RelatedProduct) ?? [];
    }

    private get deliveries(): OrderProductDelivery[] {
        return this.state.deliveries;
    }

    private get correspondingProducts(): ProductAccessory[] {
        return this.product?.accessories?.where(item => item.type == ProductAccessoryType.CorrespondingProduct) ?? [];
    }

    private get hasData(): boolean {
        return (
            (this.product != null) && (this.product.images != null) && (this.product.attributes != null) &&
            (this.product.accessories != null)
        );
    }

    public async toggleDescriptionAsync(): Promise<void> {
        await this.setState({descriptionExpanded: !this.state.descriptionExpanded});
    }

    public get descriptionExpanded(): boolean {
        return this.state.descriptionExpanded;
    }

    public get product(): Product | null {
        return this.state.product;
    }

    public get productReplacement(): ProductReplacement | null {
        return this.state.productReplacement;
    }

    public get replacedProducts(): ProductReplacement[] {
        return this.state.replacedProducts;
    }

    public get isMediq(): boolean {
        return this.getUser()?.isMediq;
    }
    
    public get loading(): boolean {
        return this.state.loading;
    }

    public async onSwipeHandlerAsync(direction: SwipeDirection): Promise<boolean> {
        return ((direction != SwipeDirection.Right) && (direction != SwipeDirection.Left));
    }

    public async getProductAsync(productIdOrAssortmentIdOrOrderProductId: string, productReplacementId: string | null): Promise<void> {
        const request = new GetProductRequest();
        request.productIdOrAssortmentIdOrOrderProductId = productIdOrAssortmentIdOrOrderProductId;
        request.productReplacementId = productReplacementId;
        request.customerId = AittaController.currentCustomer?.id || null;

        const response: GetProductResponse = await this.postAsync("/api/productManagement/getProduct", request);

        
        let replacedProducts: ProductReplacement[] = [];
        if (response.replacedProductData != null) {
            replacedProducts = response.replacedProductData.where(item => item.productReplacement != null).map(item => item.productReplacement!);
        }
        
        await this.setState({product: response.product, productReplacement: response.replacementProductData?.productReplacement || null, deliveries: response.deliveries ?? [], replacedProducts: replacedProducts});
    }

    public async initializeAsync(): Promise<void> {

        const productIdOrAssortmentIdOrOrderProductId: string = this.routeId || "";

        if (productIdOrAssortmentIdOrOrderProductId) {
            const parameters = this.parameters as IProductDetailsProps | null;

            await this.getProductAsync(productIdOrAssortmentIdOrOrderProductId, parameters?.productReplacementId ?? null);
        }

        await super.initializeAsync();
    }

    private renderAttributes(attributes: ProductAttribute[], excludeKeywords: boolean): React.ReactNode {
        if (excludeKeywords) {
            const keywords: ProductAttribute | null = attributes.firstOrDefault(item => item.key == "Keywords");
            if (keywords) {
                attributes.remove(keywords);
            }
        }

        const description: ProductAttribute | null = attributes.firstOrDefault(item => item.key === "ProductDescription");

        const longDescription: boolean = (description != null) && (!!description.value && description.value.length > 150);

        return (
            <div className={styles.attributes}>
                {
                    attributes.map((attribute: ProductAttribute) => (
                        <div key={attribute.key} className={styles.attribute}>

                            <div className={styles.key}>
                                {ProductAttribute.getKey(attribute)}:
                            </div>

                            <div>

                                <div className={this.css(!this.descriptionExpanded && styles.collapsedLongDescription)}>

                                    <span className={styles.value}> {ProductAttribute.getValue(attribute)} </span>

                                </div>

                                {
                                    (attribute.key == description?.key) && (longDescription) &&
                                    (
                                        <Button type={ButtonType.Link}
                                                label={(this.descriptionExpanded ? Localizer.mobileCatalogProductDetailsPageShowLess : Localizer.mobileCatalogProductDetailsPageShowMore)}
                                                onClick={() => this.toggleDescriptionAsync()}
                                        />
                                    )
                                }

                            </div>

                        </div>
                    ))
                }
            </div>
        );

    }

    private renderDeliveries(deliveries: OrderProductDelivery[]): React.ReactNode {
        return (
            <div>
                {
                    deliveries.map((delivery: OrderProductDelivery) => (
                        <div key={`delivery_${delivery.id}`} className={styles.deliveries}>
                            <a href={delivery.trackingLink!} target="_blank">


                                <div>
                                    {delivery.date!.format("D")}
                                </div>

                                <div>
                                    ({delivery.deliveredQuantity}/{delivery.orderedQuantity})
                                </div>


                            </a>
                        </div>
                    ))
                }
            </div>
        );

    }

    private renderAccessories(accessories: ProductAccessory[], noAccessoriesText: string): React.ReactNode {
        if (this.loading) {
            return (
                <span className={styles.noAccessories}>{Localizer.genericLoading}</span>
            );
        }

        if (accessories.length == 0) {
            return (
                <h4 className={styles.noAccessories}>{noAccessoriesText}</h4>
            );
        }

        const pagination: CarouselPagination = (accessories.length <= 20)
            ? CarouselPagination.BottomOutside
            : CarouselPagination.None;

        return (
            <Carousel navigation={CarouselNavigation.Outside}
                      pagination={pagination}
                      slidesPerView={1}
                      className={styles.carousel}
            >
                {
                    accessories.map((accessory: ProductAccessory, index: number) => this.renderProduct(accessory.relatedProduct!, index, true))
                }
            </Carousel>
        );
    }

    private renderProduct(product: Product, index: number, relatedProduct: boolean): React.ReactElement {
        product.attributes?.sortBy(attribute => ProductAttribute.getKey(attribute));
        const description: ProductAttribute | null = product.attributes && product.attributes.firstOrDefault(item => item.key === "ProductDescription");

        if (description != null) {
            product.attributes?.remove(description);

            product.attributes = [description, ...product.attributes ?? []];
        }

        return (
            <div className={styles.product} key={index}>

                {
                    (relatedProduct) &&
                    (
                        <div className={styles.caption}>
                            <span>{Product.getTitle(product)}</span>
                            <span>{Product.getSubtitle(product)}</span>
                        </div>
                    )
                }

                <OneColumn>

                    {
                        (this.hasData) &&
                        (
                            (!relatedProduct) ?
                                (
                                    <Accordion autoCollapse expanded
                                               header={"{0}".format(product.name)}

                                    >

                                        <img src={ImageProvider.getRequiredImageSrc(Product.getImage(product), noImage)}
                                             className={styles.image}
                                             alt={Localizer.productsModalImageNotFound}
                                        />

                                    </Accordion>
                                )
                                : (
                                    <img src={ImageProvider.getRequiredImageSrc(Product.getImage(product), noImage)}
                                         className={styles.image}
                                         alt={Localizer.productsModalImageNotFound}
                                    />
                                )

                        )
                    }

                </OneColumn>

                {
                    (relatedProduct)
                        ?
                        (
                            <>
                                <h4 className={styles.attributesHeader}>{Localizer.mobileCatalogProductDetailsPageAttributes}</h4>

                                {this.renderAttributes(product.attributes ?? [], true)}

                            </>
                        )
                        :
                        (
                            <Accordion header={Localizer.mobileCatalogProductDetailsPageAttributes}
                                       autoCollapse
                            >
                                <OneColumn>

                                    {this.renderAttributes(product.attributes ?? [], true)}

                                </OneColumn>

                            </Accordion>

                        )
                }

            </div>
        )
    }

    private renderReplacement(replacement: ProductReplacement): React.ReactElement {
        return (
            this.renderReplacementProduct(replacement.toProduct!)
        )
    }

    private renderReplacedProducts(replacedProducts: ProductReplacement[]): React.ReactElement {
        const pagination: CarouselPagination = (replacedProducts.length <= 20)
            ? CarouselPagination.BottomOutside
            : CarouselPagination.None;

        return (
            <Carousel navigation={CarouselNavigation.Outside}
                      pagination={pagination}
                      slidesPerView={1}
                      className={styles.carousel}
            >
                {
                    replacedProducts.map((replacedProduct: ProductReplacement, index: number) =>
                        (
                            <div key={`replacement_${index}`}>
                                {this.renderReplacementProduct(replacedProduct.fromProduct!)}
                            </div>
                        )
                    )
                }
            </Carousel>
        );
    }

    private renderReplacementProduct(product: Product): React.ReactElement {
        const replacementMessage: string = (this.productReplacement)
            ? (this.productReplacement.validTo)
                ? Localizer.mobileCatalogProductDetailsPageReplacementValidTo.format(this.productReplacement.validTo)
                : Localizer.mobileCatalogProductDetailsPageReplacementNoValidTo
            : "";
        
        return (
            <div className={styles.replacement}>

                <OneColumn>

                    <span className={styles.replacementInfo}>{replacementMessage}</span>

                </OneColumn>

                {
                    (product) &&
                    (
                        <React.Fragment>

                            <OneColumn>

                                <img src={ImageProvider.getRequiredImageSrc(Product.getImage(product), noImage)}
                                     alt={Localizer.productsModalImageNotFound}
                                />

                            </OneColumn>

                            <OneColumn>

                                {this.renderAttributes(product.attributes ?? [], true)}

                            </OneColumn>

                        </React.Fragment>
                    )
                }

            </div>
        )
    }

    public render(): React.ReactNode {
        return (
            <PageContainer transparent fullHeight
                           fullWidth={this.mobile}
                           className={this.css(styles.productDetails, this.mobile && styles.mobile)}
            >
                {
                    (this.product) &&
                    (

                        <>
                            <div className={styles.content}>

                                <React.Fragment>

                                    {
                                        this.renderProduct(this.product, 0, false)
                                    }

                                    {
                                        (this.deliveries.length > 0) &&
                                        (
                                            <Accordion autoCollapse
                                                       header={Localizer.mobileOrderDetailsPageDeliveryTracking}
                                                       className={styles.accordion}

                                            >
                                                {
                                                    this.renderDeliveries(this.deliveries)
                                                }
                                            </Accordion>
                                        )
                                    }

                                    <Accordion autoCollapse
                                               header={Localizer.mobileCatalogProductDetailsPageRelatedProducts}
                                               className={styles.accordion}

                                    >
                                        {
                                            this.renderAccessories(this.relatedProducts, Localizer.productsModalNoRelatedProducts)
                                        }
                                    </Accordion>

                                    <Accordion autoCollapse
                                               header={Localizer.mobileCatalogProductDetailsPageCorrespondingProducts}
                                               className={styles.accordion}
                                    >
                                        {
                                            this.renderAccessories(this.correspondingProducts, Localizer.productsModalNoCorrespondingProducts)
                                        }
                                    </Accordion>

                                    {
                                        (this.productReplacement?.toProduct) &&
                                        (
                                            <Accordion autoCollapse
                                                       header={Localizer.mobileCatalogProductDetailsPageReplacementProduct}
                                                       className={styles.accordion}
                                            >
                                                {
                                                    this.renderReplacement(this.productReplacement)
                                                }
                                            </Accordion>
                                        )
                                    }

                                    {
                                        ((this.isMediq) && (this.replacedProducts)) &&
                                        (
                                            <Accordion autoCollapse
                                                       header={"EN: Replaced products"}
                                                       className={styles.accordion}
                                            >
                                                {
                                                    this.renderReplacedProducts(this.replacedProducts)
                                                }
                                            </Accordion>
                                        )
                                    }

                                </React.Fragment>

                            </div>
                        </>
                    )

                }

                {(this.loading) && <Spinner/>}

            </PageContainer>
        );
    }
}