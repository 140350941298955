// autogenerated
import {ProductReplacementLevel, ProductReplacementType} from "@/models/Enums";

export default class SaveProductReplacementRequest {

    public id: string | null = null;

    public customerGroupId: string | null = null;

    public customerId: string | null = null;

    public level: ProductReplacementLevel = ProductReplacementLevel.Global;

    public type: ProductReplacementType = ProductReplacementType.Temporary;

    public generateLabels: boolean = false;

    public fromProductId: string = "";

    public toProductId: string = "";

    public validTo: Date | null = null;

    public active: boolean = false;

    public assortmentCheckNeeded: boolean = false;

    public readonly isSaveProductReplacementRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof SaveProductReplacementRequest) || ((from as any).isSaveProductReplacementRequest === true));
    }

    public static as(from?: object | null): SaveProductReplacementRequest | null {
        return (SaveProductReplacementRequest.is(from)) ? from as SaveProductReplacementRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}