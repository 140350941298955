// autogenerated

export default class GetUsersRequest {

    public showDeleted: boolean = false;

    public showExpired: boolean = false;

    public roleNames: string[] | null = null;

    public includeRoles: boolean = false;

    public readonly isGetUsersRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof GetUsersRequest) || ((from as any).isGetUsersRequest === true));
    }

    public static as(from?: object | null): GetUsersRequest | null {
        return (GetUsersRequest.is(from)) ? from as GetUsersRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}