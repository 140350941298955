// autogenerated
import BaseProductAssortmentConsumptionsRequest from "./BaseProductAssortmentConsumptionsRequest";

export default class GetProductAssortmentConsumptionsRequest extends BaseProductAssortmentConsumptionsRequest {

    public readonly isGetProductAssortmentConsumptionsRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof GetProductAssortmentConsumptionsRequest) || ((from as any).isGetProductAssortmentConsumptionsRequest === true));
    }

    public static as(from?: object | null): GetProductAssortmentConsumptionsRequest | null {
        return (GetProductAssortmentConsumptionsRequest.is(from)) ? from as GetProductAssortmentConsumptionsRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}