// autogenerated
import BannerMessage from "@/models/server/BannerMessage";
import Localization from "@/models/server/Localization";
import AittaConstants from "@/helpers/AittaConstants";

export default class Banner extends BannerMessage {

    public activateAt: Date | null = null;

    public deactivateAt: Date | null = null;

    public active: boolean = false;

    public showOnce: boolean = false;

    public readonly isBanner: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof Banner) || ((from as any).isBanner === true));
    }

    public static as(from?: object | null): Banner | null {
        return (Banner.is(from)) ? from as Banner : null;
    }

    // #region Placeholder

    public static create(): Banner {
        const banner = new Banner();
        banner.id = AittaConstants.defaultGuid;
        banner.text = new Localization();
        banner.text.id = AittaConstants.defaultGuid;
        banner.showOnce = true;
        banner.dismissible = true;
        return banner;
    }

    // #endregion
}