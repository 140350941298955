// autogenerated
import {FileModel} from "@reapptor-apps/reapptor-toolkit";

export default class ImportProductSelectionsRequest {

    public file: FileModel | null = null;

    public customerGroupId: string | null = null;

    public customerId: string | null = null;

    public templateId: string | null = null;

    public toTemplate: boolean = false;

    public safeImportNeeded: boolean = false;

    public readonly isImportProductSelectionsRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof ImportProductSelectionsRequest) || ((from as any).isImportProductSelectionsRequest === true));
    }

    public static as(from?: object | null): ImportProductSelectionsRequest | null {
        return (ImportProductSelectionsRequest.is(from)) ? from as ImportProductSelectionsRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}