// autogenerated

export default class SetLabelsPrintedRequest {

    public productLabelIds: string[] | null = null;

    public customerId: string | null = null;

    public all: boolean = false;

    public except: boolean = false;

    public isGetLabelsPrintFileModelRequest: boolean = false;

    public readonly isSetLabelsPrintedRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof SetLabelsPrintedRequest) || ((from as any).isSetLabelsPrintedRequest === true));
    }

    public static as(from?: object | null): SetLabelsPrintedRequest | null {
        return (SetLabelsPrintedRequest.is(from)) ? from as SetLabelsPrintedRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}