// autogenerated
import {LocalizationLanguage} from "@/models/Enums";

export default class SaveFaqRequest {

    public id: string | null = null;

    public key: string | null = null;

    public title: string | null = null;

    public description: string | null = null;

    public language: LocalizationLanguage = LocalizationLanguage.Finnish;

    public readonly isSaveFaqRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof SaveFaqRequest) || ((from as any).isSaveFaqRequest === true));
    }

    public static as(from?: object | null): SaveFaqRequest | null {
        return (SaveFaqRequest.is(from)) ? from as SaveFaqRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}