// autogenerated

export default class DeleteLabelsRequest {

    public productLabelIds: string[] | null = null;

    public customerId: string | null = null;

    public all: boolean = false;

    public except: boolean = false;

    public readonly isDeleteLabelsRequest: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof DeleteLabelsRequest) || ((from as any).isDeleteLabelsRequest === true));
    }

    public static as(from?: object | null): DeleteLabelsRequest | null {
        return (DeleteLabelsRequest.is(from)) ? from as DeleteLabelsRequest : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}