// autogenerated
import {OrderStatus} from "@/models/Enums";

export default class OrderInfo {

    public id: string = "";

    public number: number = 0;

    public express: boolean = false;

    public erpId: string | null = null;

    public erpReference: string | null = null;

    public productCount: number = 0;

    public customerId: string = "";

    public status: OrderStatus = OrderStatus.Open;

    public plannedCloseDate: Date | null = null;

    public closedAt: Date | null = null;

    public plannedDeliveryDate: Date | null = null;

    public deliveredAt: Date | null = null;

    public partiallyDeliveredAt: Date | null = null;

    public submittedAt: Date | null = null;

    public createdAt: Date = new Date();

    public createdById: string = "";

    public modifiedAt: Date = new Date();

    public modifiedById: string = "";

    public readonly isOrderInfo: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof OrderInfo) || ((from as any).isOrderInfo === true));
    }

    public static as(from?: object | null): OrderInfo | null {
        return (OrderInfo.is(from)) ? from as OrderInfo : null;
    }

    // #region Placeholder

    // Put your code here (function, properties, ect...)

    // #endregion
}