// autogenerated
import {CalendarDateMark, DayOfWeek} from "@/models/Enums";
import CustomerCalendarMark from "@/models/server/CustomerCalendarMark";

export default class CustomerCalendarDate {

    public date: Date = new Date();

    public items: CustomerCalendarMark[] = [];

    public readonly isCustomerCalendarDate: true = true;

    public static is(from?: object | null): boolean {
        return (from != null) && ((from instanceof CustomerCalendarDate) || ((from as any).isCustomerCalendarDate === true));
    }

    public static as(from?: object | null): CustomerCalendarDate | null {
        return (CustomerCalendarDate.is(from)) ? from as CustomerCalendarDate : null;
    }

    // #region Placeholder

    public static create(date: Date): CustomerCalendarDate {
        const customerCalendarDate = new CustomerCalendarDate();
        customerCalendarDate.date = date;
        return customerCalendarDate;
    }

    public static hasMark(dayItems: CustomerCalendarDate | CustomerCalendarDate[] | null, mark: CalendarDateMark): boolean {
        return (dayItems != null)
            ? (Array.isArray(dayItems))
                ? dayItems.some(dayItem => dayItem.items.some(item => item.mark === mark))
                : CustomerCalendarDate.hasMark([dayItems], mark)
            : false;
    }

    public static hasOrder(dayItems: CustomerCalendarDate | CustomerCalendarDate[] | null, expressOrder?: boolean | null): boolean {
        return (dayItems != null)
            ? (Array.isArray(dayItems))
                ? dayItems.some(dayItem => dayItem.items.some(item => (item.orderNumber != null) && ((expressOrder == null) || (item.expressOrder == expressOrder))))
                : CustomerCalendarDate.hasOrder([dayItems], expressOrder)
            : false;
    }

    public static getCustomerAwayDays(dayItems: CustomerCalendarDate[] | null): Date[] {
        return (dayItems != null)
            ? dayItems
                .where(day => day.items.some(item => item.mark == CalendarDateMark.CustomerAway))
                .map(item => item.date)
            : [];
    }

    public static getOpenOrderDay(dayItems: CustomerCalendarDate[] | null, mark: CalendarDateMark): Date | null {
        return (dayItems != null)
            ? dayItems
            .firstOrDefault(day => (!CustomerCalendarDate.inPast(day)) && (CustomerCalendarDate.hasMark(day, mark)) && (CustomerCalendarDate.hasOrder(day, false)))
            ?.date || null
            : null;
    }

    public static inPast(dayItems: CustomerCalendarDate | CustomerCalendarDate[] | null): boolean {
        if (dayItems == null) {
            return false;
        }

        if (Array.isArray(dayItems)) {
            return dayItems.some(item => CustomerCalendarDate.inPast(item));
        }

        const date: Date = dayItems.date;

        return date.inPast(true);
    }

    public static isWeekend(dayItems: CustomerCalendarDate | CustomerCalendarDate[] | null): boolean {
        if (dayItems == null) {
            return false;
        }

        if (Array.isArray(dayItems)) {
            return dayItems.some(item => CustomerCalendarDate.isWeekend(item));
        }

        const weekend: DayOfWeek[] = [DayOfWeek.Saturday, DayOfWeek.Sunday];
        const date: Date = dayItems.date;

        return weekend.includes(date.getDay());
    }

    public static hasWarning(dayItems: CustomerCalendarDate | CustomerCalendarDate[] | null): boolean {
        if (dayItems == null) {
            return false;
        }

        if (Array.isArray(dayItems)) {
            return dayItems.some(item => CustomerCalendarDate.hasWarning(item));
        }

        const publicHoliday: boolean = CustomerCalendarDate.hasMark(dayItems, CalendarDateMark.PublicHoliday);
        const isWeekend: boolean = CustomerCalendarDate.isWeekend(dayItems);
        const inPast: boolean = CustomerCalendarDate.inPast(dayItems);
        const customerAway: boolean = CustomerCalendarDate.hasMark(dayItems, CalendarDateMark.CustomerAway);
        const deliveryDate: boolean = CustomerCalendarDate.hasMark(dayItems, CalendarDateMark.DeliveryDate);
        const closeDate: boolean = CustomerCalendarDate.hasMark(dayItems, CalendarDateMark.CloseDate);

        return ((!inPast) && ((publicHoliday) || (isWeekend) || (customerAway)) && ((deliveryDate) || (closeDate)));
    }

    public static hasExpressOrder(dayItems: CustomerCalendarDate | CustomerCalendarDate[] | null): boolean {
        if (dayItems == null) {
            return false;
        }

        return CustomerCalendarDate.hasOrder(dayItems, true);
    }

    public static openOrder(dayItems: CustomerCalendarDate | CustomerCalendarDate[] | null, expressOrder?: boolean | null): boolean {
        if (dayItems == null) {
            return false;
        }

        if (Array.isArray(dayItems)) {
            return dayItems.some(item => CustomerCalendarDate.openOrder(item));
        }

        const inPast: boolean = CustomerCalendarDate.inPast(dayItems);
        const deliveryDate: boolean = CustomerCalendarDate.hasMark(dayItems, CalendarDateMark.DeliveryDate);
        const closeDate: boolean = CustomerCalendarDate.hasMark(dayItems, CalendarDateMark.CloseDate);
        const hasOrder: boolean = CustomerCalendarDate.hasOrder(dayItems, expressOrder);

        return (!inPast) && ((deliveryDate) || (closeDate)) && (hasOrder);
    }

    public static canSet(calendarDate: CustomerCalendarDate): boolean {
        const deliveryDate: boolean = CustomerCalendarDate.hasMark(calendarDate, CalendarDateMark.DeliveryDate);
        const closeDate: boolean = CustomerCalendarDate.hasMark(calendarDate, CalendarDateMark.CloseDate);
        const inPast: boolean = CustomerCalendarDate.inPast(calendarDate);
        return (!deliveryDate) && (!closeDate) && (!inPast);
    }

    public static canPrevDate(calendarDate: CustomerCalendarDate, dayItems: CustomerCalendarDate[]): boolean {
        const prevDate: Date = calendarDate.date.addDays(-1).date();
        const prevCalendarDate: CustomerCalendarDate = dayItems.firstOrDefault(item => CustomerCalendarDate.dateEquals(item.date, prevDate)) ?? CustomerCalendarDate.create(prevDate);
        return (this.canSet(prevCalendarDate));
    }

    public static canNextDate(calendarDate: CustomerCalendarDate, dayItems: CustomerCalendarDate[]): boolean {
        const nextDate: Date = calendarDate.date.addDays(+1).date();
        const nextCalendarDate: CustomerCalendarDate = dayItems.firstOrDefault(item => CustomerCalendarDate.dateEquals(item.date, nextDate)) ?? CustomerCalendarDate.create(nextDate);
        return (this.canSet(nextCalendarDate));
    }

    public static canAddDeliveryDate(calendarDate: CustomerCalendarDate, dayItems: CustomerCalendarDate[]): boolean {
        const prevDate: Date = calendarDate.date.addDays(-1).date();
        const prevCalendarDate: CustomerCalendarDate = dayItems.firstOrDefault(item => CustomerCalendarDate.dateEquals(item.date, prevDate)) ?? CustomerCalendarDate.create(prevDate);
        return (
            (CustomerCalendarDate.canSet(calendarDate)) &&
            (CustomerCalendarDate.canSet(prevCalendarDate)) &&
            (!CustomerCalendarDate.isWeekend(calendarDate)) &&
            (!CustomerCalendarDate.isWeekend(prevCalendarDate))
        );
    }

    public static dateEquals(x: Date | string, y: Date | string): boolean {
        x = (typeof x === "string") ? new Date(x) : x;
        y = (typeof y === "string") ? new Date(y) : y;

        const xValue: number = x.date().valueOf();
        const yValue: number = y.date().valueOf();

        return (xValue === yValue);
    }

    // #endregion
}